import axios from 'axios';
import config from '@/config';

export default {
    async GetCompanyLogo(companyId, jwt) {
        const response = await axios.get(`${config.backendIP}/api/v1/company/logo/${companyId}`, {
            headers: {
                Authorization: `${jwt}`
            }
        });
        return response;
    },
    async GetCompanyName(companyId, jwt) {
        const response = await axios.get(`${config.backendIP}/api/v1/company/name/${companyId}`, {
            headers: {
                Authorization: `${jwt}`
            }
        });
        return response;
    },
    async GetCompanyImages(companyId, jwt) {
        const response = await axios.get(`${config.backendIP}/api/v1/company/images/${companyId}`, {
            headers: {
                Authorization: `${jwt}`
            }
        });
        return response;
    },
    async DeleteCompanyImage(companyId, imageId, jwt) {
        const response = await axios.delete(`${config.backendIP}/api/v1/company/image/${companyId}/${imageId}`, {
            headers: {
                Authorization: `${jwt}`
            }
        });
        return response;
    },
    async GetCompanyInfo(companyId, jwt) {
        const response = await axios.get(`${config.backendIP}/api/v1/company/${companyId}`, {
            headers: {
                Authorization: `${jwt}`
            }
        });
        return response;
    },
    async UpdateCompanyInfo(companyId, jwt, data) {
        const response = await axios.put(`${config.backendIP}/api/v1/company/${companyId}`, data, {
            headers: {
                Authorization: `${jwt}`
            }
        });
        return response;
    },
    async GetCompanySafetyTime(companyId, jwt) {
        const response = await axios.get(`${config.backendIP}/api/v1/company/safetytime/${companyId}`, {
            headers: {
                Authorization: `${jwt}`
            }
        });
        return response;
    },
    async UpdateCompanySafetyTime(companyId, jwt, data) {
        const response = await axios.put(`${config.backendIP}/api/v1/company/safetytime/${companyId}`, data, {
            headers: {
                Authorization: `${jwt}`
            }
        });
        return response;
    },
};