<template>
    <footer class="footer">
      <div class="container">
        <div class="footer-logo">
          <img :src="MainLogoPath_White" alt="Logo">
        </div>
        <div class="footer-links" v-if="footerLinks.length > 0">
            <li v-for="(link, index) in footerLinks" :key="index">
                <a :href="link.url" :class="link.class" :id="link.id">{{ link.text }}</a>
            </li>
        </div>
        <div class="social-links">
          <a href="#"><i class="pi pi-instagram"></i></a>
          <a href="#"><i class="pi pi-facebook"></i></a>
          <a href="#"><i class="pi pi-youtube"></i></a>
        </div>
        <div class="footer-legal-info">
            <a>{{currentYear}} © AmbuBook - Tutti i diritti riservati. | AmbuBook S.r.l.s. - C.so Francia 127 Bis, 10098 Rivoli (TO), Italia | P. IVA e C.F. 13057260013</a>
            <br>
            <a href='#' class='iubenda-cs-preferences-link'>Le tue preferenze relative alla privacy</a>
            <span>&nbsp;|&nbsp;</span>
            <a href="https://www.iubenda.com/privacy-policy/58947101" class="iubenda-nostyle iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a>
            <span>&nbsp;|&nbsp;</span>
            <a href="https://www.iubenda.com/privacy-policy/58947101/cookie-policy" class="iubenda-nostyle iubenda-noiframe iubenda-embed iubenda-noiframe " title="Cookie Policy ">Cookie Policy</a>
            <span>&nbsp;|&nbsp;</span>
            <a href="https://www.iubenda.com/termini-e-condizioni/58947101" class="iubenda-nostyle iubenda-noiframe iubenda-embed iubenda-noiframe " title="Termini e Condizioni ">Termini e Condizioni</a>
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  import _MainLogoPath_White from '../assets/Images/MainLogo_White.png';
  export default {
    name: 'Footer',
    data(){
        return{
            currentYear: new Date().getFullYear(),
            MainLogoPath_White: _MainLogoPath_White,
            footerLinks: [
                //{ text: 'Lavora con noi', url: '#' },
                //{ text: 'Team', url: '#' },
                //{ text: 'Assistenza', url: '#'},
            ],
        }
    },
    methods: {
        iubendaLoader(){
            (function (w,d) {
                var loader = function () {
                    var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0];
                    s.src="https://cdn.iubenda.com/iubenda.js";
                    tag.parentNode.insertBefore(s,tag);
                };
                if(w.addEventListener){
                    w.addEventListener("load", loader, false);
                }else if(w.attachEvent){
                    w.attachEvent("onload", loader);
                }else{
                    w.onload = loader;
                }
            })(window, document);
          }
    }
  }
  </script>
  
  <style scoped>
  .footer {
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #2D3142;
  padding: 20px 0;
  text-align: center;
  z-index: -2;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-logo img {
  max-height: 20px;
  width: auto;
  margin-bottom: 10px;
}

.footer-links ul {
  list-style: none;
  padding: 0;
}

.footer-links{
    margin-bottom: 10px;
}
.footer-links li {
  display: inline;
  margin-right: 20px;
}

.footer-links a {
  text-decoration: none;
  color: #BFC0C0;
  font-size: 16px;
}
.social-links{
    margin-bottom: 10px;
}
.social-links a {
  margin: 0 10px;
  text-decoration: none;
  color: #FFFFFF;
  font-size: 20px;
}
.footer-legal-info a {
  text-decoration: none;
  color: #BFC0C0;
  font-size: 12px;
}

.footer-legal-info>span {
  text-decoration: none;
  color: #BFC0C0;
  font-size: 12px;
}
  </style>
  