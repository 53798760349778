<template>
    <br>
    <br>
    <br>
    <br>
    <br>
    <div class="flex overflow-hidden">
        <div class="ResultText flex-grow-1 flex align-items-center justify-content-center" if v-if="isConfirmed === undefined">
            <h1>Stiamo verificando...</h1>
        </div>
        <div class="ResultText flex-grow-1 flex align-items-center justify-content-center" if v-else-if="isConfirmed === true">
            <h1>Grazie per aver confermato la tua mail</h1>
        </div>
        <div class="ResultText flex-grow-1 flex align-items-center justify-content-center" if v-else>
            <h1>Ops... Qualcosa è andato storto</h1>
        </div>

    </div>
    <div class="flex overflow-hidden">
        <div class="flex-grow-1 flex align-items-center justify-content-center">
            <Button label="Home" @click="this.$router.replace('/')" rounded />
        </div>
    </div>
    <br>
    <br>
    <br>
    <br>
    <br>
</template>

<script>
    import API from '../api/API'
    export default {
        name: 'ConfirmationMailView',
        data() {
            return {
                isConfirmed: undefined
            }
        },
        mounted() {
            const hash = this.$route.params.hash
            API.UserAPI.ConfirmEmail(hash)
                .then(() => {
                    this.isConfirmed = true
                })
                .catch(() => {
                    this.isConfirmed = false
                })
        }
    }
</script>

<style scoped>
  .ResultText{
    z-index: -2;
    padding: 0;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 0 0 900px 0;
    margin: 0 auto;
    height: auto;
    line-height: normal;
    margin-bottom: 20px;
    position: relative;
  }
  .ResultText h1{
    color: rgb(0, 0, 0);
    font-size: 3rem;
    padding-top: 20px;
    margin: 0 auto;
  }
</style>
  