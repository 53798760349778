<template>
    <div class="resultContainer"> 
      <div class="resultContent">
        <div class="resultImage">
          <Skeleton height="6rem" width="6rem" class="mb-2" borderRadius="16px" id="SkeletonImage"></Skeleton>
        </div>
        <div class="resultImageAndInfosContainer">
          <div class="resultTitle">
            <Skeleton height="1rem" class="mb-2" borderRadius="16px"></Skeleton>
            <div class="addressContainer">
              <Skeleton width="15rem" height="0.5rem" class="mb-2" borderRadius="16px" style="margin-top: 15px;"></Skeleton>
            </div>
          </div>
          <div class="resultInfos">
            <Skeleton width="10rem" class="mb-2" borderRadius="16px"></Skeleton>
          </div>
        </div>
        <div class="resultPrice">
          <div class="resultPriceNumber">
            <Skeleton width="10rem" class="mb-2" borderRadius="16px"></Skeleton>
          </div>
          <Skeleton height="2.5rem" width="75%" class="mb-2" borderRadius="2rem" style="float: right; margin-top: 15px;"></Skeleton>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: 'ResultSkeleton',
  data(){
      return {

      }
  },
  mounted() {

  },
  watch: {

  } ,
  
  methods: {

  }
}
</script>


<style scoped>
.resultContainer{
  padding: 15px;
  border-radius: 15px;
  -webkit-box-shadow: 0px 0px 12px -1px rgba(0,0,0,0.5);
  box-shadow: 0px 0px 12px -1px rgba(0,0,0,0.05);
  margin: 5px;
  min-width: 850px;
}
.resultContent{
  display: flex;
  align-items: center;
  height: 100px;
  justify-content: space-between;
}
.resultTitle{
  color: #4F5D75;
  text-align: left;
}
.resultImage{
  height: 6rem;
  width: 6rem;
  border-radius: 15px;
}
.resultImageAndInfosContainer{
  margin: 15px;
  line-height: 5px;
  flex-grow: 1;
  height: -webkit-fill-available;
}
.resultInfos{
  text-align: left;
  color: #EF8354;
  display: flex;
  position: relative;
  align-items: baseline;
  margin-top: 30px;
}
.resultInfos i{
  font-size: small;
  color: #bfc0c0;
  margin: 5px;
  cursor: pointer;
}
.resultPrice{
    text-align: right;
    margin: 15px;
    margin-right: 10px;
    line-height: 10px;
    height: -webkit-fill-available;
}
.resultPrice p{
  color: #bfc0c0;
  font-size: x-small;
  font-weight: lighter;
  margin-right: 10px;
}
.resultPriceNumber{
  display: flex;
  position: relative;
  align-items: center;
}
.addressContainer{
  display: flex;
  position: relative;
  align-items: center;
  height: 0px;
}
.addressContainer i{
  font-size: x-small;
  margin-right: 5px;
  margin-left: 5px;
}

@media (max-width: 700px){

.resultContainer{
  min-width: 0px;
}
.resultContent{
  display: unset;
  height: auto;
}
.resultTitle{
  line-height: 1;
}
.resultPrice{
  text-align: right;
  line-height: 0px;
  margin: 0px;
  max-height: -webkit-min-content;
}
.resultPriceNumber{
  justify-content: end;
}
.resultImage{
  width: -webkit-fill-available;
}
#SkeletonImage{
  width: -webkit-fill-available !important;
}
.resultImageAndInfosContainer{
  margin: 15px;
  max-height: auto;
  max-height: -webkit-min-content;
  line-height: 5px;
  flex-grow: 1;
}
}

</style>