<template>
    <div style="height: 30rem;">
        <br>
        <div class="flex flex-auto justify-content-center">
            <span class="p-float-label" style="margin: 15px;">
                <Calendar inputId="start" v-model="unavailability.startTime" showTime showIcon
                dateFormat="dd/mm/yy" hourFormat="24" :stepMinute="5" timezone />
                <label for="start">Inizio indisponibilità</label>
            </span>
        </div>
        <div class="flex justify-content-center">
            <span class="p-float-label" style="margin: 15px;">
                <Calendar inputId="end" v-model="unavailability.endTime" showTime showIcon
                dateFormat="dd/mm/yy" hourFormat="24" :stepMinute="5" />        
                <label for="end">Fine indisponibilità</label>
            </span>
        </div>
        <div class="flex justify-content-center">
            <span class="p-float-label" style="margin: 15px;">
                <Textarea v-model="unavailability.notes" rows="5" cols="30" />
                <label for="notes">Note indisponibilità</label>
            </span>
        </div>
        <br>
        <div class="flex justify-content-center">
            <Button :label="isInserting ? 'Aggiungi indisponibilità' : 'Modifica indisponibilità'" @click="addUnavailability" rounded />      
        </div> 
        <br>
        <div v-if="!isInserting" class="flex justify-content-center">
            <Button label="Elimina indisponibilità" @click="deleteUnavailability" severity="danger" rounded />      
        </div> 
        <br>
    </div>
    <Toast ref="toast" position="bottom-right" style="right:0px; width: -webkit-fill-available; margin: 10px; max-width: 500px;"/>
</template>

<script>
import API from '@/api/API';
import Cookies from 'js-cookie';
import Helper from '@/helper/Helpers';

export default {
    name: 'AddAvailability',

    props: {
        serviceId: {
            type: String,
            required: true
        },
        companyId: {
            type: String,
            required: true
        },
        bookingId: {
            type: String,
            required: false
        },
        inStartDate: {
            type: Date,
            required: false
        },
        inEndDate: {
            type: Date,
            required: false
        },
        isInserting: {
            type: Boolean,
            required: true
        },
        revertEvent: {
            type: Object,
            required: false
        },
        inNotes: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            unavailability: {
                serviceId: this.serviceId,
                companyId: this.companyId,
                bookingId: this.bookingId,
                startTime: null,
                endTime: null,
                notes: null
            }
        }
    },
    components: {

    },
    mounted() {
        if (this.inStartDate !== undefined) {
            this.unavailability.startTime = Helper.TimeHelper.getDateToUTC(this.inStartDate);
        }
        if (this.inEndDate !== undefined) {
            this.unavailability.endTime = Helper.TimeHelper.getDateToUTC(this.inEndDate);
        }
        if (this.inNotes !== undefined) {
            this.unavailability.notes = this.inNotes;
        }
    },
    methods: {
        addUnavailability() {
            if (!this.isValid()) {
                this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'Inserire data e ora di inizio e fine indisponibilità!',life: 6500});
                return;
            }

            const jwt = Cookies.get('token');
            if (jwt === undefined) {
                this.$router.push({ name: 'login' });
            } else {
                if (this.isInserting) {
                    const booking = {
                        serviceId: this.unavailability.serviceId,
                        companyId: this.unavailability.companyId,
                        startTime: Helper.TimeHelper.getFormattedDateISO(this.unavailability.startTime),
                        endTime: Helper.TimeHelper.getFormattedDateISO(this.unavailability.endTime),
                        notes: this.unavailability.notes
                    };

                    API.BookingAPI.PostExternalBooking(jwt, booking).then((response) => {
                        if (response.status === 200) {
                            this.$emit('unavailabilityAdded');
                        }
                    }).catch((error) => {
                        if (error.response !== undefined) {
                            if (error.response.status === 401) {
                                this.$router.push({ name: 'login' });
                            } else if (error.response.status === 409) {
                                this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'La nuova indisponibilità non può sovrapporsi ad altre indisponibilità!',life: 6500});
                            } else if (error.response.status === 418){
                                this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'La nuova indisponibilità non può essere aggiunta nel passato!',life: 6500});
                            } else if (error.response.status === 413) {
                                this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'La nuova indisponibilità non può durare più di una settimana consecutiva!',life: 6500});
                            }
                        } else {
                            this.$refs.toast.add({severity:'error', summary: 'Errore', detail: 'Errore sconosciuto!',life: 6500});
                        }
                    });
                }
                else
                {
                    const booking = {
                        bookingId: this.unavailability.bookingId,
                        serviceId: this.unavailability.serviceId,
                        companyId: this.unavailability.companyId,
                        startTime: Helper.TimeHelper.getFormattedDateISO(this.unavailability.startTime),
                        endTime: Helper.TimeHelper.getFormattedDateISO(this.unavailability.endTime),
                        notes: this.unavailability.notes
                    };

                    API.BookingAPI.PutExternalBooking(jwt, booking).then((response) => {
                        if (response.status === 200) {
                            this.$emit('unavailabilityAdded');
                        }
                    }).catch((error) => {
                        if (error.response !== undefined) {
                            if (error.response.status === 401) {
                                this.$router.push({ name: 'login' });
                            } else if (error.response.status === 409) {
                                this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'La nuova indisponibilità non può sovrapporsi ad altre indisponibilità!',life: 6500});
                            } else if (error.response.status === 418) {
                                this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'La nuova indisponibilità non può essere aggiunta nel passato!',life: 6500});
                            } else if (error.response.status === 413) {
                                this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'La nuova indisponibilità non può durare più di una settimana consecutiva!',life: 6500});
                            }
                        } else {
                            this.$refs.toast.add({severity:'error', summary: 'Errore', detail: 'Errore sconosciuto!',life: 6500});
                        }
                    });

                    // API.AvailabilityAPI.PutAvailability(jwt, this.availability.availabilityId,
                    //     Helper.TimeHelper.getFormattedDateISO(this.availability.startTime),
                    //     Helper.TimeHelper.getFormattedDateISO(this.availability.endTime)
                    // ).then((response) => {
                    //     if (response.status === 200) {
                    //         this.$emit('availabilityAdded');
                    //     }
                    // }).catch((error) => {
                    //     if (error.response !== undefined) {
                    //         if (error.response.status === 401) {
                    //             this.$router.push({ name: 'login' });
                    //         } else if (error.response.status === 409) {
                    //             this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'La nuova disponibilità non può toccare o sovrapporsi ad altre disponibilità!',life: 6500});
                    //         } else if (error.response.status === 418) {
                    //             this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'La nuova disponibilità non può essere aggiunta nel passato!',life: 6500});
                    //         } else if (error.response.status === 413) {
                    //             this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'La nuova disponibilità non può durare più di una settimana consecutiva!',life: 6500});
                    //         }
                    //     } else {
                    //         this.$refs.toast.add({severity:'error', summary: 'Errore', detail: 'Errore sconosciuto!',life: 6500});
                    //     }
                    //     if (this.revertEvent !== null && this.revertEvent.revert !== undefined)
                    //         this.revertEvent.revert();
                    // });
                }
            }
        },
        deleteUnavailability() {
            const jwt = Cookies.get('token');
            if (jwt === undefined) {
                this.$router.push({ name: 'login' });
            } else {
                API.BookingAPI.DeleteExternalBooking(jwt, this.unavailability.bookingId).then((response) => {
                    if (response.status === 200) {
                        this.$emit('unavailabilityAdded');
                    }
                }).catch((error) => {
                    if (error.response !== undefined) {
                        if (error.response.status === 401) {
                            this.$router.push({ name: 'login' });
                        }
                    } else {
                        this.$refs.toast.add({severity:'error', summary: 'Errore', detail: 'Errore sconosciuto!',life: 6500});
                    }
                });
                // API.AvailabilityAPI.DeleteAvailability(jwt, this.availability.availabilityId).then((response) => {
                //     if (response.status === 200) {
                //         this.$emit('availabilityAdded');
                //     }
                // }).catch((error) => {
                //     if (error.response !== undefined) {
                //         if (error.response.status === 401) {
                //             this.$router.push({ name: 'login' });
                //         }
                //     } else {
                //         this.$refs.toast.add({severity:'error', summary: 'Errore', detail: 'Errore sconosciuto!',life: 6500});
                //     }
                // });
            }
        },
        isValid() {
            return this.unavailability.startTime !== null && this.unavailability.endTime !== null;
        }
    }
}
</script>