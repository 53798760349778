<template>
  <div class="DashboardVehicles">
    <VehiclesList @clickedVehicle="passThisVehicle" :form-is-dirty="formIsDirty"></VehiclesList>
    <VehicleForm :currentVehicle="currentvehicleToModify" @formIsDirty="dirtyChanged"></VehicleForm>
  </div>
</template>

<script>
import VehiclesList from '../components/VehiclesList.vue'
import VehicleForm from '../components/VehicleForm.vue'
export default {
  name: 'DashboardVehiclesView',
  data(){
    return{
      currentvehicleToModify: null,
      formIsDirty: false
    }
  },
  components: {
    VehiclesList,
    VehicleForm
  },
  methods:{
    dirtyChanged(dirty) {
      this.formIsDirty = dirty;
    },
    passThisVehicle(currentVehicle){
      this.formIsDirty = false;
      this.currentvehicleToModify = currentVehicle;
    }
  },
  mounted(){
  },
}
</script>

<style scoped>
.DashboardVehicles {
  position: relative;
  z-index: -2;
  margin: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  min-height: 70vh;
  min-height: 115vh;
}
@media (max-width: 500px), (max-height: 900px) {
  .DashboardVehicles{
    align-items: center;
    height: auto;
    display: block;
    margin: 5px;
  }
}

</style>
