import TimeHelper from './TimeHelper.js';
import GeoHelper from './GeoHelper.js';
import ResultHelper from './ResultHelper.js';

export default {
    TimeHelper: {
        ...TimeHelper
    },
    GeoHelper: {
        ...GeoHelper
    },
    ResultHelper: {
        ...ResultHelper
    },
};