<template>
  <div class="ResultInfosContainer" v-if="allPropsLoaded">
    <div class="containerHeader"> 
      <Map :from="search.fromLocation" :to="search.toLocation" style="display: block;"></Map>
    </div>

    <div class="containerContent">
      <div class="rightSide">
        <div class="firstLine">
          <div class="containerTitle">
            <h1>{{ this.rawItem.companyName }} {{ this.rawItem.serviceSection ? ' - Sezione ' + this.rawItem.serviceSection : '' }}</h1>
            <Avatar :image="this.rawItem.companyLogo" class="mr-2" shape="circle" />
          </div>
          <div class="containerRating">
            <vue3starRatings 
              v-model="ratingScore"
              :starSize="12"
              starColor="#EF8354"
              inactiveColor="#bfc0c0"
              :numberOfStars="5"
              :disableClick="true"
            />
            <p>({{ this.totalRatings }} valutazioni)</p>
          </div>

          <div class="companyDescriptionContainer">{{ this.rawItem.companyDescription }}</div>
        </div>
        <div class="secondLine">
          <div class="secondLineSingleRow"><p class="secondLineLabel">Giorno della prenotazione: </p><p>{{this.formattedDate}}</p></div>
          <div class="secondLineSingleRow"><p class="secondLineLabel">Partenza:</p><p> {{ this.formattedFromAddress}}</p></div>
          <div class="secondLineSingleRow"><p class="secondLineLabel">Arrivo:</p><p> {{ this.formattedToAddress}}</p></div>
          <div class="secondLineSingleRow"><p class="secondLineLabel">Orario stimato arrivo del mezzo:</p><p> {{this.formattedPickupTime}}</p></div>
          <div class="secondLineSingleRow"><p class="secondLineLabel">Orario di arrivo a destinazione:</p><p> {{this.formattedBookingTime}}</p></div>
          <div class="secondLineSingleRow"><p class="secondLineLabel">Durata prevista:</p><p> {{this.formattedEstimatedTime}}</p></div>
          <div class="secondLineSingleRow"><p class="secondLineLabel">Distanza percorsa:</p><p> {{this.rawItem.totalDistance}} KM</p></div>
          <div v-if="serviceType == 2" class="secondLineSingleRow"><p class="secondLineLabel">Tempo di attesa:</p><p> {{ this.formattedWaitingTime }}</p></div>
          <div class="secondLineSingleRow"><p class="secondLineLabel">Mezzo per il trasporto:</p><p> {{ this.vehicleType }}</p></div>
          <br>
          <p class="text-color-secondary">I tempi esposti sono calcolati da AmbuBook. Potrebbero differire dai tempi effettivi dell'azienda.</p>
        </div>

      </div>
      <div class="leftSide">
        <div class="imageLine">
          <Galleria :value="images" :responsiveOptions="responsiveOptions" :numVisible="5" :circular="true" containerStyle="max-width: 640px;"
            :showItemNavigators="true" :showThumbnails="false">
            <template #item="slotProps">
                <img :src="slotProps.item.itemImageSrc" :alt="slotProps.item.alt" style="width: 100%; display: block; border-radius: 15px;" />
            </template>
            <template #thumbnail="slotProps">
                <img :src="slotProps.item.thumbnailImageSrc" :alt="slotProps.item.alt" style="display: block;" />
            </template>
          </Galleria>
        </div>
      </div>
      
    </div>
    <div class="containerFooter"> 
      <div class="priceLine">
          <p>Include tasse e costi</p>
          <h1>€ {{ this.rawItem.totalPrice }}</h1>
        </div>
    </div>
    <Toast ref="toast" position="bottom-right" style="right:0px; width: -webkit-fill-available; margin: 10px; max-width: 500px;"/>
  </div>
</template>

<script>
import Map from '../components/Map.vue'
import Helper from '@/helper/Helpers';
import { store } from '@/store';
import { stringifyQuery } from 'vue-router';
import vue3starRatings from "vue3-star-ratings";

export default {
  name: 'ResultInfos',
  components: {
    Map,
    vue3starRatings,
  },
  data(){
    return{
      //DEBUG
      debug: false,

      rawItem: null,
      search: null,
      allPropsLoaded: false,

      ratingScore: null,
      totalRatings: null,

      //CompanyImages
      images: [],
      responsiveOptions: [
          {
              breakpoint: '991px',
              numVisible: 4
          },
          {
              breakpoint: '767px',
              numVisible: 3
          },
          {
              breakpoint: '575px',
              numVisible: 1
          }
      ],

      formattedDate: '',
      formattedBookingTime: '',
      formattedPickupTime: '',
      formattedEstimatedTime: '',
      formattedFromAddress: '',
      formattedToAddress: '',
      formattedWaitingTime: '',
      vehicleType: '',
      serviceType: '',
    }
  },
  created(){

  },
  mounted() {

    this.allPropsLoaded = false;

    let tmpStore = store.getters.GetResultInfo;
    
    this.rawItem = tmpStore.rawItem;
    this.ratingScore = this.rawItem.rating;
    this.totalRatings = this.rawItem.totalRatings;
    this.search = tmpStore.search;

    if(this.rawItem.companyImages != null){
      for (let i = 0; i < this.rawItem.companyImages.length; i++) {
        this.images.push({
          itemImageSrc: this.rawItem.companyImages[i].image,
        });
      }
    } else {
      this.images.push({
        itemImageSrc: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAoAAAAGQCAQAAACU+hmuAAAD00lEQVR42u3UgQAAAAgDsE6eZEAJZNAGsUwXwEsRICBAAAECCBBAgAACBBAggAABBAggQAABAggQQIAAAgQQIIAAAQQIIEAAAQIIEECAAAIEECCAAAEECCBAAAECCBBAgIAAAQQIIEAAAQIIEECAAAIEECCAAAEECCBAAAECCBBAgAACBBAggAABBAggQAABAggQQIAAAgQQIIAAAQQIIEAAAQIIEBAggAABBAggQAABAggQQIAAAgQQIIAAAQQIIEAAAQIIEECAAAIEECCAAAEECCBAAAECCBBAgAACBBAggAABBAggQECAAgQECCBAAAECCBBAgAACBBAggAABBAggQAABAggQQIAAAgQQIIAAAQQIIEAAAQIIEECAAAIEECCAAAEECCBAAAECCBAQIIAAAQQIIEAAAQIIEECAAAIEECCAAAEECCBAAAECCBBAgAACBBAggAABBAggQAABAggQQIAAAgQQIIAAAQQIIEAAAQICBBAggAABBAggQAABAggQQIAAAgQQIIAAAQQIIEAAAQIIEECAAAIEECCAAAEECCBAAAECCBBAgAACBBAggAABBAgIUICAAAEECCBAAAECCBBAgAACBBAggAABBAggQAABAggQQIAAAgQQIIAAAQQIIEAAAQIIEECAAAIEECCAAAEECCBAAAECAgQQIIAAAQQIIEAAAQIIEECAAAIEECCAAAEECCBAAAECCBBAgAACBBAggAABBAggQAABAggQQIAAAgQQIIAAAQQIIEBAgAACBBAggAABBAggQAABAggQQIAAAgQQIIAAAQQIIEAAAQIIEECAAAIEECCAAAEECCBAAAECCBBAgAACBBAggAABAQoQECCAAAEECCBAAAECCBBAgAACBBAggAABBAggQAABAggQQIAAAgQQIIAAAQQIIEAAAQIIEECAAAIEECCAAAEECCBAQIAAAgQQIIAAAQQIIEAAAQIIEECAAAIEECCAAAEECCBAAAECCBBAgAACBBAggAABBAggQAABAggQQIAAAgQQIIAAAQQICBBAgAACBBAggAABBAggQAABAggQQIAAAgQQIIAAAQQIIEAAAQIIEECAAAIEECCAAAEECCBAAAECCBBAgAACBBAgIEABAgIEECCAAAEECCBAAAECCBBAgAACBBAggAABBAggQAABAggQQIAAAgQQIIAAAQQIIEAAAQIIEECAAAIEECCAAAEECAgQQIAAAgQQIIAAAQQIIEAAAQIIEECAAAIEECCAAAEECCBAAAECCBBAgAACBBAggAABBAggQAABAggQQIAAlwUynxNfILWgMgAAAABJRU5ErkJggg=='
      });
    }

    this.formattedDate = Helper.TimeHelper.getDateFromDateTime(this.search.dateTime);
    let dateSearch = new Date(this.search.dateTime);
    if (this.search.serviceType == 3) {
      dateSearch = new Date(this.rawItem.estimatedTime.customerTimeReturnTarget);
    }
    console.log(dateSearch);

    this.formattedBookingTime = Helper.TimeHelper.getTimeUTCFromDateTime(dateSearch); // `${dateSearch.getUTCHours()}:${dateSearch.getUTCMinutes() == 0 ? '00' : dateSearch.getUTCMinutes()}`;
    let datePickup = new Date(this.rawItem.estimatedTime.customerTimeBeforeTarget);
    this.formattedPickupTime = Helper.TimeHelper.getTimeUTCFromDateTime(datePickup); // `${datePickup.getUTCHours()}:${datePickup.getUTCMinutes() == 0 ? '00' : datePickup.getUTCMinutes()}`;
    console.log(datePickup)
    this.formattedEstimatedTime = Helper.TimeHelper.getFormattedTimeFromMinutes(new Date(this.rawItem.estimatedTime.companyTotalTime));
    this.formattedFromAddress = Helper.GeoHelper.removeCAPandCountry(this.search.fromLocation.address);
    this.formattedToAddress = Helper.GeoHelper.removeCAPandCountry(this.search.toLocation.address);
    this.formattedWaitingTime = Helper.TimeHelper.getFormattedTimeFromMinutes(this.search.waitingTime);
    
    switch (this.search.vehicleType) {
      case 1:
        this.vehicleType = 'Ambulanza';
        break;
      case 2:
        this.vehicleType = 'Auto';
        break;
      case 3:
        this.vehicleType = 'Trasporto disabili';
        break;
      default:
        this.vehicleType = '???';
        break;
    }

    this.serviceType = this.search.serviceType;

    this.allPropsLoaded = true;
  },
  methods: {

  },
}
</script>

<style scoped>
.containerRating{
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
.containerRating p{
  margin-left: 5px;
    font-size: small;
    color: gray;
}

.ResultInfosContainer{
  padding: 70px;
  border-radius: 80px;
  box-shadow: 0px 0px 50px 7px rgba(0,0,0,0.1);
  position: relative;
  margin-top: 50px;
  position: relative;
  /* height: fit-content; */
  text-align: center;
  background-color: white;
  width: -webkit-fill-available;
  max-width: 1200px;
  margin: 50px;
  min-height: 1115px;
}
.ResultInfosContainer .p-float-label{
    margin: 15px;
    width: max-content;
}
.containerContent{
  position: relative;
  align-items: normal;
  display: flex;
}
.containerTitle{
  text-align: left;
  display: flex;
  align-items: center;
}
.containerTitle h1{
  margin-right: 15px;
}
.companyDescriptionContainer{
  text-align: left;
}
.firstLine, .secondLine{
  text-align: left;
  margin-top: 30px;
}
.secondLineSingleRow{
  display: flex;
  height: 2rem;
  position: relative;
  align-items: center;
}
.secondLineLabel{
  margin-right: 5px;
  color: dimgrey;
}
.imageLine{
  background-color: rgb(211 211 211 / 10%);
  border-radius: 20px;
  height: 100%;
  display: flex;
  align-items: center;
}
.rightSide{
  max-width: 65%;
  padding-right: 15px;
}
.leftSide{
  padding-left: 15px;
  margin-top: 50px;
}
.priceLine{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: rgb(211 211 211 / 10%);
    border-radius: 15px;
    padding-right: 20px;
    padding-left: 20px;
    color: black;
    float: inline-end;
    width: -webkit-fill-available;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 70px;
}
.priceLine p{
  color: grey;
  font-size: x-small;
  font-weight: lighter;
  margin-right: 10px;
}
.priceLine h2{
  margin-left: 0px;
}
.containerFooter{
  margin-top: 25px;
}
.checkBoxContainer{
  margin-top: 20px;
  font-size: medium;
  color: gray;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  position: relative;
}
.checkBoxContainer p{
  margin: 10px;
}
.p-button{
  background-color: #EF8354;
  border-color: #EF8354;
  min-width: 150px;
}
:deep(.p-checkbox-box:hover){
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
:deep(.p-checkbox .p-checkbox-box.p-highlight){
  background:#EF8354;
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
:deep(.p-checkbox-box:focus){
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
.p-button:hover{
  border-color: #bc6844;
  background-color: #bc6844;
}

:deep(.p-inputtext){
  width: 300px;
}
:deep(.p-inputtext:hover){
    border-color: #EF8354;
}
:deep(.p-inputtext:focus){
    border-color: #EF8354;
    box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
:deep(.p-button:focus){
    border-color: #EF8354;
    box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
.containerReturnBackButton span i{
  font-size: 0.7rem; 
  color: grey; 
  margin-top: 20px;
  margin-right: 10px;
  transition: 0.5s;
}
.containerReturnBackButton span i:hover{
  cursor: pointer;
}
.containerReturnBackButton{
  position: relative;
  display: flex;
  display: inline-flex;
  color: grey;
  transition: 0.5s;
  font-size: 1rem;
  align-items: baseline;
}
.containerReturnBackButton:hover{
  cursor: pointer;
}
.align-items-center{
  color: grey;
  max-width: 300px;
  margin: 15px;
}
:deep(.p-checkbox){
  margin-right: 10px !important;
}
:deep(.p-dropdown){
  width: 300px;
  text-align: left;
}
:deep(.p-dropdown .p-dropdown-label:focus){
  border-color: #ffffff;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0);
}
:deep(.p-inputnumber){
  max-width: 300px;
}
.advancedSearchBottomToggle{
  display: flex;
  align-items: baseline;
  justify-content: center;
  color: #747474;
}
.advancedSearchBottomToggle i, .advancedSearchBottomToggle p{
  margin: 10px;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}
.advancedSearchBottomToggle i{
  font-size: small;
}

@media (max-width: 1280px) and (min-width: 501px) {
    .ResultInfosContainer{
      zoom:100%;
    }
    .containerContent{
      margin: 0px;
    }
    .ResultInfosContainer .p-float-label{
      margin: 20px;
      width: max-content;
    }
  }
  @media (max-width: 500px) {
    .ResultInfosContainer{
      padding: 5px;
      border-radius: 20px;
      margin: 10px;
    }
    .containerHeader{
      margin: 10px;
    }
    .containerTitle{
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .containerContent{
      margin: 0px;
      display: block;
    }
    .firstLine, .secondLine {
        text-align: left;
        margin: 15px;
    }
    .priceLine {
        display: flex;
        justify-content: right;
        align-items: center;
        margin: 15px;
        position: relative;
    }
    .imageLine{
      margin: 15px;
    }
    .rightSide{
      max-width: 100%;
      padding-right: 0px;
    }
    .leftSide{
      padding-left: 0px;
      margin-top: 0px;
    }
    .containerFooter{
      display: flex;
    }
    :deep(.p-inputtext){
      width: 300px;
      height: 60px;
    }
    .containerReturnBackButton{
      margin-bottom: 10px;
    }
    :deep(.p-dropdown){
      width: 300px;
      height: 60px;
      line-height: 36px;
    }
  }

</style>