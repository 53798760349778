<!--Vorrei creare un componente per l'insieme di tag che formano una "card", lo valuterò in futuro in base a quanto ci riserviranno-->
<template>
  <div class="HowItWorks">
    <h1>Hai mai sentito parlare di AmbuBook?</h1>
    <div class="row">
      <div v-animateonscroll="{ enterClass: 'fadein', leaveClass: 'fadeout' }" class="infoCard"  id="infoCard1">
        <span class="text-3xl font-bold">Che cos'è AmbuBook?</span>
        <div class="cardContentContainer">
          <div class="infoCardTexts" id="infoCard1Text">{{infoCard1Text}}</div>
          <img src="../assets/Images/AmbulanceNoBKG.png">
        </div>
      </div>
    </div>
    <h1>Prenotare non è mai stato così semplice.</h1>
    <div class="row">
      <div v-animateonscroll="{ enterClass: 'fadein', leaveClass: 'fadeout' }" class="infoCard"  id="infoCard2">
        <span class="text-3xl font-bold">Il valore aggiunto di AmbuBook.</span>
        <div class="infoCardTexts" id="infoCard2Text">{{infoCard2Text}}</div>
      </div>
      <div v-animateonscroll="{ enterClass: 'fadein', leaveClass: 'fadeout' }" class="infoCard" id="infoCard3">
        <span class="text-3xl font-bold">Come posso effettuare la ricerca?</span>
        <div class="cardContentContainer"> 
          <div class="infoCardTexts" id="infoCard3Text">{{infoCard3Text}}</div>
          <i class="pi pi-map-marker"></i>
        </div>
      </div>
    </div>
    <h1>Unica piattaforma, tutto centralizzato.</h1>
    <div class="row">
      <div v-animateonscroll="{ enterClass: 'fadein', leaveClass: 'fadeout' }" class="infoCard"  id="infoCard4">
        <span class="text-3xl font-bold">Come farà Ambubook a sapere le mie esigenze?</span>
        <div class="cardContentContainer"> 
          <div class="infoCardTexts" id="infoCard4Text">{{infoCard4Text}}</div>
          <img src="../assets/Images/SmileNoBKG.png">
        </div>
      </div>
    </div>
    <h2>Effettua ora la tua prima prenotazione!</h2>
  </div>
</template>

<script>
export default {
  name: 'HowItWorksView',
  components: {
  },
  data() {
    return {
      isExpanded: false,
      infoCard1Text: `Ambubook è una piattaforma dove è possibile prenotare molteplici servizi di ambulanze, il tutto in pochissimo tempo. 
      Potrai riservare una o più ambulanze a seconda delle tue esigenze, per te stesso o per i tuoi cari.`,
      infoCard2Text: `"Semplice e veloce” è il nostro motto. 
      Per noi il tuo tempo è prezioso. Con Ambubook, in pochi, semplici e veloci step potrai riservarti un servizio di ambulanza online, così che le associazioni possano vedere in tempo reale la tua prenotazione e organizzarsi al meglio per soddisfare la tua richiesta.`,
      infoCard3Text: 'Sulla nostra piattaforma sceglierai la data e l’orario a seconda delle tue esigenze e Ambubook ti mostrerà tutte le ambulanze disponibili messe a disposizione da ciascuna associazione presente nella tua zona e dintorni.',
      infoCard4Text: `Grazie al supporto delle associazioni ed aziende aderenti alla nostra piattaforma potrai trovare i sevizi di cui necessiti. 
      Qualsiasi tipo di esigenza Ambubook saprà soddisfarla al meglio!`,

    };
  },
  methods: {
    expandBox() {
      this.isExpanded = true;
    },
    shrinkBox() {
      this.isExpanded = false;
    },
  },

}
</script>

<style scoped>
.HowItWorks{
    position: relative;
    z-index: -2;
    margin:100px;
    text-align: center;
}
.HowItWorks h1{
  font-size: xxx-large;
}
.infoCard{
    transition: width 0.3s;
    height: 400px;
    background-color: rgb(247 247 247);
    margin: 30px;
    border-radius: 70px;
    position: relative;
    padding: 50px;
    -webkit-box-shadow: 0px 0px 50px 7px rgba(0,0,0,0.22);
    box-shadow: 0px 0px 50px 7px rgba(0,0,0,0.1);
    align-content: center;
    justify-content: center;
    align-items: center;
    display: grid;
}
.cardContentContainer{
  position: relative;
  display: flex;
  align-items: center;
}
.cardContentContainer img{
  height: 90px;
  opacity: 30%;
}
.cardContentContainer i{
  font-size: 50px;
  opacity: 30%;
}
.row{
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.infoCardTexts{
  color: white;
  text-align: left;
  padding: 50px;
  font-size: larger;
  width: 500px;
  margin: auto;
}
#topMainText, #infoCard3Text{
  color: black;
}
#infoCard1{
  width: 1660px;
  animation-duration: 1000ms;
  background-color: #EF8354;
  color: white;
}
#infoCard1:hover{
  width: 1710px;
  transition: width 0.3s;
}
#infoCard2{
  width:600px;
  animation-duration: 1000ms;
  background-color: #bfc0c0;
  color: white;
}
#infoCard2:hover{
  width: 650px;
  transition: width 0.3s;
}
#infoCard3{
  width: 1000px;
  animation-duration: 1200ms;
  background-color: #FFFFFF;
  color: #4F5D75;
}
#infoCard3:hover{
  width: 1050px;
  transition: width 0.3s;
}
#infoCard4{
  width: 1660px;
  animation-duration: 1000ms;
  background-color: #4F5D75;
  color: white;
}
#infoCard4:hover{
  width: 1710px;
  transition: width 0.3s;
}

@media (max-width: 1280px) {

  .HowItWorks{
    position: relative;
    z-index: -2;
    margin:30px;
    text-align: center;
  }
  .HowItWorks h1{
    font-size: xx-large;
  }
  .row{
    display: grid;
    align-content: center;
    justify-content: center;
  }

  .infoCardTexts{
    color: white;
    padding: 0px;
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: medium;
    margin: auto;
    width: auto;
    text-align: center;
  }

  #infoCard1,#infoCard2,#infoCard3,#infoCard4{
    padding: 40px;
    width: auto;
    height: auto;
    overflow: hidden;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .cardContentContainer{
    display: block;
    align-content: center;
    justify-content: center;
  }

}
</style>
