<template>
    <div class="BookingInfosContainer" v-if="allPropsLoaded">
        <div class="bookingStatusContainer">
            <div class="bookingNumberTitle" v-if="allPropsLoaded">
                <h3 id="bookingNumberTitleLabel">Numero di prenotazione:</h3>
                <h3>{{ booking.bookingNumber }}</h3>
            </div>
            <div style="display: flex; align-items: center;">
                <Tag class="text-sm" v-if="booking.bookingState === 0" severity="danger" value="Errore" rounded></Tag>
                <Tag class="text-sm" v-if="booking.bookingState === 1" severity="warning" value="In attesa" rounded></Tag>
                <Tag class="text-sm" v-if="booking.bookingState === 2" severity="info" value="Accettata" rounded></Tag>
                <Tag class="text-sm" v-if="booking.bookingState === 3" severity="danger" value="Rifiutata" rounded></Tag>
                <Tag class="text-sm" v-if="booking.bookingState === 4" severity="contrast" value="Cancellata" rounded></Tag>
                <Tag class="text-sm" v-if="booking.bookingState === 5" severity="success" value="Completata" rounded></Tag>
                <Tag class="text-sm" v-if="booking.bookingState === 6" severity="danger" value="Scaduta" rounded></Tag>
                <i class="pi pi-info-circle" style="margin-left: 10px; color: darkgray;" v-tooltip="'Indica lo stato di questa prenotazione.'"></i>
            </div>
        </div>
        <div class="flex flex-wrap justify-content-end">
            <Button label="Stampa" icon="pi pi-print" class="p-button-primary max-w-10rem"
            @click="printBookingPDF()"></Button>
        </div>
        <br>
        <div class="containerHeader"> 
            <Map :from="booking.fromLocation" :to="booking.toLocation" style="display: block;"></Map>
        </div>

        <div v-if="allPropsLoaded">
            <Accordion class="InformationsContainer" :multiple="true" :activeIndex="[0]">
                <AccordionTab header="Informazioni Prenotazione">
                    <div class="infoRow">
                        <p class="infoRowLabel">Nome e cognome del prenotante</p>
                        <p class="infoRowValue">{{userBooking.firstName}} {{userBooking.lastName}}</p>
                    </div>
                    <div class="infoRow">
                        <p class="infoRowLabel">Numero di telefono</p>
                        <p class="infoRowValue">{{userBooking.telephone}}</p>
                    </div>
                    <div class="infoRow">
                        <p class="infoRowLabel">Email</p>
                        <p class="infoRowValue">{{userBooking.email}}</p>
                    </div>
                    <div class="infoRow">
                        <p class="infoRowLabel">Indirizzo di partenza</p>
                        <p class="infoRowValue">{{booking.fromLocation.address}}</p>
                    </div>
                    <div class="infoRow">
                        <p class="infoRowLabel">Indirizzo di arrivo</p>
                        <p class="infoRowValue">{{booking.toLocation.address}}</p>
                    </div>
                    <div class="infoRow" v-if="booking.serviceType === 2">
                        <p class="infoRowLabel">Tempo di attesa</p>
                        <p class="infoRowValue">{{booking.waitingTime}}</p>
                    </div>
                    <div class="infoRow">
                        <p class="infoRowLabel">Tipo di prenotazione</p>
                        <p class="infoRowValue">{{booking.serviceType === 1 ? 'Trasporto' : booking.serviceType === 2 ? 'Visita' : booking.serviceType === 3 ? 'Dimissioni' : 'Errore'}}</p>
                    </div>
                    <div class="infoRow" v-if="booking.serviceType === 2">
                        <p class="infoRowLabel">Tipo di visia</p>
                        <p class="infoRowValue">{{ booking.transportedInfo.typeOfExamination }}</p>
                    </div>
                    <div class="infoRow">
                        <p class="infoRowLabel">Veicolo assegnato</p>
                        <p class="infoRowValue">{{service.name}}</p>
                    </div>
                    <div class="infoRow">
                        <p class="infoRowLabel">Data e ora richiesta</p>
                        <p class="infoRowValue">{{booking.dateTimeToLocation}}</p>
                    </div>
                    <div class="infoRow">
                        <div style="display: flex; align-items: center;">
                            <p class="infoRowLabel">Data e ora di partenza dalla base</p>
                            <i class="pi pi-info-circle" style="margin-left: 10px; color: darkgray;" v-tooltip="'Gli orari sono stimati e possono variare in base al traffico e alle condizioni meteo.'"></i>
                        </div>
                        <p class="infoRowValue">{{booking.estimatedTime.companyTimeBeforeTarget}}</p>
                    </div>
                    <div class="infoRow">
                        <div style="display: flex; align-items: center;">
                            <p class="infoRowLabel">Data e ora indirizzo di partenza</p>
                            <i class="pi pi-info-circle" style="margin-left: 10px; color: darkgray;" v-tooltip="'Gli orari sono stimati e possono variare in base al traffico e alle condizioni meteo.'"></i>
                        </div>
                        
                        <p class="infoRowValue">{{ booking.serviceType !== 2 ? 'Coincidente con la richiesta' : booking.estimatedTime.customerTimeBeforeTarget}}</p>
                    </div>
                    <div class="infoRow">
                        <div style="display: flex; align-items: center;">
                            <p class="infoRowLabel">Data e ora di ritorno alla base</p>
                            <i class="pi pi-info-circle" style="margin-left: 10px; color: darkgray;" v-tooltip="'Gli orari sono stimati e possono variare in base al traffico e alle condizioni meteo.'"></i>
                        </div>
                        <p class="infoRowValue">{{booking.estimatedTime.companyTimeAfterTarget}}</p>
                    </div>
                    <div class="infoRow">
                        <div style="display: flex; align-items: center;">
                            <p class="infoRowLabel">Distanza totale</p>
                        </div>
                        <p class="infoRowValue">{{booking.totalDistance}} Km</p>
                    </div>
                </AccordionTab>
                <AccordionTab header="Informazioni Paziente Trasportato">
                    <div class="infoRow">
                        <p class="infoRowLabel">Nome e cognome del paziente</p>
                        <p class="infoRowValue">{{booking.transportedInfo.firstName}} {{booking.transportedInfo.lastName}}</p>
                    </div>
                    <div class="infoRow">
                        <p class="infoRowLabel">Sesso</p>
                        <p class="infoRowValue">{{ booking.transportedInfo.sex == 1 ? 'Maschio' : 'Femmina'}}</p>
                    </div>
                    <div class="infoRow">
                        <p class="infoRowLabel">Data di nascita</p>
                        <p class="infoRowValue">{{booking.transportedInfo.birthDate}}</p>
                    </div>
                    <div class="infoRow">
                        <p class="infoRowLabel">Peso</p>
                        <p class="infoRowValue">{{booking.transportedInfo.weight}} Kg</p>
                    </div>
                    <div class="infoRow">
                        <p class="infoRowLabel">Cellulare di riferimento</p>
                        <p class="infoRowValue">{{booking.transportedInfo.referencePhoneNum}}</p>
                    </div>
                    <div class="infoRow">
                        <p class="infoRowLabel">Deambulante</p>
                        <p class="infoRowValue">{{booking.transportedInfo.canWalk ? 'Si' : 'No'}}</p>
                    </div>
                    <div class="infoRow">
                        <p class="infoRowLabel">Necessita barella</p>
                        <p class="infoRowValue">{{booking.transportedInfo.needStretcher ? 'Si' : 'No'}}</p>
                    </div>
                    <div class="infoRow">
                        <p class="infoRowLabel">È presente accompagnatore</p>
                        <p class="infoRowValue">{{booking.transportedInfo.companionPresent ? 'Si' : 'No'}}</p>
                    </div>
                    <div class="infoRow">
                        <p class="infoRowLabel">È presente l'ascensore</p>
                        <p class="infoRowValue">{{booking.transportedInfo.elevatorPresent ? 'Si' : 'No'}}</p>
                    </div>
                    <div class="infoRow">
                        <p class="infoRowLabel">Può stare seduto</p>
                        <p class="infoRowValue">{{booking.transportedInfo.canStaySitted ? 'Si' : 'No'}}</p>
                    </div>
                    <div class="infoRow">
                        <p class="infoRowLabel">Neccessita ossigeno</p>
                        <p class="infoRowValue">{{booking.transportedInfo.needOxygen ? 'Si' : 'No'}}</p>
                    </div>
                    <div class="infoRow">
                        <p class="infoRowLabel">Piano di casa</p>
                        <p class="infoRowValue">{{booking.transportedInfo.floor}}</p>
                    </div>
                </AccordionTab>
                <AccordionTab header="Note Aggiuntive">
                    {{booking.transportedInfo.notes}}
                </AccordionTab>
            </Accordion>
        </div>
    </div>
    
    <Toast ref="toast" position="bottom-right" style="right:0px; width: -webkit-fill-available; margin: 10px; max-width: 500px;"/>
</template>

<script>
    import API from '../api/API';
    import Cookies from 'js-cookie';
    import Map from '../components/Map.vue'
    import Helper from '../helper/Helpers';

    export default {
        name: 'BookingInfo',

        props: {
            bookingId: {
                required: true
            }
        },
        data() {
            return {
                allPropsLoaded: false,
                booking: {},
                userBooking: {},
                service: {}
            }
        },
        components: {
            Map
        },
        mounted() {
            this.loadBooking();
        },
        methods: {
            loadBooking() {
                const jwt = Cookies.get('token');

                if (jwt === undefined)
                {
                    console.error('Token non presente');
                }
                
                let self = this;
                API.BookingAPI.GetBooking(jwt, this.bookingId).then((response) => {
                    this.booking = response.data;
                    this.booking.dateTimeToLocation = 
                        Helper.TimeHelper.getDateFromDateTime(new Date(this.booking.dateTimeToLocation)) + 
                        ' ' + Helper.TimeHelper.getTimeFromDateTime(new Date(this.booking.dateTimeToLocation));
                    
                    this.booking.estimatedTime.companyTimeBeforeTarget = 
                        Helper.TimeHelper.getDateFromDateTime(new Date(this.booking.estimatedTime.companyTimeBeforeTarget)) + 
                        ' ' + Helper.TimeHelper.getTimeFromDateTime(new Date(this.booking.estimatedTime.companyTimeBeforeTarget));
                    
                    this.booking.estimatedTime.companyTimeAfterTarget =
                        Helper.TimeHelper.getDateFromDateTime(new Date(this.booking.estimatedTime.companyTimeAfterTarget)) + 
                        ' ' + Helper.TimeHelper.getTimeFromDateTime(new Date(this.booking.estimatedTime.companyTimeAfterTarget));

                    this.booking.estimatedTime.customerTimeBeforeTarget = 
                        Helper.TimeHelper.getDateFromDateTime(new Date(this.booking.estimatedTime.customerTimeBeforeTarget)) + 
                        ' ' + Helper.TimeHelper.getTimeFromDateTime(new Date(this.booking.estimatedTime.customerTimeBeforeTarget));

                    this.booking.waitingTime = Helper.TimeHelper.getFormattedTimeFromMinutes(this.booking.waitingTime);
                    this.booking.transportedInfo.birthDate = Helper.TimeHelper.getDateFromDateTime(new Date(this.booking.transportedInfo.birthDate));

                    API.UserAPI.GetUser(jwt, this.booking.userId).then((response) => {
                        self.userBooking = response.data;
                        self.userBooking.birthDate = Helper.TimeHelper.getDateFromDateTime(new Date(self.userBooking.birthDate));
                        self.allPropsLoaded = true;
                    }).catch((error) => {
                        console.error(error);
                    });

                    API.ServiceAPI.GetServiceByID(jwt, this.booking.serviceId).then((response) => {
                        self.service = response.data;
                    }).catch((error) => {
                        console.error(error);
                    });
                }).catch((error) => {
                    console.error(error);
                });
            },
            printBookingPDF() {
                const jwt = Cookies.get('token');
                API.BookingAPI.GetBookingPDF(jwt, this.bookingId).then((response) => {
                    const sanitizedBookingId = this.booking.bookingNumber.replace(/\//g, '_');

                    const file = new File(
                        [response.data], `${sanitizedBookingId}_AmbuBook.pdf`, {type: 'application/pdf'}
                    );
                    const fileURL = URL.createObjectURL(file);

                    const fileName = `${sanitizedBookingId}_AmbuBook.pdf`;

                    window.open(fileURL);

                    this.$refs.toast.add({severity:'success', summary: 'Successo', detail: 'PDF generato correttamente',life: 2500});
                }).catch((error) => {
                    this.$refs.toast.add({severity:'error', summary: 'Ops', detail: 'Errore nella generazione del PDF',life: 2500});
                });
            }
        }
    }
</script>

<style scoped>
.BookingInfosContainer{
    padding: 40px;
    padding-top: 0px;
    padding-bottom: 0px;
}
.bookingNumberTitle{
    display: flex;
    align-items: center;
}
#bookingNumberTitleLabel{
    color: gray;
    margin-right: 10px
}
.bookingStatusContainer{
    display: flex;
    padding: 5px;
    justify-content: space-between;
    align-items: center;
}
.InformationsContainer{
    margin-top: 25px;
}
.infoRow{
    display: flex;
    justify-content: space-between;
    height: 30px;
    align-items: center;
}
.infoRowLabel{
    color: gray;
}

@media (max-width: 500px), (max-height: 900px) {
    .bookingStatusContainer{
        font-size: x-small;
    }
    .BookingInfosContainer{
        padding: 0px;
    }
    .bookingNumberTitle{
        width: min-content;
    }
    :deep(.p-accordion-content){
        font-size: xx-small;
    }
}

</style>