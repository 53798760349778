<template>
    <div class="SignUp">
      <SignUpForm v-if="type" class="SignUpForm" :t="type" @select-value-changed="handleSelectValueChanged" />
      <div v-if="isOnDesktopDisplay || isOnTabletDisplay" class="lateralPanel" ref="lateralPanel"> 
        <div v-if="isOnDesktopDisplay && !isOnTabletDisplay" class="lateralPanelTitle">
          <h1>{{ getTitleText() }}</h1>
        </div>
      </div>
      <div v-if="isOnDesktopDisplay || isOnTabletDisplay" class="lateralPanel2" ref="lateralPanel2"></div>
    </div>
  </template>
  
  <script>
  import SignUpForm from '../components/SignUpForm.vue'
  export default {
    name: 'SignUpView',
    components: {
        SignUpForm,
    },
    props: {

    },
    data(){
      return{
        //Global Variables
        isOnDesktopDisplay: window.innerWidth > 1280,
        isOnTabletDisplay: window.innerWidth < 1280 && window.innerWidth > 500,
        selectedValueFromChild: 'Privato',
        CustomerTextToShow:'Unisciti ad AmbuBook per prenotare ora un mezzo!',
        CompanyTextToShow:'Unisciti ad AmbuBook ed inizia ad aiutare il tuo territorio!',

        type: null
      }
    },
    mounted() {
      if (this.$route.query.t === undefined) {
        this.type = 'Utente';
      } else {
        this.type = this.$route.query.t;
      }
      
      setTimeout(() => {
        if(this.isOnDesktopDisplay){
          this.$refs.lateralPanel.style.opacity = '75%';
          this.$refs.lateralPanel2.style.opacity = '60%';
        }
      }, 100);
    },
    methods: {
      handleSelectValueChanged(newSelectValue) {
        this.selectedValueFromChild = newSelectValue;
      },
      getTitleText() {
        if (this.selectedValueFromChild === 'Utente') {
          return this.CustomerTextToShow;
        } else if (this.selectedValueFromChild === 'Azienda') {
          return this.CompanyTextToShow;
        } else {
          return '';
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .SignUp{
    position: relative;
    z-index: -2;
    margin:0px;
    text-align: center;
    display: flex;
  }
  .lateralPanel{
    background-color: #ef8354;
    width: 60%;
    height: auto;
    border-radius: 1000px 300px 0 0;
    margin-top: 50px;
    position: relative;
    transition: opacity 1s ease-out;
    opacity: 0;
    box-shadow: -32px -20px 50px -18px #dddddd;
  }
  .lateralPanel2{
    height: 100%;
    width: 60%;
    position: absolute;
    background-color: #bfc0c0;
    z-index: -1;
    float: right;
    position: absolute;
    right: 0;
    border-radius: 0px 0 0 1000px;
    transition: opacity 1s ease-out;
    opacity: 0;
    box-shadow: -32px -20px 50px -18px #dddddd;
  }
  .lateralPanelTitle{
    color: white;
    position: relative;
    margin-top: 300px;
    padding-left: 400px;
    font-size: -webkit-xxx-large;
    text-align: right;
    padding-right: 100px;
    opacity: 100%;
  }
  @media (max-width: 1280px) and (min-width: 501px) {
    .SignUp{
      /*height: 100vh;*/
      align-items: center;
      /*margin: 50px;*/
      margin-top: 20px;
      margin-bottom: 20px;
    }  

    .lateralPanel2{
      height: 100%;
      width: 90%;
      position: absolute;
      background-color: #bfc0c0;
      z-index: -1;
      float: right;
      position: absolute;
      right: 0;
      border-radius: 1000px 0 0 800px;
      transition: opacity 1s ease-out;
      margin-bottom: 50%;
      opacity: 0.4;
      box-shadow: -32px -20px 50px -18px rgba(0,0,0,0.1);
    }

    .lateralPanel{
      background-color: #ef8354;
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: -1;
      position: absolute;
      left: 0;
      border-radius: 0 1000px 400px 0;
      transition: opacity 1s ease-out;
      opacity: 0.2;
      box-shadow: -32px -20px 50px -18px #dddddd;
      margin-top: 20%;
      width: 95%;
    }
  }
  @media (max-width: 500px) {
    .SignUp{
      align-items: center;
    }  
  }
  </style>