import axios from 'axios';
import config from '@/config';

export default {
    async GetAvailabilitiesForService(jwt, serviceId, companyId, startDate, endDate) {
        const resp = await axios.post(`${config.backendIP}/api/v1/company/availabilities`, {
            serviceId: serviceId,
            companyId: companyId,
            startTime: startDate,
            endTime: endDate
        }, {
            headers: {
                Authorization: `${jwt}`
            }
        });
        return resp;
    },
    async PostAvailability(jwt, serviceId, companyId, startDate, endDate) {
        const resp = await axios.post(`${config.backendIP}/api/v1/company/availability`, {
            serviceId: serviceId,
            companyId: companyId,
            startTime: startDate,
            endTime: endDate
        }, {
            headers: {
                Authorization: `${jwt}`
            }
        });
        return resp;
    },
    async PutAvailability(jwt, availabilityId, startDate, endDate) {
        const resp = await axios.put(`${config.backendIP}/api/v1/company/availability/${availabilityId}`, {
            startTime: startDate,
            endTime: endDate
        }, {
            headers: {
                Authorization: `${jwt}`
            }
        });
        return resp;
    },
    async DeleteAvailability(jwt, availabilityId) {
        const resp = await axios.delete(`${config.backendIP}/api/v1/company/availability/${availabilityId}`, {
            headers: {
                Authorization: `${jwt}`
            }
        });
        return resp;
    }
};