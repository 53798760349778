<template>
    <br>
    <br>
    <br>
    <br>
    <br>
    <div class="flex overflow-hidden">
        <div class="ResultText flex-grow-1 flex align-items-center justify-content-center" if v-if="ratingSent === undefined">
            <h1>Stiamo verificando...</h1>
        </div>
        <div class="ResultText flex-grow-1 flex align-items-center justify-content-center" if v-else-if="ratingSent === true">
            <h1>Grazie per il tuo voto!</h1>
        </div>
        <div class="ResultText flex-grow-1 flex align-items-center justify-content-center" if v-else>
            <h1>Ops... Qualcosa è andato storto</h1>
        </div>

    </div>
    <div class="flex overflow-hidden">
        <div class="flex-grow-1 flex align-items-center justify-content-center">
            <Button label="Home" @click="this.$router.replace('/')" rounded />
        </div>
    </div>
    <br>
    <br>
    <br>
    <br>
    <br>
</template>

<script>
    import API from '../api/API'
    export default {
        name: 'RatingView',
        data() {
            return {
                ratingSent: undefined
            }
        },
        mounted() {
            const bookingId = this.$route.params.bookingId
            const token = this.$route.params.token
            const rating = this.$route.params.rating
            API.UserAPI.SendRating(bookingId, token, rating)
                .then(() => {
                    this.ratingSent = true
                })
                .catch(() => {
                    this.ratingSent = false
                })
        }
    }
</script>

<style scoped>
  .ResultText{
    z-index: -2;
    padding: 0;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 0 0 900px 0;
    margin: 0 auto;
    height: auto;
    line-height: normal;
    margin-bottom: 20px;
    position: relative;
  }
  .ResultText h1{
    color: rgb(0, 0, 0);
    font-size: 3rem;
    padding-top: 20px;
    margin: 0 auto;
  }
</style>
  