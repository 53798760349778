// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { isSupported } from "firebase/messaging";
// https://firebase.google.com/docs/web/setup#available-libraries

import API from '@/api/API';
import Cookies from "js-cookie";
import ToastEventBus from 'primevue/toasteventbus';

const useToastService = () => {
  const showToast = () => {
    ToastEventBus.emit('add', { severity: 'success', detail:'Notifiche attivate!', life: 3000 });
  }

  return { showToast };
};

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBfkMotE34L2ByS5ljbFkrtQuv2UE81Sc8",
  authDomain: "ambubook-1702134362742.firebaseapp.com",
  projectId: "ambubook-1702134362742",
  storageBucket: "ambubook-1702134362742.appspot.com",
  messagingSenderId: "585409505434",
  appId: "1:585409505434:web:02e1687ea92a566edc1723",
  measurementId: "G-8M8R9LPY7V"
};

// Inizializza Firebase
const app = initializeApp(firebaseConfig);
const messaging = (async () => {
try {
    const isSupportedBrowser = await isSupported();
    console.log('Firebase supported:', isSupportedBrowser);
    if (isSupportedBrowser) {
        return getMessaging(app);
    }
    console.log('Firebase not supported this browser');
    return null;
} catch (err) {
    console.log(err);
    return null;
}
})();

// Chiedi il permesso per le notifiche e ottieni il token
export function askNotificationPermission() {
  return Notification.requestPermission().then(permission => {
    if (permission === 'granted') {
      console.log('Permesso per le notifiche concesso.');
      return getTokenFCM();
    } else {
      console.log('Permesso per le notifiche negato.');
      return null;
    }
  });
}

// Ottieni il token FCM
function getTokenFCM() {
  return getToken(messaging, { vapidKey: 'BGxuYbow6zD2ZppH9xjCy8Gd3NqHLSxJbYO9aNlo41upf1Za2BsYC7H8kwdpVqkkAzeQhYRadVHraPBbW3aUxnM' }).then((currentToken) => {
    if (currentToken) {
      console.log('Token FCM ottenuto:', currentToken);
      saveTokenToServer(currentToken);
      return currentToken;
    } else {
      console.log('Nessun token FCM disponibile.');
      return null;
    }
  }).catch(err => {
    console.log('Errore nel recupero del token FCM:', err);
    return null;
  });
}

// Funzione per salvare il token nel server
function saveTokenToServer(token) {
  const jwt = Cookies.get('token');
  const userId = Cookies.get('id');
  if (jwt && userId) {
    API.UserAPI.SetFCMToken(jwt, userId, token).then(resp => {
      console.log('Token FCM salvato nel server:', resp);
      useToastService().showToast();
    }).catch(err => {
      console.error('Errore nel salvataggio del token FCM nel server:', err);
    });
  }
}

// Ricevi le notifiche in background
onMessage(messaging, (payload) => {
  console.log('Notifica ricevuta in background:', payload);
  const notificationTitle = payload.notification.title;
  const notificationOptions = {
    body: payload.notification.body,
    icon: '/favicon.png'
  };
  new Notification(notificationTitle, notificationOptions);

});