import axios from 'axios';
import config from '@/config';
import Cookies from 'js-cookie';

export default {
    async PostUserAndCompany(postData) {
        const resp = await axios.post(`${config.backendIP}/api/signup/company`, postData);

        return resp;
    },
    async PostUser(user) {
        const resp = await axios.post(`${config.backendIP}/api/signup/user`, user);
        
        return resp;
    },
    async ChangePassword(jwt, email, newPassword, oldPassword) {
        const resp = await axios.post(`${config.backendIP}/api/v1/user/password`, {
            email: email,
            newPassword: newPassword,
            oldPassword: oldPassword
        }, {
            headers: {
                Authorization: `${jwt}`
            }
        });

        return resp;
    },
    async SendResetPasswordEmail(email) {
        const resp = await axios.get(`${config.backendIP}/api/user/password/pin/${email}`);

        return resp;
    },
    async ResetPassword(email, pin, newPassword) {
        const resp = await axios.post(`${config.backendIP}/api/user/password/pin`, {
            email: email,
            pin: pin,
            password: newPassword
        });

        return resp;
    },
    async Login(email, password, rememberMe, recaptchaToken) {
        const resp = await axios.post(`${config.backendIP}/api/signin`, {
            recaptcha: recaptchaToken,
            email: email,
            password: password,
            rememberMe: rememberMe
        });

        return resp;
    },
    async IsUserLogged(jwt) {
        const resp = await axios.post(`${config.backendIP}/api/v1/userLogged`, {}, {
            headers: {
                Authorization: `${jwt}`
            }
        });

        return resp;
    },
    async IsCompanyAdminLogged(jwt) {
        const userType = Cookies.get('userType');
        let resp;
        if (jwt && (userType == "2" || userType == "1")) {
            console.log('IsCompanyAdminLogged ' + typeof userType);
            resp = await axios.post(`${config.backendIP}/api/v1/companyLogged`, {}, {
                headers: {
                    Authorization: `${jwt}`
                }
            });
        } else {
            return Error('User is not a company admin');
        }
        
        return resp;
    },
    async IsAdminLogged(jwt) {
        const resp = await axios.post(`${config.backendIP}/api/v1/adminLogged`, {}, {
            headers: {
                Authorization: `${jwt}`
            }
        });

        return resp;
    },
    async ConfirmEmail(hash) {
        return await axios.get(`${config.backendIP}/api/email/confirmation/${hash}`);
    },
    async SendRating(bookingId, token, rating) {
        return await axios.post(`${config.backendIP}/api/rate/${bookingId}/${token}/${rating}`);
    },
    async GetUser(jwt, userId) {
        return await axios.get(`${config.backendIP}/api/v1/user/${userId}`, {
            headers: {
                Authorization: `${jwt}`
            }
        });
    },
    async GetAllUserBookings(jwt, userId) {
        const resp = await axios.get(`${config.backendIP}/api/v1/user/bookings/${userId}`, {
            headers: {
                Authorization: `${jwt}`
            }
        });

        return resp;
    },
    async SetFCMToken(jwt, userId, token) {
        return await axios.post(`${config.backendIP}/api/v1/user/fcm`, {
            userId: userId,
            fcmToken: token
        }, {
            headers: {
                Authorization: `${jwt}`
            }
        });
    }
};