<template>
    <div class="cardContainer">
        <!-- Richiede all'utente tramite un bottone l'attivazione delle notifiche -->
        <h2>Attiva le notifiche</h2>
        <p>Per ricevere le notifiche direttamente sul tuo dispositivo su nuove prenotazioni ed aggiornamenti, attiva le notifiche.</p>
        <br>
        <Button label="Attiva le notifiche" icon="pi pi-bell" @click="dialogVisible = true" rounded />
    </div>
    <Dialog v-model:visible="dialogVisible" class="text-center" modal header="⚠️ Attenzione" :style="{ width: '55vw' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <p class="m-0 font-semibold text-red-500">
        Le notifiche vengono ricevute solo sui dispositivi su cui si è acconsentito.<br>
        Per disattivare le notifiche, vai nelle impostazioni del tuo browser e disattiva le notifiche per il nostro sito.<br>
        Se non ricevi le notifiche, controlla le impostazioni del tuo browser o prova ad attivarle nuovamente.<br>
        Se hai un computer Apple o Windows le notifiche funzionano solo se il browser è acceso, non è necessario essere su AmbuBook.<br>
        Se hai un dispositivo iOS o Android, le notifiche funzionano anche se il browser è chiuso, ma AmbuBook deve essere salvato nella home del telefono/tablet.
      </p>
      <br>
      <strong>Una volta apparsa la richiesta di consenso premi "Consenti".</strong>
      <br>
      <br>
      <Button label="Ho capito, attiva" icon="pi pi-bell" @click="onActivate()" rounded />
    </Dialog>
</template>

<script>
import { askNotificationPermission } from '@/services/firebaseMessagingService.js';

export default {
  name: 'CompanyRequestNotification',
  components: {
  },
  mounted() {
  },
  data() {
    return {
      dialogVisible: false
    }
  },
  methods: {
    onActivate() {
        askNotificationPermission();
        this.dialogVisible = false;
    }
  }
}
</script>

<style scoped>
.cardContainer{
    padding: 70px;
    border-radius: 50px;
    box-shadow: 0px 0px 50px 7px rgba(0,0,0,0.1);
    max-width: 100%;
    position: relative;
    /* margin: 200px auto; */
    position: relative;
    height: fit-content;
    text-align: center;
    background-color: white;
}

.containerFooter{
  margin-top: 25px;
}
@media (max-width: 500px){
    .cardContainer{
      padding: 20px;
      border-radius: 20px;
      margin: 10px;
    }
}
</style>