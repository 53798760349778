export default {
    // Retruns the date to local time
    getFormattedDate(parameterDate) {
        let thisDate = new Date(parameterDate.toISOString().slice(0, 10) + 'T' + parameterDate.toISOString().slice(11, 17) + '00Z');
        let utcDate = new Date(thisDate);
        let localTime = thisDate.getTime() - utcDate.getTimezoneOffset() * 60 * 1000;
        let localDate = new Date(localTime);
        return localDate;
    },
    // Orribile - workaround per il problema di primevue
    addLocalTimeToDate(parameterDate) {
        let thisDate = new Date(parameterDate.toISOString().slice(0, 10) + 'T' + parameterDate.toISOString().slice(11, 17) + '00Z');
        let utcDate = new Date(thisDate);
        let localTime = thisDate.getTime() + utcDate.getTimezoneOffset() * 60 * 1000;
        let localDate = new Date(localTime);
        return localDate;
    },
    getFormattedDateISO(parameterDate) {
        let thisDate = new Date(parameterDate.toISOString().slice(0, 10) + 'T' + parameterDate.toISOString().slice(11, 17) + '00Z');
        let utcDate = new Date(thisDate);
        let localTime = thisDate.getTime() - utcDate.getTimezoneOffset() * 60 * 1000;
        let localDate = new Date(localTime);
        let localIso = localDate.toISOString();
        return localIso;
    },
    getFormattedTimeFromMinutes(minutes) {
        let hours = Math.floor(minutes / 60);
        let remainingMinutes = minutes % 60;
        let oreTxt = hours === 1 ? ' ora ' : ' ore ';
        let minutiTxt = remainingMinutes === 1 ? ' minuto ' : ' minuti ';
        if (hours === 0 && remainingMinutes !== 0) {
            return remainingMinutes + minutiTxt;
        } else if (hours !== 0 && remainingMinutes === 0) {
            return hours + oreTxt;
        } else {
            return hours + oreTxt + remainingMinutes + minutiTxt;
        }
    },
    getDateToUTC(date) { 
        return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    },
    getDateFromDateTime(dateTime) {
        let date = new Date(dateTime);
        let utcDate = date.getUTCDate();
        let utcMonth = date.getUTCMonth() + 1;
        let utcYear = date.getUTCFullYear();
        if (utcDate < 10) {
            utcDate = '0' + utcDate;
        }
        if (utcMonth < 10) {
            utcMonth = '0' + utcMonth;
        }
        return `${utcDate}/${utcMonth}/${utcYear}`;
    },
    getTimeFromDateTime(dateTime) {
        let date = new Date(dateTime);
        let utcHour = date.getUTCHours();
        let utcMinute = date.getUTCMinutes();
        if (utcHour < 10) {
            utcHour = '0' + utcHour;
        }
        if (utcMinute < 10) {
            utcMinute = '0' + utcMinute;
        }
        return `${utcHour}:${utcMinute}`;
    },
    getTimeUTCFromDateTime(dateTime) {
        let date = new Date(dateTime);
        let utcHour = date.getUTCHours();
        let utcMinute = date.getUTCMinutes();
        if (utcHour < 10) {
            utcHour = '0' + utcHour;
        }
        if (utcMinute < 10) {
            utcMinute = '0' + utcMinute;
        }
        return `${utcHour}:${utcMinute}`;
    },
    getMonthName(date) {
        const months = [
            "Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno",
            "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"
        ];
        const monthIndex = date.getMonth();
        return months[monthIndex];
    },
    translateEnglishToItalianMonth(month) {
        switch (month) {
            case 'January':
                return 'Gennaio';
            case 'February':
                return 'Febbraio';
            case 'March':
                return 'Marzo';
            case 'April':
                return 'Aprile';
            case 'May':
                return 'Maggio';
            case 'June':
                return 'Giugno';
            case 'July':
                return 'Luglio';
            case 'August':
                return 'Agosto';
            case 'September':
                return 'Settembre';
            case 'October':
                return 'Ottobre';
            case 'November':
                return 'Novembre';
            case 'December':
                return 'Dicembre';
            default:
                return '';
        }
    },
    isToday(date) {
        let today = new Date();
        return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
    },
    isTimePlusOneHourLessThanNow(time) {
        let now = new Date();
        let nowPlusOneHour = new Date(now.getTime() + 60 * 60 * 1000);
        let timeToCheck = new Date(time);
        return timeToCheck < nowPlusOneHour;
    },
}