<template>
    <div class="Results">
      
      <SearchBox v-if="isOnMobile && showSearchBox" :search-object="search" :show-closing-button="true" :mobile-mode="true" :start="this.search.fromLocation.address" :destination="this.search.toLocation.address"></SearchBox>
      <SearchBox v-if="!isOnMobile && showSearchBox" :search-object="search" :show-closing-button="false" :mobile-mode="false" style="margin: 5px; -webkit-box-shadow: 0px 0px 12px -1px rgba(0,0,0,0.5); box-shadow: 0px 0px 12px -1px rgba(0,0,0,0.05); min-width: 700px;"></SearchBox>

      <ResultSkeleton v-if="!showResults && !noResults"></ResultSkeleton>
      <ResultSkeleton v-if="!showResults && !noResults"></ResultSkeleton>
      <ResultSkeleton v-if="!showResults && !noResults"></ResultSkeleton>
      <ResultSkeleton v-if="!showResults && !noResults"></ResultSkeleton>
      <ResultSkeleton v-if="!showResults && !noResults"></ResultSkeleton>
      <ResultSkeleton v-if="!showResults && !noResults"></ResultSkeleton>
      <ResultSkeleton v-if="!showResults && !noResults"></ResultSkeleton>
      <ResultSkeleton v-if="!showResults && !noResults"></ResultSkeleton>
      <ResultSkeleton v-if="!showResults && !noResults"></ResultSkeleton>

      <Message v-if="showResults && !loggedIn" severity="warn">Ricorda: dovrai prima accedere alla piattaforma per prenotare un servizio</Message>

      <div v-for="(result, index) in resultSearch.result" :key="index">
        <Result :raw-item="result"
                :search="this.search"
                :requested-date="search.dateTime"
                :company-name="result.companyName" 
                :start-address="this.search.fromLocation"
                :end-address="this.search.toLocation"
                :duration="formattedTime(result.estimatedTime.companyTotalTime)"
                :distance="result.totalDistance"
                :service-type="this.search.serviceType"
                :waiting-time="this.search.waitingTime"
                :price="result.totalPrice"
                :image="result.companyLogo">
        </Result>
      </div>

      <div class="p-d-flex p-jc-center" v-if="noResults">
        <div class="noresult-container">
          <img src="../assets/Images/SadNoBKG_Gray_512.png" alt="Sad smile" class="noresult-image" />
          <br>
          <Text class="noresult-text">Ci dispiace...<br>Nessun risultato trovato.<br>Prova ad aumentare il raggio di ricerca</Text>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import API from '@/api/API';
  import Result from '../components/Result.vue';
  import ResultSkeleton from '../components/ResultSkeleton.vue'
  import SearchBox from '../components/SearchBox.vue'
  import Map from '../components/Map.vue'
  import Helper from '@/helper/Helpers';
  import { store } from '../store';
  import { useReCaptcha } from "vue-recaptcha-v3";

  export default {
    name: 'ResultsView',
    components: {
      Result,
      ResultSkeleton,
      SearchBox,
      Map
    },
    data () {
        return {
            search: {},
            resultSearch: [],
            proxyResult: [],
            showResults: false,
            noResults: false,
            showSearchBox: false,

            //Mobile checking
            isOnMobile : window.innerWidth <= 700,
        }
    },
    mounted() {
      try {
        this.search = {
          fromLocation: JSON.parse(this.$route.query.fromLocation),
          toLocation: JSON.parse(this.$route.query.toLocation),
          vehicleType: parseInt(this.$route.query.vehicleType),
          dateTime: this.$route.query.dateTime,
          maxRadius: parseInt(this.$route.query.maxRadius),
          serviceType: parseInt(this.$route.query.serviceType),
          waitingTime: parseInt(this.$route.query.waitingTime),
          startPOI: this.$route.query.startPOI === 'true',
          arrivePOI: this.$route.query.arrivePOI === 'true',
        }

        if (this.search.fromLocation && this.search.toLocation && this.search.vehicleType && this.search.dateTime && this.search.maxRadius && this.search.serviceType) {
          setTimeout(() => {
            this.recaptcha();
          }, 150);
        }
      } catch (error) {
        this.$router.push('/');
      }
      console.log(this.search);
      this.showSearchBox = true;
    },
    computed: {
      loggedIn() {
        return store.getters.IsLoggedIn;
      },
    },

    methods: {
      async recaptcha() {
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('search')

        this.getResults(token);
      },
      formattedTime(time) {
        return Helper.TimeHelper.getFormattedTimeFromMinutes(time);
      },
      getResults(recaptchaResponse) {
        if (recaptchaResponse != null && recaptchaResponse != "") {
          this.search.recaptcha = recaptchaResponse;
          API.SearchAPI.Search(this.search).then(response => {
            this.proxyResult = Helper.ResultHelper.orederBy(response.data, 'totalPrice', 'asc');
            this.resultSearch = response.data;
            this.showResults = true;
          }).catch(error => {
            this.showResults = false;
            this.noResults = true;
          });
        } 
      },
    }
  }
  </script>
  
  <style scoped>
  .Results{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: -2;
    text-align: center;
    min-height: 100vh;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  @media (max-width: 700px){
    .Results{
      margin: 0px;
      margin-bottom: 5px;
      align-items: normal;
    }
  }

  @media (max-width: 700px){
    .Results{
      margin: 0px;
      margin-bottom: 5px;
      align-items: normal;
    }
  }

.noresult-container {
  text-align: center;
}

.noresult-image {
  display: block;
  margin: 0 auto;
  width: 150px;
  height: auto;
  margin-top: 70px;
}

.noresult-text {
  margin-top: 30px; /* Adjust spacing between image and text */
  font-size: 1.5rem; /* Adjust font size as needed */
  font-weight: bold; /* Optional: Adjust font weight */
  color: #989898;
}
  
  </style>
  