<template>
  <div class="surface-section px-4 py-8 md:px-6 lg:px-8">
    <div class="text-700 text-center">
        <div class="text-900 font-bold text-5xl mb-3">Contattaci per ogni tua necessità</div>
        <div class="text-700 text-2xl mb-5">Per assistenza, richieste commerciali o per una semplice informazione, sempre a tua disposizione.</div>
    </div>
  </div>
  <div class="secondRowContainer">
    <div class="emailContainer">
      <div class="iconContainer">
        <i class="pi pi-at iconImage"></i>
      </div>
      <div class="textContainer">
        <p class="emailLabel">
          info@ambubook.com
        </p>
        <p>
          Scrivici a questa mail per qualsiasi informazione o problematica. Un nostro operatore prenderà in carico la richiesta in un lampo!
        </p>
      </div>
    </div>
    <div class="chatContainer">
      <div class="iconContainer">
        <i class="pi pi-comment iconImage"></i>
      </div>
      <div class="textContainer">
        <p>
          Scrivici nella chat che trovi in basso a destra in ogni parte del sito! Un nostro operatore ti risponderà in tempo reale cercando di soddisfare qualsiasi tua esigenza e di toglierti qualsiasi dubbio!
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactsView',
  components: {
  }
}
</script>

<style scoped>
.iconImage{
  font-size: 9rem; 
  color: white;
}
.emailLabel{
  font-weight: bolder; 
  margin-top: 0px; 
  font-size: 2rem;
}
.iconContainer{
  text-align: center; 
  padding: 100px;
  padding-bottom: 75px;
  margin-top: 25px;
}
.textContainer{
  color: white;
  font-size: 1.5rem;
  margin-right: 100px;
  margin-left: 100px;
  font-family: revert;
}
.secondRowContainer{
  display: flex;
  background-color: white;
}
.emailContainer{
  height: 60vh;
  background-color: #EF8354;
  width: 60%;
  border-radius: 0px 100px 100px 0px;
  margin-bottom: 200px;
  margin-right: 25px;
  box-shadow: 0px 0px 50px 7px rgba(0, 0, 0, 0.1);
}
.chatContainer{
  height: 60vh;
  background-color: #4F5D75;
  width: 60%;
  border-radius: 100px 0px 0px 100px;
  margin-left: 25px;
  box-shadow: 0px 0px 50px 7px rgba(0, 0, 0, 0.1);
}
.whiteSpaceContainer{
  height: 60vh;
}

.Contacts{
    position: relative;
    z-index: -2;
    margin:100px;
    text-align: center;
}

@keyframes sway {
  0% {
    transform: rotate(8deg);
  }
  50% {
    transform: rotate(-8deg);
  }
  100% {
    transform: rotate(8deg);
  }
}

.object {
  position: absolute;
  animation: sway 2.4s infinite;
  animation-timing-function: ease-in-out;
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  transform-origin: top;
  left: 0;
  right: 0;
  height: 5%;
  z-index: 999;
  text-transform: uppercase;
}

.object-shape {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  background-color: #2187C7;
  margin: 0 auto;
  position: relative;
  color: #fff;
  text-align: center;
  padding-top: 25px;
  font-weight: 800;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.object-shape span {
  font-size: 22px;
  color:white;
}

.object-rope {
  height: 100%;
  width: 5px;
  background-color: #2187C7;
  content: "";
  display: block;
  margin-left: 50%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 500px;
  padding: 20vh 20px 20px 20px
}

.logo {
  max-width: 300px;
}

p {
  font-family: sans-serif;
  text-align: center;
}

.message {
  margin-top: 40px;
}
@media (max-width: 500px){
  .chatContainer{
    align-content: center;
  }
  .emailContainer{
    align-content: center;
  }
  .emailLabel{
    font-size: unset;
  }
  .textContainer{
    color: white;
    font-size: 1.3rem;
    margin-right: 50px;
    margin-left: 50px;
    font-family: revert;
  }
  .iconImage{
    font-size: 6rem; 
    color: white;
  }
  .iconContainer{
    padding:50px;
    margin-top: 0px;
  }
  .secondRowContainer{
    display: inline;
  }
  .emailContainer{
    height: 60vh;
    background-color: #EF8354;
    width: -webkit-fill-available;
    border-radius: 50px;
    margin-bottom: 50px;
    margin: 10px;
    box-shadow: 0px 0px 50px 7px rgba(0, 0, 0, 0.1);
  }
  .chatContainer{
    height: 60vh;
    background-color: #4F5D75;
    width: -webkit-fill-available;
    border-radius: 50px;
    margin-bottom: 50px;
    margin: 10px;
    box-shadow: 0px 0px 50px 7px rgba(0, 0, 0, 0.1);
  }
}
</style>

<!--  -->
