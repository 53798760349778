<template>
  <div class="ResultInfo">
    <div class="ResultInfoSubContainer">
      <ResultInfos v-if="canLoadInfosComponent" :raw-item="this.resultInfoRawObject" :search="this.searchObject"></ResultInfos>
      <PatientForm @booking="recaptcha" :is-examination="isExamination"/> 
    </div>
  </div>
  <Toast ref="toast" position="bottom-right" style="right:0px; width: -webkit-fill-available; margin: 10px; max-width: 500px;"/>
  <Dialog v-model:visible="serviceBookedDialog" modal header="Prenotazione inviata!" :style="{ width: '25rem' }" :closable="false" :draggable="false">
    <div style="text-align: center;">
      <img src="../assets/Images/SmileNoBKG_Orange.png" style="height: 100px;">
      <p style="text-align: left;">
        Grazie per aver prenotato con AmbuBook! <br>
        La tua prenotazione è stata inviata con successo. <br>
        Riceverai una mail di conferma con i dettagli del servizio non appena verrà accettata.
      </p>
      <!-- Questo bottone dovrà poi fare il redirect alle prenotazioni dell'utente! -->
      <div style="text-align: center; margin-top: 40px;">
        <Button label="Vedi prenotazioni" @click="goToMyBookings()" rounded />
      </div>
    </div>
  </Dialog>
</template>

<script>
import PatientForm from '../components/PatientForm.vue'
import ResultInfos from '../components/ResultInfos.vue'
import API from '@/api/API';
import Cookies from 'js-cookie';
import Helper from '@/helper/Helpers';
import { store } from '@/store';

export default {
  name: 'ResultInfoView',
  data(){
    return{
      resultInfoRawObject: null,
      searchObject: null,
      canLoadInfosComponent: false, //Questa variabile serve ad aspettare che le props vengano impacchettate per essere mandate al componente dei dettagli
      serviceBookedDialog: false,

      isExamination: false,

      // Recaptcha
      recaptchaToken: ''
    }
  },
  components: {
    PatientForm,
    ResultInfos
  },
  mounted(){
    this.canLoadInfosComponent = true;
    let tmpStore = store.getters.GetResultInfo;
    this.isExamination = tmpStore.search.serviceType == 2;
  },
  methods: {
    goToMyBookings(){
      const newUrl = window.location.origin + '/mybookings';
      window.location.assign(newUrl);
    },
    async recaptcha(transportedInfo) {
      await this.$recaptchaLoaded()
      this.recaptchaToken = await this.$recaptcha('bookService')

      if (this.recaptchaToken === undefined)
      {
        this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'Errore durante la verifica del captcha.',life: 2500});
      } else {
        console.log(this.recaptchaToken);
        this.bookService(transportedInfo);
      }
    },
    bookService(transportedInfo){
      console
      const jwt = Cookies.get('token');
      const userId = Cookies.get('id');
      if (jwt === undefined)
      {
        this.$router.push({name: 'Login'});
      }
      else
      {
        let tmpStore = store.getters.GetResultInfo;

        let rawItem = tmpStore.rawItem;
        let search = tmpStore.search;

        rawItem.transportedInfo = {
          "birthDate": transportedInfo.BirthDate !== "" ? Helper.TimeHelper.getFormattedDateISO(new Date(transportedInfo.BirthDate)) : "",
          "firstName": transportedInfo.FirstName,
          "lastName": transportedInfo.LastName,
          "floor": transportedInfo.VisitFloor,
          "canWalk": transportedInfo.CantWalk,
          "canStaySitted": transportedInfo.CanSit,
          "companionPresent": transportedInfo.PatientCompanion,
          "elevatorPresent": transportedInfo.Lifter,
          "referencePhoneNum": transportedInfo.PhoneNumberReference,
          "sex": transportedInfo.Sex,
          "needOxygen": transportedInfo.NeedOxygen,
          "needStretcher": transportedInfo.NeedStretcher,
          "notes": transportedInfo.Notes,
          "weight": transportedInfo.Weight,
          "companionPresent": transportedInfo.IsCompanionPresent,
          "typeOfExamination": transportedInfo.TypeOfExamination,
        };  
        API.BookingAPI.BookService(jwt, rawItem, userId, search, this.recaptchaToken)
        .then(response => {
          this.serviceBookedDialog = true;
        }).catch(error => {
          this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'Errore durante la prenotazione del servizio.',life: 2500});
        });
      }
    }
  }
}
</script>

<style scoped>
.ResultInfo{
  position: relative;
  z-index: -2;
  margin:0px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ResultInfoSubContainer{
  display: flex;
  position: relative;
}
@media (max-width: 500px), (max-height: 900px) {
  .ResultInfo, .ResultInfoSubContainer{
    align-items: center;
    height: auto;
    display: block;
  }
}

</style>
