<template>
  <div class="home">
    <div class="SearchContainer" id="no-consent-given">
      <div class="SearchContainerTitle">
        <h1 class="text-6xl font-bold">Inizia la tua ricerca!</h1>
        <h5>In pochi e semplici passi troverai il servizio di cui hai bisogno.</h5>
      </div>
      <SearchBox :propShowClosingButton="false" :mobileMode="false" class="SearchBox"></SearchBox>
    </div>
    <h1 class="InfosContainerTitle">Hai bisogno di alcune informazioni?</h1>
    <div class="InfosContainer">
      <div class="InfosForCustomersCard">
        <div>
          <h1>Sei un privato e hai bisogno di prenotare un'ambulanza? Scopri come fare!</h1>
          <Button label="Scopri" @click="this.$router.push({ name: 'privates' });" rounded/>
        </div>
      </div>
      <div class="InfosForCompanyCard">
        <div>
          <h1>Sei un'organizzazione e vuoi migliorare il tuo servizio con AmbuBook? Scopri come aderire!</h1>
          <Button label="Scopri" @click="this.$router.push({ name: 'companies' });" rounded/>
        </div>
      </div>
      <div class="InfosForCompanyCard">
        <div>
          <h1>Non sai come funziona AmbuBook? Scopri qui sotto tutte le funzionalità!</h1>
          <Button label="Scopri" @click="this.$router.push({ name: 'howitworks' });" rounded />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchBox from '../components/SearchBox.vue'
export default {
  name: 'HomeView',
  components: {
    SearchBox,
  },
  data () {
      return {
        isTest: !window.location.href.includes('test.ambubook.com')
      }
  },
}
</script>

<style scoped>
.home{
  position: relative;
  z-index: -2;
  text-align: center;
}
.SearchContainer{
  text-align: -webkit-center;
}
.SearchContainerTitle{
  background-color: #ef8354;
  color: white;
  padding: 50px;
  line-height: 20px;
  font-size: xx-large;
  height: 25rem;
  /* margin-bottom: 400px; */
  background: rgb(239,131,84);
  background: linear-gradient(90deg, rgba(239,131,84,1) 50%, rgba(239,131,84,0.8841911764705882) 100%);
  box-shadow: 0px 0px 50px 7px rgba(0,0,0,0.1);
}
.SearchBox{
  position: absolute;
  top: 10rem;
  right: 0;
  left: 0;
}

.InfosContainer{
  display: flex;
  height: 50vh;
  margin: 150px;
  margin-top: 0px;
}
.InfosForCustomersCard, .InfosForCompanyCard{
  width: -webkit-fill-available;
  padding: 50px;
  display: flex;
  align-items: center;
  margin: 30px;
  box-shadow: 0px 0px 50px 7px rgba(0,0,0,0.1);
  border-radius: 50px;
  text-align: right;
  background: rgb(255,255,255);
  background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(246,246,246,1) 100%);
}
.InfosContainerTitle{
  color: black;
  height: 20vh;
  text-align: center;
  width: max-content;
  margin: 200px auto;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  font-size: -webkit-xxx-large;
}
.InfosContainer Button{
  border: none;
}
.InfosContainer Button:hover{
  background-color: #c96c44;
  color: #ffffff;
}
@media (max-width: 1280px) {
  .InfosContainer{
    display: inline;
  }
}
@media (max-width: 925px) {
  .home{
    /*margin: 50px;
    margin-top: 20px;*/
    margin: auto;
    display: block;
    height: auto;
    align-items: center;
  }

  .SearchContainerTitle{
    padding: 0;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 0 0 900px 0;
    margin: 0 auto;
    height: auto;
    line-height: normal;
    margin-bottom: 20px;
    position: relative;
    background: rgb(239,131,84);
    background: linear-gradient(90deg, rgba(239,131,84,1) 50%, rgba(239,131,84,0.8841911764705882) 100%);
  }
  .SearchContainerTitle h1{
    color: white;
    font-size: 3rem;
    padding-top: 20px;
    margin: 0 auto;
  }
  .SearchContainerTitle h5{
    color: white;
    padding-right: 100px;
    padding-bottom: 20px;
    font-size: medium;
    font-weight: lighter;
  }
  .SearchBox{
    position: relative;
    top: 15%;
    right: 0;
    left: 0;
  }
  .InfosContainerTitle{
    color: black;
    height: 20vh;
    text-align: center;
    width: max-content;
    margin: 200px auto;
    margin-bottom: 0px;
    display: table-row-group;
    align-items: center;
  }
  .InfosContainer{
    display: inline;
    height: 50vh;
    margin: 150px;
    margin-top: 0px;
  }
  .InfosForCustomersCard, .InfosForCompanyCard{
    width: -webkit-fill-available;
    padding: 50px;
    display: flex;
    align-items: center;
    margin: 0px;
    margin-top: 30px;
    margin-bottom: 30px;
    box-shadow: 0px 0px 50px 7px rgba(0,0,0,0.1);
    border-radius: 50px;
    text-align: right;
    background: rgb(255,255,255);
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(246,246,246,1) 100%);
  }

}

</style>
