<template>
    <div class="DashboardStatistics">
        <h1 class="blink">Le tue statistiche</h1>
        <div v-if="stats">
            <div class="grid">
                <div class="sm:col-12 md:col-12 lg:col-1">
                        <Dropdown v-on:change="reloadStats" v-model="periodChoosen" inputId="periodStats" :options="periodOptions" 
                        optionLabel="period" optionValue="code" class="min-w-full"/>
                </div>
            </div>
            <div class="grid">
                <div class="col-12 md:col-6 lg:col-3">
                    <div class="surface-card shadow-2 p-3 border-round">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <span class="block text-500 font-medium mb-3">In attesa di risposta</span>
                                <div :class="{'text-900 font-medium text-xl': stats.waitingBookings == 0, 'text-red-700 font-bold text-xl': stats.waitingBookings > 0}">{{ stats.waitingBookings }}</div>
                            </div>
                            <div class="flex align-items-center justify-content-center bg-yellow-100 border-round" style="width:2.5rem;height:2.5rem">
                                <i class="pi pi-exclamation-triangle text-yellow-500 text-xl"></i>
                            </div>
                        </div>
                        <br>
                    </div>
                </div>
                <div class="col-12 md:col-6 lg:col-3">
                    <div class="surface-card shadow-2 p-3 border-round">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <span class="block text-500 font-medium mb-3">In attesa di completamento</span>
                                <div :class="{'text-900 font-medium text-xl': stats.waitingCompletionBookings == 0, 'text-orange-400 font-bold text-xl': stats.waitingCompletionBookings > 0}">{{ stats.waitingCompletionBookings }}</div>
                            </div>
                            <div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
                                <i class="pi pi-clock text-orange-500 text-xl"></i>
                            </div>
                        </div>
                        <br>
                    </div>
                </div>
                <div class="col-12 md:col-6 lg:col-3">
                    <div class="surface-card shadow-2 p-3 border-round">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <span class="block text-500 font-medium mb-3">{{ completedBookingsText }}</span>
                                <div class="text-900 font-medium text-xl">{{ stats.completedBookings }}</div>
                            </div>
                            <div class="flex align-items-center justify-content-center bg-green-100 border-round" style="width:2.5rem;height:2.5rem">
                                <i class="pi pi-check text-green-500 text-xl"></i>
                            </div>
                        </div>
                        <span :class="{'text-green-500 font-medium': stats.completedBookingsDifference >= 0, 'text-red-500 font-medium': stats.completedBookingsDifference < 0}">{{ stats.completedBookingsDifference }} in {{ stats.completedBookingsDifference < 0 ? 'meno ' : 'più ' }}</span>
                        <span class="text-500">{{ previeousCompletedBookingsText }}</span>
                    </div>
                </div>
                <div class="col-12 md:col-6 lg:col-3">
                    <div class="surface-card shadow-2 p-3 border-round">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <span class="block text-500 font-medium mb-3">{{ rejectedBookingsText }}</span>
                                <div class="text-900 font-medium text-xl">{{ stats.rejectedBookings }}</div>
                            </div>
                            <div class="flex align-items-center justify-content-center bg-red-100 border-round" style="width:2.5rem;height:2.5rem">
                                <i class="pi pi-times text-red-500 text-xl"></i>
                            </div>
                        </div>
                        <span :class="{'text-green-500 font-medium': stats.rejectedBookingsDifference < 0, 'text-red-500 font-medium': stats.rejectedBookingsDifference >= 0}">{{ stats.rejectedBookingsDifference }} in {{ stats.rejectedBookingsDifference < 0 ? 'meno ' : 'più ' }}</span>
                        <span class="text-500">{{ previousRejectedBookingsText }}</span>
                    </div>
                </div>
                <div class="col-6 max-h-2rem h-3rem">
                    <div class="surface-card shadow-2 p-3 border-round">
                        <Chart type="bar" :data="chartData" :options="chartOptions" class="h-30rem" />
                    </div>
                </div>
                <div class="col-6 max-h-2rem h-3rem">
                    <div class="surface-card shadow-2 p-3 border-round">
                        <p class="text-lg text-center text-700" style="margin-top: 4px;">Ultime prenotazioni in sintesi</p>
                        <BookingsDataTable :stats-mode="true"></BookingsDataTable>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Toast ref="toast" position="bottom-right" style="right:0px; width: -webkit-fill-available; margin: 10px; max-width: 500px;"/>
</template>

<script>
import Cookies from 'js-cookie';
import API from '@/api/API';
import Helper from '@/helper/Helpers';
import BookingsDataTable from '@/components/BookingsDataTable.vue';

export default {
    name: 'DashboardStatisticsView',
    data(){
        return{
            stats: null,
            chartData: null,
            chartOptions: null,
            periodChoosen: null,

            completedBookingsText: '',
            previeousCompletedBookingsText: '',
            rejectedBookingsText: '',
            previousRejectedBookingsText: '',

            periodOptions: [
                { period: 'Settimana', code: 2 },
                { period: 'Mese', code: 3 },
                { period: 'Anno', code: 4 },
            ],
        }
    },
    components: {
        BookingsDataTable
    },
    mounted(){
        this.periodChoosen = 3;
        this.reloadStats();

        this.setChartData();
        this.chartOptions = this.setChartOptions();
    },
    methods: {
        getMonthName(date){
            console.log(date);
            return Helper.TimeHelper.translateEnglishToItalianMonth(date);
        },
        reloadStats(){
            const jwt = Cookies.get('token');
            const companyId = Cookies.get('companyId');
            
            API.StatsAPI.GetLastBookings(jwt, companyId, this.periodChoosen).then(response => {
                this.stats = response.data;
                this.formatTextByPerdiod();
            }).catch(error => {
                // Check if the error is a 429 (Too Many Requests) and show a message
                if(error.response.status == 429) {
                    this.$refs.toast.add({severity:'error', summary: 'Errore', detail: "Troppe richieste, riprova più tardi", life: 1500});
                }
                console.log(error);
            });

            this.chartData = this.setChartData();
        },
        formatTextByPerdiod() {
            if (this.periodChoosen == 2) {
                this.completedBookingsText = "Prenotazioni completate nella settimana: " + this.stats.periodTimes.dateString;
                this.previeousCompletedBookingsText = "in confronto alla settimana: " + this.stats.periodTimes.previousDateString;
                this.rejectedBookingsText = "Prenotazioni rifiutate nella settimana: " + this.stats.periodTimes.dateString;
                this.previousRejectedBookingsText = "in confronto alla settimana: " + this.stats.periodTimes.previousDateString;
            } else if (this.periodChoosen == 3) {
                this.completedBookingsText = "Prenotazioni completate a " + this.getMonthName(this.stats.periodTimes.dateString);
                this.previeousCompletedBookingsText = "in confronto a " + this.getMonthName(this.stats.periodTimes.previousDateString);
                this.rejectedBookingsText = "Prenotazioni rifiutate a " + this.getMonthName(this.stats.periodTimes.dateString);
                this.previousRejectedBookingsText = "in confronto a " + this.getMonthName(this.stats.periodTimes.previousDateString);
            } else if (this.periodChoosen == 4) {
                this.completedBookingsText = "Prenotazioni completate nell'anno: " + this.stats.periodTimes.dateString;
                this.previeousCompletedBookingsText = "in confronto all'anno: " + this.stats.periodTimes.previousDateString;
                this.rejectedBookingsText = "Prenotazioni rifiutate nell'anno: " + this.stats.periodTimes.dateString;
                this.previousRejectedBookingsText = "in confronto all'anno: " + this.stats.periodTimes.previousDateString;
            }
        },
        setChartData() {
            const documentStyle = getComputedStyle(document.documentElement);
            const jwt = Cookies.get('token');
            const companyId = Cookies.get('companyId');

            API.StatsAPI.GetEarningsVsBookings(jwt, companyId, this.periodChoosen).then(response => {
                this.chartData = response.data;
            }).catch(error => {
                console.log(error);
            });
        },
        setChartOptions() {
            const documentStyle = getComputedStyle(document.documentElement);
            const textColor = documentStyle.getPropertyValue('--text-color');
            const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
            const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

            return {
                maintainAspectRatio: false,
                aspectRatio: 0.6,
                responsive: true, 
                plugins: {
                    legend: {
                        labels: {
                            color: textColor
                        }
                    },
                },
                scales: {
                    x: {
                        ticks: {
                            color: textColorSecondary
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    },
                    
                    'y-axis-1': {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        ticks: {
                            color: textColorSecondary,
                            callback: function(label, index, labels) {
                                return label + '€';
                            }
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    },
                    'y-axis-2': {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        ticks: {
                            color: textColorSecondary
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    }
                }
            };
        }
    }
}
</script>

<style scoped>
.DashboardStatistics{
  position: relative;
  z-index: -2;
  margin:50px;
  margin-top: 100px;
  text-align: center;
  /* display: flex; */
  align-items: center;
  height: 100vh;
  min-height: 115vh;
}

.signUpContainer .p-float-label {
  margin: 15px;
  width: max-content;
}

:deep(.border-round){
    border-radius: 20px !important;
}
:deep(.p-3){
    padding: 1.5rem !important;
}

@media (max-width: 500px), (max-height: 900px) {
  .DashboardStatistics{
    align-items: center;
    height: auto;
    display: block;
  }

  .signUpContainer .p-float-label {
    margin: 20px;
    width: max-content;
  }
}
</style>
