<template>
  <div class="ResetPasswordContainer">
    <div class="containerHeader"> 
      <i class="pi pi-user-edit" style="font-size: 2.5rem; color: #EF8354;"></i>
      <h1>Cambia Password</h1>
      <p>Inserisci la tua vecchia password e creane una nuova.</p>
    </div>
    <div class="containerContent">
      <span v-for="(field, index) in CustomerFormFields" :key="index" class="p-float-label p-input-icon-right">
        <IconField iconPosition="right">
          <InputIcon :class="field.iconClass"></InputIcon>
          <InputText v-if="field.type !== 'password'" v-model="$data[field.id]" :loading="loading" />
          <Password v-else v-model="$data[field.id]" toggleMask :feedback="false" :loading="loading"/>
        </IconField>
        <label :for="field.id">{{ field.label }}</label>
      </span>
    </div>
    <div class="containerFooter"> 
      <span>
        <Button label="Conferma" :loading="loading" @click="handleResetPassword" rounded />
      </span>
    </div>
    <div class="containerReturnBackButton" @click="ViewPreviousWindow"> 
      <span> 
        <i class="pi pi-arrow-left"></i>
      </span>
      <span> 
        <p>Indietro</p>
      </span>
    </div>
    <Toast ref="toast" position="bottom-right" style="right:0px; width: -webkit-fill-available; margin: 10px; max-width: 500px;"/>
  </div>
</template>

<script>
import API from '@/api/API';
import { store } from '../store';
import Cookies from 'js-cookie';
// @ts-ignore
import { stringifyQuery } from 'vue-router';

export default {
  name: 'ResetPasswordForm',
  data(){
      return{
        //DEBUG
        debug: false,

        //Generic Variables
        GenericEmail:'',
        CurrentPassword:'',
        NewPassword: '',
        loading: false,

        //Input Dynamic Creation
        CustomerFormFields: [
        { id: 'CurrentPassword', label: 'Password attuale', type: 'password' },
        { id: 'NewPassword', label: 'Nuova password', type: 'password' },
        ],
      }
  },
  methods: {
    async handleResetPassword() {
      try 
      {
        //Inizio a mostrare il caricamento
        this.loading = true;
        this.GenericEmail = store.getters.GetLoggedInUserEmail;
        if(this.debug)
        {
          console.log('Email: ' + this.GenericEmail);
          console.log('CurrentPassword: ' + this.CurrentPassword);
          console.log('NewPassword: ' + this.NewPassword);
        }

        //Verifica dei dati inseriti dall'utente
        if(this.GenericEmail == '' || this.CurrentPassword == '' || this.NewPassword == '')
        {
          // @ts-ignore
          this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'Mail o Password non inserite correttamente.',life: 2500});
        }
        else
        {
          const jwt = Cookies.get('token');
          if (jwt === undefined) 
          {
            this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'Non sei loggato, effettua il login per cambiare la password.',life: 2500});
          }
          else
          {
            //Chiamata API per cambiare la password
            const response = await API.UserAPI.ChangePassword(jwt, this.GenericEmail, this.NewPassword, this.CurrentPassword);
            if (response.status === 200) 
            {
              this.$refs.toast.add({severity:'success', summary: 'Operazione riuscita!', detail: 'La tua password è stata cambiata correttamente.',life: 2500});
              
              //Waiting per mostrare il toast di conferma
              await new Promise(resolve => setTimeout(resolve, 3000));

              //Ritorno alla home
              this.$router.replace('/');
            }
          }
        }
      } 
      catch (error) 
      {
        this.ManageError(error);
      }
      finally
      {
        this.loading=false;
      }
    },
    // @ts-ignore
    ManageError(error){
      if(this.debug){
        console.log('DEBUG - Error response data: ' + error.response.data);
        console.log('DEBUG - Error response key: ' + error.response.data.key);
        console.log('DEBUG - Error response description: ' + error.response.data.description);
      }
      switch(error.response.data.key){
        case 33:
            // @ts-ignore
            this.$refs.toast.add({severity:'error', summary: 'Password non corretta', detail: 'La password attuale che hai inserito non è corretta.',life: 5000});
          break;
        default:
            // @ts-ignore
            this.$refs.toast.add({severity:'error', summary: 'Qualcosa non sta funzionando...', detail: 'Ci scusiamo per il disagio, contatta il supporto per segnalare.',life: 5000});
          break;
      }
      
    },

    ViewPreviousWindow(){
      if(this.debug){
        console.log('DEBUG - IsResetPasswordFormVisible: ' + store.getters.IsResetPasswordVisible);
      }
      this.$router.go(-1);
    }
  },
}
</script>


<style scoped>
.ResetPasswordContainer{
    padding: 70px;
    border-radius: 80px;
    box-shadow: 0px 0px 50px 7px rgba(0,0,0,0.1);
    max-width: 700px;
    position: relative;
    margin: 200px auto;
    margin-top: 50px;
    position: relative;
    height: fit-content;
    text-align: center;
    background-color: white;
}
.ResetPasswordContainer .p-float-label{
    margin: 15px;
    width: max-content;
}
.containerHeader{
  margin-bottom: 25px;
}
.containerContent{
  position: relative;
  align-items: center;
  display: inline-grid;
  margin: 15px;
}
.containerFooter{
  margin-top: 25px;
}
.checkBoxContainer{
  margin-top: 20px;
  font-size: medium;
  color: gray;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  position: relative;
}
.checkBoxContainer p{
  margin: 10px;
}
.p-button{
  background-color: #EF8354;
  border-color: #EF8354;
  width: 150px;
}
:deep(.p-checkbox-box:hover){
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
:deep(.p-checkbox .p-checkbox-box.p-highlight){
  background:#EF8354;
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
:deep(.p-checkbox-box:focus){
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
.p-button:hover{
  border-color: #bc6844;
  background-color: #bc6844;
}

:deep(.p-inputtext){
  width: 300px;
}
:deep(.p-inputtext:hover){
    border-color: #EF8354;
}
:deep(.p-inputtext:focus){
    border-color: #EF8354;
    box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
:deep(.p-button:focus){
    border-color: #EF8354;
    box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
.containerReturnBackButton span i{
  font-size: 0.7rem; 
  color: grey; 
  margin-top: 20px;
  margin-right: 10px;
  transition: 0.5s;
}
.containerReturnBackButton span i:hover{
  cursor: pointer;
}
.containerReturnBackButton{
  position: relative;
  display: flex;
  display: inline-flex;
  color: grey;
  transition: 0.5s;
  font-size: 1rem;
  align-items: baseline;
}
.containerReturnBackButton:hover{
  cursor: pointer;
}


@media (max-width: 1280px) and (min-width: 501px) {
    .ResetPasswordContainer{
      zoom:100%;
    }
    .containerContent{
      margin: 0px;
    }
    .ResetPasswordContainer .p-float-label{
      margin: 20px;
      width: max-content;
    }
  }
  @media (max-width: 500px) {
    .ResetPasswordContainer{
      padding: 10px;
      border-radius: 20px;
      margin: 10px;
    }
    .containerHeader{
      margin: 10px;
      margin-top: 30px;
    }
    .containerContent{
      margin: 0px;
    }
    .containerFooter{
      margin: 10px;
    }
    :deep(.p-inputtext){
      width: 300px;
      height: 60px;
    }
    .containerReturnBackButton{
      margin-bottom: 10px;
    }
  }

</style>