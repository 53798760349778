<template>
    <div id="map">
    </div>
</template>

<script>
    import "leaflet/dist/leaflet.css";
    import L from 'leaflet';
    import 'leaflet-routing-machine';

    export default {
        name: "Map",
        components: {
        },
        props: {
            from: Object,
            to: Object,
        },
        mounted() {
            var map = L.map('map');
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '© OpenStreetMap contributors'
            }).addTo(map);

            L.Routing.control({
                waypoints: [
                    L.latLng(this.from.lat, this.from.lon),
                    L.latLng(this.to.lat, this.to.lon)
                ],
                fitSelectedRoutes: true,
                draggableWaypoints: false,
                routeWhileDragging: false,
                createMarker: function() { return null; },
                lineOptions: {
                    addWaypoints: false,
                    styles: [{ color: '#242c81', weight: 2 }]
                }
            }).addTo(map);
        },
        data() {
            return {
                zoom: 2,
            };
        },
        methods: {

        },
    };
</script>
  
<style>

#map {
    height: 300px;
    width: -webkit-fill-available;
    border-radius: 40px;
}

#map > div.leaflet-control-container > div.leaflet-top.leaflet-right > div {
    display: none;
}

#map > div.leaflet-control-container > div.leaflet-top.leaflet-left {
    display: none;
}

.leaflet-pane.leaflet-marker-pane {
    display: none;
}

#map > div.leaflet-pane.leaflet-map-pane > div.leaflet-pane.leaflet-overlay-pane > svg > g > path:last-child {
    stroke-width: 4px;
}

@media (max-width: 500px) {
    #map {
        border-radius: 20px;
    }
}

</style>