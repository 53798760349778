import axios from 'axios';
import config from '@/config';
import SimulatePrice from '@/components/SimulatePrice.vue';

export default {

    async PostService(jwt, vehicleData) {
        const resp = await axios.post(`${config.backendIP}/api/v1/company/service`, vehicleData,
        {
            headers: {
                Authorization: `${jwt}`
            }
        });

        return resp;
    },

    async GetAllServices(jwt, companyId){
        const resp = await axios.get(`${config.backendIP}/api/v1/company/services/${companyId}`, {
            headers: {
                Authorization: `${jwt}`
            }
        });
        return resp;
    },

    async GetServiceByID(jwt, serviceId){
        try {
            const resp = await axios.get(`${config.backendIP}/api/v1/company/service/${serviceId}`, {
                headers: {
                    Authorization: `${jwt}`
                }
            });
            return resp;
        } catch (error) {
            return error.response;
        }
    },

    async ActiveOrDisableVehicle(jwt, active, serviceId) {
        try {
            const resp = await axios.put(`${config.backendIP}/api/v1/company/service/${serviceId}/active`, {
                isActive: active
            }, {
                headers: {
                    Authorization: `${jwt}`
                }
            });
            return resp;
        } catch (error) {
            return error.response;
        }
    },
    async SimulatePrice(jwt, serviceId, requestSim){
        try {
            const resp = await axios.post(`${config.backendIP}/api/v1/company/service/${serviceId}/simulate`, requestSim, {
                headers: {
                    Authorization: `${jwt}`
                }
            });
            return resp;
        } catch (error) {
            return error.response;
        }
    },
    async UpdateService(jwt, serviceId, vehicleData) {
        const resp = await axios.put(`${config.backendIP}/api/v1/company/service/${serviceId}`, vehicleData,
        {
            headers: {
                Authorization: `${jwt}`
            }
        });

        return resp;
    },

    async DeleteService(jwt, serviceId) {
        const resp = await axios.delete(`${config.backendIP}/api/v1/company/service/${serviceId}`,
        {
            headers: {
                Authorization: `${jwt}`
            }
        });

        return resp;
    },
};