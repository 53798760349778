<template>
  <div class="signUpContainer">
    <div class="containerHeader"> 
      <SelectButton v-model="SelectValue" :options="SelectOptions" aria-labelledby="basic" />
      <i class="pi pi-user-plus" style="font-size: 2.5rem; color: #EF8354;"></i>
      <h1>Benvenuto su AmbuBook.</h1>
      <p>Abbiamo bisogno di alcuni dati per conoscerti meglio</p>
    </div>
    <form class="containerContent" id="signupForm">
      
      <!--Container Content relativo ad un Cliente-->
      <div v-if="SelectValue == this.CustomerSelectValue" class="containerContent customerContent"> 
        <span v-for="(field, index) in CustomerFormFields" :key="index" class="p-float-label p-input-icon-right">
          <IconField iconPosition="right">
            <InputIcon :class="field.iconClass"></InputIcon>
            <InputText v-if="field.type !== 'password'" :id="field.id" v-model="$data[field.id]" :name="field.name"/>
            <Password v-else :id="field.id" v-model="$data[field.id]" inputId="field.id" toggleMask/>
          </IconField>
          <label :for="field.id">{{ field.label }}</label>
        </span>
          <span class="p-float-label p-input-icon-right">
            <Calendar v-model="CustomerBirthDate" inputId="customerBirthDate" dateFormat="dd/mm/yy" showIcon iconDisplay="input"/>
            <label for="customerBirthDate">Data di nascita</label>
          </span>
        <span class="p-float-label">
          <Dropdown v-model="CustomerSex" inputId="customerSex" :options="SexOptions" optionLabel="name" optionValue="code"/>
          <label for="customerSex">Sesso</label>
        </span>
      </div>
      
      <!--Container Content relativo ad un'associazione-->
      <div v-if="SelectValue == this.CompanySelectValue" class="containerContent companyContent"> 
        <div class="companySteps">
          <Steps v-model:activeStep="active" :model="companySteps" :readonly="true"/>
        </div>
        <span v-for="(field, index) in CompanyFormFields" :key="index" class="p-float-label p-input-icon-right" v-if="active === 0">
          <IconField iconPosition="right">
            <InputIcon :class="field.iconClass"></InputIcon>
            <InputText v-if="field.type !== 'password'" :id="field.id" v-model="$data[field.id]" />
            <Password v-else :id="field.id" v-model="$data[field.id]" inputId="field.id" toggleMask/>
          </IconField>
          <label :for="field.id">{{ field.label }}</label>
        </span>
        <vue-google-autocomplete
        ref="CompanyHeadQuarterAddress"
        id="map1"
        :fields="['address_components', 'formatted_address', 'geometry']"
        classname="form-control"
        placeholder="Indirizzo Sede"
        v-on:placechanged="getCompanyAddressData"
        country="it"
        class="googleAutocomplete"
        v-if="active === 0"/>

        <!--Container Content relativo ad un amministratore di associazione-->
        <span v-for="(field, index) in AdminFormFields" :key="index" class="p-float-label p-input-icon-right" v-if="active === 1">
          <IconField iconPosition="right">
            <InputIcon  :class="field.iconClass"></InputIcon>
            <InputText v-if="field.type !== 'password'" :id="field.id" v-model="$data[field.id]"/>
            <Password v-else :id="field.id" v-model="$data[field.id]" inputId="field.id" toggleMask/>
          </IconField>
            <label :for="field.id">{{ field.label }}</label>
        </span>
        <span class="p-float-label p-input-icon-right" v-if="active === 1">
            <Calendar v-model="AdminBirthDate" inputId="adminBirthDate" dateFormat="dd/mm/yy" showIcon iconDisplay="input"/>
            <label for="adminBirthDate">Data di nascita</label>
          </span>
        <span class="p-float-label" v-if="active === 1">
          <Dropdown v-model="AdminSex" inputId="adminSex" :options="SexOptions" optionLabel="name" optionValue="code"/>
          <label for="adminSex">Sesso</label>
        </span>

        <!--Pulsanti avanti indietro durante la creazione Associazione + Admin Associazione-->
        <div class="Arrows">
          <div class="containerReturnBackButton" @click="active=0" v-if="active==1"> 
            <span> 
              <i class="pi pi-arrow-left"></i>
            </span>
            <span> 
              <p>Indietro</p>
            </span>
          </div>

          <div class="containerGoAheadButton" @click="checkCompanyData" v-if="active==0"> 
            <span> 
              <p>Avanti</p>
            </span>
            <span> 
              <i class="pi pi-arrow-right"></i>
            </span>
          </div>
        </div>
      </div>
      <br>
      <div class="flex items-center max-w-22rem" name="generic">
        <Checkbox v-model="termsAndConditions" inputId="acceptTerms" :invalid="invalidTermsAndConditions" :binary="true" />
        <label  for="acceptTerms" style="color: gray;" class="ml-2 text-md"> Ho letto e accetto i 
          <a style="text-decoration: none; color: rgb(239, 131, 84);" href="https://www.iubenda.com/termini-e-condizioni/58947101" target="_blank" title="Termini e Condizioni">Termini e Condizioni</a>, 
          <a style="text-decoration: none; color: rgb(239, 131, 84);" href="https://www.iubenda.com/privacy-policy/58947101/cookie-policy" target="_blank" title="Cookie Policy ">Cookie Policy</a> e
          <a style="text-decoration: none; color: rgb(239, 131, 84);" href="https://www.iubenda.com/privacy-policy/58947101" target="_blank" title="Privacy Policy ">Privacy Policy</a> di AmbuBook
        </label>
      </div>
    </form>
    <!---->
    <div class="containerFooter">
      <span> 
        <Button name="sendSignup" label="Conferma" :loading="loading" rounded @click="recaptcha" v-if="SelectValue == this.CustomerSelectValue || (SelectValue == this.CompanySelectValue && active==1)"/>
      </span>
      <small class="disclaimer-google">Questo sito è protetto da reCAPTCHA. <br>
          Si applicano 
          <a style="text-decoration: none; color: rgb(239, 131, 84);" href="https://policies.google.com/privacy">Privacy Policy</a> e
          <a style="text-decoration: none; color: rgb(239, 131, 84);" href="https://policies.google.com/terms">Termini di servizio</a> di Google.
      </small>
    </div>
    <br>
    <br>
    <Toast ref="toast" position="bottom-right" style="right:0px; width: -webkit-fill-available; margin: 10px; max-width: 500px;"/>
  </div>
</template>

<script>
import API from '@/api/API';
import Helper from '@/helper/Helpers';
import { useReCaptcha } from "vue-recaptcha-v3";

export default {
  name: 'SignUpForm',
  components: {  },
  props: {
    t: {
      type: String,
      required: false,
      default: 'Utente'
    },
  },
  data(){
      return{
        //DEBUG
        debug: true,

        //Recaptcha
        recaptchaToken: '',

        //Sex selector component Constructor
        SexOptions: [
            { name: 'Uomo', code: 1 },
            { name: 'Donna', code: 2 },
        ],

        //Generic Variables
        GenericEmail:'',
        GenericPassword:'',
        GenericPasswordConfirm:'',
        CustomerSelectValue:'Utente',
        CompanySelectValue:'Azienda',
        loading:false,
        termsAndConditions: false,

        //Customer or ODV switch
        SelectValue: null,
        SelectOptions: [],

        //Customer
        CustomerName:'',
        CustomerSurname:'',
        CustomerPhone:'',
        CustomerSex: null,
        CustomerBirthDate: null,

        //Company Admin
        AdminName:'',
        AdminSurname:'',
        AdminPhone:'',
        AdminSex: null,
        AdminBirthDate: null,

        //Company
        CompanyName:'',
        CompanyEmail:'',
        CompanyHeadQuarterAddress:'',
        CompanyPhone:'',
        CompanyRegion:'',
        CompanyCity:'',
        CompanyZipCode:'',
        CompanyLongitudeCoord:'',
        CompanyLatitudeCoord:'',
        VATNumber:'',
        TaxCode:'',
        InvoiceCode:'',

        //Steps
        companySteps: [
            {
                label: 'Organizzazione'
            },
            {
                label: 'Amministratore'
            }
        ],
        currentStep: "Organizzazione",
        currentStepIndex: 0,
        active: 0,

        //Input Dynamic Creation
        CustomerFormFields: [
        { id: 'CustomerName',label: 'Nome', iconClass: 'pi pi-user', ref:'customerNameInput', name: "firstName" },
        { id: 'CustomerSurname', label: 'Cognome', iconClass: 'pi pi-user', ref:'customerSurnameInput', name: "lastName"},
        { id: 'GenericEmail', label: 'Email', iconClass: 'pi pi-envelope', ref:'genericEmailInput', name: "email"},
        { id: 'CustomerPhone', label: 'Cellulare', iconClass: 'pi pi-phone', ref:'customerPhoneInput', name: "telephone"},
        { id: 'GenericPassword', label: 'Password', type: 'password', ref:'genericPasswordInput', name: "password"},
        { id: 'GenericPasswordConfirm', label: 'Conferma Password', type: 'password', ref:'genericPasswordConfirmInput' }
        ],
        CompanyFormFields: [
        { id: 'CompanyName', label: 'Nome o ragione sociale', iconClass: 'pi pi-building', ref:'companyNameInput' },
        { id: 'CompanyPhone', label: 'Numero di telefono', iconClass: 'pi pi-phone', ref:'companyPhoneInput' },
        { id: 'CompanyEmail', label: 'Email', iconClass: 'pi pi-envelope', ref:'companyEmailInput' },
        { id: 'VATNumber', label: 'Partita IVA', iconClass: 'pi pi-hashtag', ref:'vatNumberInput' },
        { id: 'TaxCode', label: 'Codice Fiscale', iconClass: 'pi pi-id-card', ref:'taxCodeInput' },
        { id: 'InvoiceCode', label: 'Codice Fatturazione', iconClass: 'pi pi-file-export', ref:'invoiceCodeInput' }
        ],
        AdminFormFields: [
        { id: 'AdminName',label: 'Nome', iconClass: 'pi pi-user', ref:'adminNameInput', name: "firstName"},
        { id: 'AdminSurname', label: 'Cognome', iconClass: 'pi pi-user', ref:'adminSurnameInput', name: "lastName"},
        { id: 'GenericEmail', label: 'Email', iconClass: 'pi pi-envelope', ref:'genericEmailInput', name: "email"},
        { id: 'AdminPhone', label: 'Cellulare', iconClass: 'pi pi-phone', ref:'adminPhoneInput', name: "telephone"},
        { id: 'GenericPassword', label: 'Password', type: 'password', ref:'genericPasswordInput', name: "password"},
        { id: 'GenericPasswordConfirm', label: 'Conferma Password', type: 'password', ref:'genericPasswordConfirmInput' }
        ],
      }
  },

  created() {
    this.SelectValue = this.CustomerSelectValue;
    this.SelectOptions = [this.CustomerSelectValue, this.CompanySelectValue];
  },
  
  watch: {
    SelectValue(newSelectValue) {
      this.$emit('select-value-changed', newSelectValue);
    },
  },

  mounted() {
    if (this.t) {
      this.SelectValue = this.t;
      console.log(this.t);
    }

    this.$nextTick(() => {
      this.iubendaLoader();
    });
  },

  methods: {
    getCompanyAddressData: function (addressData, placeResultData, id) {
      console.log(addressData);
      this.CompanyHeadQuarterAddress = addressData;
    },
    async recaptcha() {
      await this.$recaptchaLoaded()
      this.recaptchaToken = await this.$recaptcha('signup')

      if(this.SelectValue == 'Utente'){
        this.createCustomerUser();
      }
      else{
        this.createCompanyAndAdminUser();
      }
    },

    //Creazione utente utilizzatore
    async createCustomerUser(){
      if(this.debug)
      {
        console.log('DEBUG - name: ' + this.CustomerName);
        console.log('DEBUG - surname: ' + this.CustomerSurname);
        console.log('DEBUG - email: ' + this.GenericEmail);
        console.log('DEBUG - password: ' + this.GenericPassword);
        console.log('DEBUG - confirm password: ' + this.GenericPasswordConfirm);
        console.log('DEBUG - telephone: ' + this.CustomerPhone);
        console.log('DEBUG - sex: ' + this.CustomerSex);
        console.log('DEBUG - birthdate: ' + this.CustomerBirthDate);
      }

      //Se ci sono errori li stampo in un toast
      if(!this.checkInputByUser(this.CustomerName, 
                                this.CustomerSurname, 
                                this.GenericEmail, 
                                this.CustomerPhone, 
                                this.GenericPassword,
                                this.GenericPasswordConfirm, 
                                this.CustomerBirthDate, 
                                this.CustomerSex))
      {
        if(this.debug){
          console.log('DEBUG - Input of user without errors.');
        }

        //Converto l'UTC della data
        const tmpMail = Helper.TimeHelper.getFormattedDateISO(this.CustomerBirthDate);

        const response = await API.UserAPI.PostUser({
          recaptcha: this.recaptchaToken,
          birthDate: tmpMail,
          companyId: "",
          email: this.GenericEmail,
          firstName: this.CustomerName,
          telephone: this.CustomerPhone,
          lastName: this.CustomerSurname,
          password: this.GenericPassword,
          sex: this.CustomerSex,
          userType: 4,
          termsAccepted: this.termsAndConditions
        }).then(response => {
          if(this.debug){
            console.log('DEBUG - Compelted call to API');
          }

          if(response.status == 200)
          {
            _iub.cons.sendData();

            if(this.debug){
              console.log(response.data);
            }

            //Confermo all'utente con un toast che la registrazione è avvenuta con successo
            // @ts-ignore
            this.$refs.toast.add({severity:'success', summary: 'Completato', detail: 'Grazie, ora sei registrato! Verifica il tuo profilo via mail per iniziare ad utilizzare AmbuBook.',life: 5500});

            //Waiting per mostrare il toast di conferma
            setTimeout(() => {
              //Spengo il caricamento alla conclusione delle operazioni con il backend
              this.loading = false;

              //Reindirizzamento a pagina principale
              this.$router.replace('/');
            }, 6000);
          }
        }).catch(error => {
          if(this.debug){
            console.log('DEBUG - Error: ' + error);
          }

          // @ts-ignore
          if(error.response && error.response.status === 400)
          {
            if (error.response.data.key === 18) {
              // @ts-ignore
              this.$refs.toast.add({severity:'error', summary: 'Opss...', detail: 'Email già in uso',life: 2500});
            } else {
              // @ts-ignore
              this.$refs.toast.add({severity:'error', summary: 'Opss...', detail: 'Creazione utente non conclusa correttamente',life: 2500});
            }
          }
          else
          {
            // @ts-ignore
            this.$refs.toast.add({severity:'error', summary: 'Opss...', detail: 'Stiamo riscontrando delle problematiche interne, ti preghiamo di contattare il supporto',life: 2500});
          }
        }).finally(() => {
          //Spengo il caricamento alla conclusione delle operazioni con il backend
          this.loading = false;
        })
      }
    },

    //Verifica dati inseriti dall'utente
    checkInputByUser(name, surname, email, phone, password, confirmPassword, birthdate, sex){

      if(this.debug){
        console.log('DEBUG - Start checking user input...');
      }

      //Controllo errori di inserimento
      var inputErrors = false;
      var inputErrorMessage = '';

      //Regex
      const passwordRegex = /^(?=.*[!@#$%^&*_\-])(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
      const italianPhoneNumberRegex = /^(\+39(\s)?)?[0-9]{9,10}$/;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      //Normalizzo se la variabile è nulla
      if(name == null){name=''};
      if(surname == null){surname=''};
      if(email == null){email=''};
      if(phone == null){phone=''};

      //Eseguo prima il trim di tutto l'inserito
      name = name.trim();
      surname = surname.toString().trim();
      email = email.toString().trim();
      phone = phone.toString().trim();     

      //Inizio il controllo di integrità dei dati
      //----------TODO---------- 
      //Mantengo commentata la porzione di codice che imposta la classe p-invalid per mostrare l'alone rosso in caso di errore.
      //Per ora non sono riuscito a farlo funzionare con i componenti dinamici. Da capire in futuro
      //----------ENDTODO--------
      if(name == ''){

        inputErrors = true;
        inputErrorMessage = 'Nome non inserito correttamente.';
        //Assegno la classe con errore per evidenziare di rosso l'input
        //this.$refs.customerNameInput.$el.classList.add('p-invalid');

      } else if(surname == ''){

        inputErrors = true;
        inputErrorMessage = 'Cognome non inserito correttamente.';
        //Assegno la classe con errore per evidenziare di rosso l'input
        //this.$refs.customerSurnameInput.$el.classList.add('p-invalid');

      } else if(!emailRegex.test(email)){
        inputErrors = true;
        inputErrorMessage = 'Mail non inserita correttamente.';
        //Assegno la classe con errore per evidenziare di rosso l'input
        //this.$refs.genericEmailInput.$el.classList.add('p-invalid');

      } else if(password == ''){

        inputErrors = true;
        inputErrorMessage = 'Password non inserita correttamente';
        //Assegno la classe con errore per evidenziare di rosso l'input
        //this.$refs.genericPasswordInput.$el.classList.add('p-invalid');

      } else if(password.length < 8){

        inputErrors = true;
        inputErrorMessage = 'La password deve essere composta da almeno 8 caratteri.';
        //Assegno la classe con errore per evidenziare di rosso l'input
        //this.$refs.genericPasswordInput.$el.classList.add('p-invalid');

      } else if(!passwordRegex.test(password)){

        inputErrors = true;
        inputErrorMessage = 'La password deve contenere almeno una minuscola, una maiuscola, un carattere speciale e un numero.';
        //Assegno la classe con errore per evidenziare di rosso l'input
        //this.$refs.genericPasswordInput.$el.classList.add('p-invalid');

      } else if(!passwordRegex.test(password)){

        inputErrors = true;
        inputErrorMessage = 'La password deve contenere almeno una minuscola, una maiuscola, un carattere speciale e un numero.';
        //Assegno la classe con errore per evidenziare di rosso l'input
        //this.$refs.genericPasswordInput.$el.classList.add('p-invalid');

      } else if(password != confirmPassword){

        inputErrors = true;
        inputErrorMessage = 'Le due password non corrispondono.';
        //Assegno la classe con errore per evidenziare di rosso l'input
        //this.$refs.genericPasswordInput.$el.classList.add('p-invalid');

      } else if(!italianPhoneNumberRegex.test(phone)){

        inputErrors = true;
        inputErrorMessage = 'Inserire un numero di cellulare valido.';
        //Assegno la classe con errore per evidenziare di rosso l'input
        //this.$refs.customerPhoneInput.$el.classList.add('p-invalid');

      } else if(birthdate === null || birthdate === undefined){

        inputErrors = true;
        inputErrorMessage = 'Data di nascita non inserita correttamente';
        //Assegno la classe con errore per evidenziare di rosso l'input
        //this.$refs.customerBirthDateInput.$el.classList.add('p-invalid');

      } else if(this.isUserOverAge(birthdate, 14)){
        
        inputErrors = true;
        inputErrorMessage = 'Non è possibile registrarsi con età inferiore a 13 anni.';
        //Assegno la classe con errore per evidenziare di rosso l'input
        //this.$refs.customerBirthDateInput.$el.classList.add('p-invalid');

      } else if(sex === null || sex === undefined){

        inputErrors = true;
        inputErrorMessage = 'Selezionare il proprio sesso.'
        //Assegno la classe con errore per evidenziare di rosso l'input
        //this.$refs.customerSexInput.$el.classList.add('p-invalid');

      } else if (!this.termsAndConditions) {
        inputErrors = true;
        inputErrorMessage = 'È necessario accettare i Termini e Condizioni per proseguire.';
        this.invalidTermsAndConditions = true;
      }

      if(this.debug){
        console.log('DEBUG - End checking user input...');
      }

      //Se ci sono errori li mostro in un toast
      if(inputErrors){
        // @ts-ignore
        this.$refs.toast.add({severity:'error', summary: 'Errore inserimento', detail: inputErrorMessage,life: 3000});
      }

      return inputErrors;
    },

    isUserOverAge(birthDate, userAge) {
      //Mi calcolo i millisecondi per anno e divido l'età passata come parametro ottenendo l'età in anni
      const millisecondsPerYear = 1000 * 60 * 60 * 24 * 365.25;
      const timeDiff = Date.now() - new Date(birthDate).getTime();
      const age = Math.floor(timeDiff / millisecondsPerYear);

      if(this.debug){
        console.log('DEBUG - IsUserOverAge: millisecondsPerYear: ' + millisecondsPerYear);
        console.log('DEBUG - IsUserOverAge: timeDiff: ' + timeDiff);
        console.log('DEBUG - IsUserOverAge: age: ' + age);
        console.log('DEBUG - IsUserOverAge: result: ' + age >= userAge);
      }
      return age < userAge;
    },

    //Creazione utente amministratore di associazione
    async createCompanyAndAdminUser(){

      if(this.debug)
      {
        console.log("DEBUG - createCompanyAndAdminUser Start");
        console.log('DEBUG - name: ' + this.AdminName);
        console.log('DEBUG - surname: ' + this.AdminSurname);
        console.log('DEBUG - email: ' + this.GenericEmail);
        console.log('DEBUG - password: ' + this.GenericPassword);
        console.log('DEBUG - telephone: ' + this.AdminPhone);
        console.log('DEBUG - sex: ' + this.AdminSex);
        console.log('DEBUG - birthdate: ' + this.AdminBirthDate);
      }
      
      //Se ci sono errori li stampo in un toast
      if(!this.checkInputByUser(this.AdminName, 
                                this.AdminSurname, 
                                this.GenericEmail, 
                                this.AdminPhone, 
                                this.GenericPassword,
                                this.GenericPasswordConfirm, 
                                this.AdminBirthDate, 
                                this.AdminSex))
      {

          if(this.debug){
            console.log('DEBUG - Input of user without errors.');
          }

          //Converto l'UTC della data
          this.AdminBirthDate = Helper.TimeHelper.getFormattedDateISO(this.AdminBirthDate);

          const response = await API.UserAPI.PostUserAndCompany({
            recaptcha: this.recaptchaToken,
            company: {
                address:this.CompanyHeadQuarterAddress.route + " " + this.CompanyHeadQuarterAddress.street_number,
                city: this.CompanyCity,
                email: this.CompanyEmail,
                name: this.CompanyName,
                phone: this.CompanyPhone,
                region: this.CompanyRegion,
                zipCode: this.CompanyZipCode,
                vatNumber: this.VATNumber,
                taxCode: this.TaxCode,
                invoiceCode: this.InvoiceCode,
                location: {
                  lat: this.CompanyLatitudeCoord,
                  lon: this.CompanyLongitudeCoord
                },
              },
              user: {
                birthDate: this.AdminBirthDate,
                email: this.GenericEmail,
                firstName: this.AdminName,
                lastName: this.AdminSurname,
                password: this.GenericPassword,
                sex: this.AdminSex,
                telephone: this.AdminPhone,
                userType: 2,
                termsAccepted: this.termsAndConditions
              }
            }
          ).then(response => {
            if(this.debug){
              console.log('DEBUG - Compelted call to API');
            }

            if(response.status == 200)
            {
              _iub.cons.sendData();

              if(this.debug){
                console.log(response.data);
              }

              //Confermo all'utente con un toast che la registrazione è avvenuta con successo
              // @ts-ignore
              this.$refs.toast.add({severity:'success', summary: 'Completato', detail: 'Grazie, ora sei registrato! Verifica il tuo profilo via mail per iniziare ad utilizzare AmbuBook.',life: 5500});

              //Waiting per mostrare il toast di conferma
              setTimeout(() => {
                //Spengo il caricamento alla conclusione delle operazioni con il backend
                this.loading = false;

                //Reindirizzamento a pagina principale
                this.$router.replace('/');
              }, 6000);
            }
          }).catch(error => {
            if(this.debug){
              console.log('DEBUG - Error: ' + error);
            }

            // @ts-ignore
            if(error.response && error.response.status === 400)
            {
              if (error.response.data.key === 18) {
                // @ts-ignore
                this.$refs.toast.add({severity:'error', summary: 'Opss...', detail: 'Email già in uso',life: 2500});
              } else {
                // @ts-ignore
                this.$refs.toast.add({severity:'error', summary: 'Opss...', detail: 'Creazione utente non conclusa correttamente',life: 2500});
              }
            }
            else
            {
              // @ts-ignore
              this.$refs.toast.add({severity:'error', summary: 'Opss...', detail: 'Stiamo riscontrando delle problematiche interne, ti preghiamo di contattare il supporto',life: 2500});
            }
          }).finally(() => {
            //Spengo il caricamento alla conclusione delle operazioni con il backend
            this.loading = false;
          }
        )
      }
    },

    //Verifica del pulsante avanti durante la creazione azienda
    checkCompanyData(){

      //Regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const italianPhoneNumberRegex = /^(\+39(\s)?)?[0-9]{9,10}$/;
      const italianFixPhoneNumberRegex = /^\d{9,11}$/;
      const italianVatNumberRegex = /^[0-9]{11}$/;
      const italianTaxCodeRegex = /^[0-9]{11}$/;
      const italianInvoiceNumberRegex = /^$|^([\w\d]{7})$/;

      if(this.debug){
        console.log("DEBUG - CompanyName: " + this.CompanyName);
        console.log("DEBUG - CompanyPhone: " + this.CompanyPhone);
        console.log("DEBUG - CompanyEmail: " + this.CompanyEmail);
        console.log("DEBUG - CompanyAddress: " + this.CompanyHeadQuarterAddress);
      }
      //Verifico se è stato inserito il nome azienda
      if(this.CompanyName === null || this.CompanyName === undefined || this.CompanyName === ""){
        this.$refs.toast.add({severity:'error', summary: 'Errore inserimento', detail: "Inserire il nome dell'azienda",life: 3000});
      } 
      //Verifico che sia stato messo il numero di telefono
      else if(this.CompanyPhone === null || this.CompanyPhone === undefined || this.CompanyPhone === ""){
        this.$refs.toast.add({severity:'error', summary: 'Errore inserimento', detail: "Inserire il numero di telefono dell'azienda",life: 3000});
      }
      else if(!italianPhoneNumberRegex.test(this.CompanyPhone) || !italianFixPhoneNumberRegex.test(this.CompanyPhone)){
        this.$refs.toast.add({severity:'error', summary: 'Errore inserimento', detail: "Numero di telefono inserito non valido. Si accettano solamente numeri fissi o cellulare.",life: 3000});
      }
      //Verifico che sia stata inserita la mail dell'azienda
      else if(this.CompanyEmail === null || this.CompanyEmail === undefined || this.CompanyEmail === ""){
        this.$refs.toast.add({severity:'error', summary: 'Errore inserimento', detail: "Inserire la mail dell'azienda",life: 3000});
      }
      //Verifico che la mail sia corretta
      else if(!emailRegex.test(this.CompanyEmail)){
        this.$refs.toast.add({severity:'error', summary: 'Errore inserimento', detail: "Email non riconosciuta come valida",life: 3000});
      }
      //Verifico che sia stata inserita la via
      else if(this.CompanyHeadQuarterAddress === null || this.CompanyHeadQuarterAddress === undefined || this.CompanyHeadQuarterAddress===""){
        this.$refs.toast.add({severity:'error', summary: 'Errore inserimento', detail: "Inserire l'indirizzo dell'azienda",life: 3000});
      }
      //Verifico che l'utente abbia inserito anche il numero civico
      else if(this.CompanyHeadQuarterAddress.street_number === undefined){
        this.$refs.toast.add({severity:'error', summary: 'Errore inserimento', detail: "Inserire il numero civico nell'indirizzo dell'azienda",life: 3000});
      }
      //La partita iva non è obbligatoria, ma se presente ci deve essere anche il codice fiscale
      else if(this.VATNumber !== "" && this.TaxCode === "") {
        this.$refs.toast.add({severity:'error', summary: 'Errore inserimento', detail: "Il codice fiscale è obbligatorio se inserita la partita iva",life: 3000});
      }
      //Verifico che la partita iva sia corretta
      else if(this.VATNumber !== "" && !italianVatNumberRegex.test(this.VATNumber)){
        this.$refs.toast.add({severity:'error', summary: 'Errore inserimento', detail: "Partita iva non valida",life: 3000});
      }
      //Verifico che il codice fiscale sia corretto
      else if(!italianTaxCodeRegex.test(this.TaxCode)){
        this.$refs.toast.add({severity:'error', summary: 'Errore inserimento', detail: "Codice fiscale non valido",life: 3000});
      }
      // Verifico che il codice di fatturazione sia corretto
      else if(!italianInvoiceNumberRegex.test(this.InvoiceCode) || this.InvoiceCode === "") {
        this.$refs.toast.add({severity:'error', summary: 'Errore inserimento', detail: "Codice di fatturazione non valido",life: 3000});
      } else if (!this.termsAndConditions) {
        this.$refs.toast.add({severity:'error', summary: 'Errore inserimento', detail: "Devi accettare i termini e condizioni",life: 3000});
      } else {
        this.CompanyCity=this.CompanyHeadQuarterAddress.locality;
        this.CompanyLatitudeCoord=this.CompanyHeadQuarterAddress.latitude;
        this.CompanyLongitudeCoord=this.CompanyHeadQuarterAddress.longitude;
        this.CompanyRegion=this.CompanyHeadQuarterAddress.administrative_area_level_1;
        this.CompanyZipCode=this.CompanyHeadQuarterAddress.postal_code;

        this.active = 1;
      }
    },
    iubendaLoader(){
      _iub.cons_instructions.push(["load", {
        form: {
            selector: "signupForm",
            map: {
                subject: {
                    first_name: "firstName",
                    last_name: "lastName",
                    email: "email"
                },
                preferences: {
                    generic: "generic",
                }
            }
        },
        consent: {
            legal_notices: [{
                    identifier: "privacy_policy",
                },
                {
                    identifier: 'cookie_policy',
                },
                {
                    identifier: "terms",
                }
            ],
        },
    }])
    }
  }
}
</script>

<style scoped>
.signUpContainer{
  padding: 70px;
  padding-bottom: 20px;
  border-radius: 80px;
  box-shadow: 0px 0px 50px 7px rgba(0,0,0,0.1);
  max-width: 700px;
  position: relative;
  margin: 200px auto;
  margin-top: 50px;
  margin-bottom: 150px;
  position: relative;
  height: fit-content;
  background: white;
}
.signUpContainer .p-float-label{
  margin: 15px;
  width: max-content;
}
.containerHeader{
  margin-bottom: 25px;
}
.containerContent{
  position: relative;
  align-items: center;
  display: inline-grid;
  justify-items: center;
  margin: 30px;
}
.containerContent .customerContent, .containerContent .companyContent{
  margin: 0px;
}
.containerFooter{
  /*margin-top: 25px;*/
  display: grid;
}
.p-button{
  background-color: #EF8354;
  border-color: #EF8354;
  width: 150px;
}
.p-button:hover{
  border-color: #bc6844;
  background-color: #bc6844;
}

:deep(.p-inputtext){
  width: 300px;
}
:deep(.p-inputtext:hover){
  border-color: #EF8354;
}
:deep(.p-inputtext:focus){
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
:deep(.p-button:focus){
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
:deep(.p-buttonset .p-button) {
  margin: 0;
  margin-bottom: 45px;
  width: 150px;
  color: grey;
  font-size: small;
  border-color: #D1d5db;
  height: 25px;
}
:deep(.p-buttonset .p-button:focus) {
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
:deep(.p-selectbutton .p-button){
  height: 30px !important;
  width: 150px;
  margin-bottom: 25px;
}
:deep(.p-selectbutton .p-button.p-highlight) {
  background: #EF8354;
  border-color: #EF8354;
  color: #ffffff;
}
:deep(.p-datepicker table td) {
  color: #ffffff;
  background: #ef8354;
}
:deep(.p-dropdown){
  width: 300px;
  text-align: left;
}
:deep(.p-dropdown .p-dropdown-label:focus){
  border-color: #ffffff;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0);
}

.companySteps{
  width: -webkit-fill-available;
  zoom: 80%;
  margin-bottom: 30px;
}

.Arrows {
  display: flex;
  justify-content: center;
  width: 300px;
  margin-top: 20px;
  align-items: center;
  height: 25px;
}

.containerReturnBackButton,
.containerGoAheadButton {
  display: flex;
  align-items: baseline;
  font-size: 1rem;
  color: grey;
  transition: 0.5s;
}

.containerReturnBackButton:hover,
.containerGoAheadButton:hover {
  cursor: pointer;
}

.containerReturnBackButton span i,
.containerGoAheadButton span i {
  font-size: 0.7rem;
  color: grey;
  margin-top: 20px;
  transition: 0.5s;
}
.containerGoAheadButton span i {
  margin-left: 10px;
}
.containerReturnBackButton span i{
  margin-right: 10px;
}

.containerReturnBackButton span i:hover,
.containerGoAheadButton span i:hover {
  cursor: pointer;
}
.googleAutocomplete{
  font-family: var(--font-family);
  /*font-feature-settings: var(--font-feature-settings, normal);*/
  font-size: 1rem;
  color: #4b5563;
  background: #ffffff;
  padding: 0.75rem 0.75rem;
  border: 1px solid #d1d5db;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 6px;
  width: 300px;
  margin: 15px;
}
.googleAutocomplete:hover{
  border-color: #EF8354;
}
:deep(.googleAutocomplete:focus){
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
  outline: none;
  border-color: #EF8354;
}
:deep(vue-google-autocomplete:focus){
  border: #EF8354;
}

@media (max-width: 1280px) and (min-width: 501px) {
    .signUpContainer{
      zoom:100%;
      margin: 0px auto;
      /*width: -webkit-fill-available;*/
    }
    .containerContent{
      margin: 0px;
    }
    .signUpContainer .p-float-label{
      margin: 20px;
      width: max-content;
    }
  }
  @media (max-width: 500px) {
    .signUpContainer{
      padding: 10px;
      border-radius: 20px;
      margin: 5px;
      width: -webkit-fill-available;
    }
    .containerHeader{
      margin: 10px;
      margin-top: 30px;
    }
    .containerContent{
      margin: 0px;
    }
    .containerFooter{
      margin: 20px;
      margin-bottom: 30px;
    }
    :deep(.p-inputtext){
      width: 300px;
      height: 60px;
    }
    :deep(.p-dropdown){
      width: 300px;
      height: 60px;
      line-height: 36px;
    }
    .googleAutocomplete{
      width: 300px;
      height: 60px;
    }
  }

  .disclaimer-google{
    font-size: 0.8rem;
    color: grey;
    text-align: center;
    margin-top: 20px;
  }

</style>