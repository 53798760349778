<template>
    <div class="cardContainer">
        <h1>Logo azienda</h1>
        <p>Carica il logo della tua azienda</p>
        <br>
        <Avatar :image="companyLogo" class="mr-2 mt-2 ml-2 mb-2" shape="circle" size="xlarge"/>
        <br>
        <br>
        <br>
        <FileUpload mode="basic" name="logo" :url="urlApi" accept="image/*" :maxFileSize="1000000" @upload="onUpload" @before-send="addJWT" :auto="true"
        :invalid-file-size-message="'Il file supera la dimensione massima di 1MB'"/>
    </div>
</template>

<script>
import config from '@/config';
import Cookies from 'js-cookie';
import { usePrimeVue } from 'primevue/config';
import API from '@/api/API';

export default {
    name: 'CompanyImages',
    components: {
    },
    data() {
        return {
            files: [],
            totalSize: 0,
            totalSizePercent: 0,
            urlApi: "",
            companyLogo: null,

            activeIndex: 0,
            displayCustom: false,
            
            primevue: usePrimeVue()
        }
    },
    mounted() {
        const jwt = Cookies.get('token');
        const companyId = Cookies.get('companyId');
        if (jwt === undefined || companyId === undefined) {
            this.$router.push({ name: 'login' });
        } else {
            this.urlApi = config.backendIP + '/api/v1/company/logo/' + companyId;

            this.reloadLogo();
        }
    },
    methods: {
        onUpload(event) {
            this.$toast.add({severity:'info', summary: 'Caricato', detail: 'Logo caricato con successo', life: 3000});
            this.reloadLogo();
        },
        addJWT(xhr) {
            if (Cookies.get('token') === undefined) {
                this.$router.push({ name : 'Login' });
            } else {
                xhr.xhr.withCredentials = true;
                xhr.xhr.setRequestHeader('Authorization', Cookies.get('token'));
            }
        },
        reloadLogo() {
            const jwt = Cookies.get('token');
            const companyId = Cookies.get('companyId');
            API.CompanyAPI.GetCompanyLogo(companyId, jwt).then(response => {
                this.companyLogo = response.data;
            }).catch(error => {
                console.log(error);
            });
        }
    }
}

</script>

<style scoped>
.cardContainer{
    padding: 70px;
    border-radius: 50px;
    box-shadow: 0px 0px 50px 7px rgba(0,0,0,0.1);
    max-width: 100%;
    position: relative;
    /* margin: 200px auto; */
    position: relative;
    height: fit-content;
    text-align: center;
    background-color: white;
}
@media (max-width: 500px){
    .cardContainer{
        padding: 20px;
        border-radius: 20px;
        margin: 10px;
    }
}
</style>