// @ts-nocheck
import Vuex from 'vuex'
import Cookies from 'js-cookie'

export const store = new Vuex.Store({
 state: {

    //Variabili per il controllo della risoluzione
    _isOnMobileDisplay: window.innerWidth < 1280,

    //Variabili per il controllo dell'utente
    _loggedIn: false,
    _token: '',
    _loggedInUser: [
        {
          id:'',
          firstName:'',
          lastName:'',
          sex:'',
          birthDate:'',
          email:'',
          telephone:'',
          userType:'',
          companyId:'',
          createdAt:'',
          updatedAt:'',
          mailConfirmed: false,
        }
      ],

    _resultInfo: '',
    
      //Variabili per il controllo del form login e resetPassword
      _isRecoverPasswordVisible: false,
 },

 getters:{
    IsLoggedIn(state){
        return state._loggedIn;
    },
    IsOnMobileDisplay(state){
        return state._isOnMobileDisplay;
    },
    IsRecoverPasswordVisible(state){
        return state._isRecoverPasswordVisible;
    },
    GetLoggedInUserEmail(state){
        return state._loggedInUser.email;
    },
    GetResultInfo(state){
        state._resultInfo = JSON.parse(window.localStorage.getItem('resultInfo')) || state._resultInfo;
        return state._resultInfo;
    }
 },

 mutations:{
    //Log In
    LogIn(state){
        state._loggedIn = true;
        return state._loggedIn;
    },
    LogOut(state){
        state._loggedIn = false;
        return state._loggedIn;
    },
    SetLoggedInUser(state, {user, token, remember}){

        var daysToRemember = 0;

        state._loggedInUser.id = user.id;
        state._loggedInUser.firstName = user.firstName;
        state._loggedInUser.lastName = user.lastName;
        state._loggedInUser.sex = user.sex;
        state._loggedInUser.birthDate = user.birthDate;
        state._loggedInUser.email = user.email;
        state._loggedInUser.userType = user.userType;
        state._loggedInUser.companyId = user.companyId;
        state._loggedInUser.createdAt = user.createdAt;
        state._loggedInUser.updatedAt = user.updatedAt;
        state._loggedInUser.mailConfirmed = user.mailConfirmed;
        state._loggedInUser.telephone = user.telephone;
        state._token = token;

        if(remember){
            //Se l'utente ha spuntato ricordami, lo ricordo per 30gg
            daysToRemember = 30;
        }
        else{
            //Se l'utente non ha spuntato ricordami, lo ricordo comunque per 1gg per evitare che ricaricando esca
            daysToRemember = 0.044;
        }
        //Salvo nei cookie l'utente
        Cookies.set('id',user.id,{ expires: daysToRemember });
        Cookies.set('firstName',user.firstName,{ expires: daysToRemember });
        Cookies.set('lastName',user.lastName,{ expires: daysToRemember });
        Cookies.set('sex',user.sex,{ expires: daysToRemember });
        Cookies.set('birthDate',user.birthDate,{ expires: daysToRemember });
        Cookies.set('email',user.email,{ expires: daysToRemember });
        Cookies.set('userType',user.userType,{ expires: daysToRemember });
        Cookies.set('companyId',user.companyId,{ expires: daysToRemember });
        Cookies.set('mailConfirmed', user.mailConfirmed,{ expires: daysToRemember });
        Cookies.set('telephone', user.telephone,{ expires: daysToRemember });
        
        //Salvo anche il suo token
        Cookies.set('token',token,{ expires: daysToRemember });
    },

    resetStore(state) {
        //Reinizializzo lo Store
        Object.assign(state, {
          _loggedIn: false,
          _token: '',
          _loggedInUser: {
            id: '',
            firstName: '',
            lastName: '',
            sex: '',
            birthDate: '',
            email: '',
            userType: '',
            companyId: '',
            createdAt: '',
            updatedAt: '',
            telephone:'',
            mailConfirmed: false,
          },
          _isRecoverPasswordVisible: false,
        });
        //Rimuovo tutti i cookie salvati
        const cookies = Object.keys(Cookies.get());
        cookies.forEach((cookie) => {
          Cookies.remove(cookie);
        });
    },

    LoadUserFromCookies(state){
        //Leggo il token, se presente vuol dire che ho i cookie popolati.
        //!TODO - Servirà verificare che questo token sia anche valido per le chiamate!
        const readToken = Cookies.get('token');
        if(readToken != '' && readToken != undefined){
            state._loggedIn = true;
            state._loggedInUser.id = Cookies.get('id');
            state._loggedInUser.firstName = Cookies.get('firstName');
            state._loggedInUser.lastName = Cookies.get('lastName');
            state._loggedInUser.sex = Cookies.get('sex');
            state._loggedInUser.birthDate = Cookies.get('birthDate');
            state._loggedInUser.email = Cookies.get('email');
            state._loggedInUser.userType = Cookies.get('userType');
            state._loggedInUser.companyId = Cookies.get('companyId');
            state._loggedInUser.mailConfirmed = Cookies.get('mailConfirmed');
            state._loggedInUser.telephone = Cookies.get('telephone');
            state._token=readToken;
        }
        else{
            state._loggedIn = false;
        }
    },

    SwitchResetLogin(state){
        if(state._isRecoverPasswordVisible){
            state._isRecoverPasswordVisible = false;
        }
        else{
            state._isRecoverPasswordVisible = true;
        }

    },

    SetResultInfo(state, {rawItem, search}) {
        state._resultInfo = {rawItem, search};
        window.localStorage.setItem('resultInfo', JSON.stringify({rawItem, search}));
    },

    //Metodo che mi serve per forzare a false la visualizzazione del cambio password, in questo modo, viaggiando nella navbar
    //ogni volta che ritornerò sulla finestra accedi mi verrà mostrato per primo il login e non l'ultima finestra visualizzata
    SwitchToLoginComponent(state){
        state._isRecoverPasswordVisible = false;
    },
 },
});