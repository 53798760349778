<template>
    <br>
    <br>
    <br>
    <br>
    <br>
    <div class="flex overflow-hidden">
        <div class="ResultText flex-grow-1 flex align-items-center justify-content-center">
            <h1>404<br>Non sappiamo come tu sia arrivato qui 😮</h1>
        </div>
    </div>
    <div class="flex overflow-hidden">
        <div class="flex-grow-1 flex align-items-center justify-content-center">
            <Button label="Home" @click="this.$router.replace('/')" rounded />
        </div>
    </div>
    <br>
    <br>
    <br>
    <br>
    <br>
</template>

<script>
    export default {
        name: 'NotFoundView',
        data() {
            return {
            }
        }
    }
</script>

<style scoped>
  .ResultText{
    z-index: -2;
    padding: 0;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 0 0 900px 0;
    margin: 0 auto;
    height: auto;
    line-height: normal;
    margin-bottom: 20px;
    position: relative;
  }
  .ResultText h1{
    color: rgb(0, 0, 0);
    font-size: 3rem;
    padding-top: 20px;
    margin: 0 auto;
  }
</style>
  