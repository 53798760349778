<template>
  <div class="Support">
    <h1>Supporto</h1>
  </div>
</template>

<script>
export default {
  name: 'SupportView',
  components: {
  }
}
</script>

<style scoped>
.Support{
    position: relative;
    z-index: -2;
    margin:100px;
    text-align: center;
}
</style>
