<template>
  <div class="CompanyProfileContainer">
    <div class="containerHeader"> 
      <i class="pi pi-user" style="font-size: 2.5rem; color: #EF8354;"></i>
      <h1>Dati azienda</h1>
      <p>Qui sotto i dati che puoi modificare</p>
    </div>
    <div class="containerContent">
      <FloatLabel class="field">
        <label for="companyName">Nome azienda</label>
        <InputText v-model="name" id="companyName" type="text"/>
      </FloatLabel>
      <FloatLabel>
        <Textarea v-model="companyDescription" rows="5" cols="50" />
        <label>Descrizione azienda</label>
      </FloatLabel>
      <FloatLabel class="field">
        <label for="companyPhone">Numero di telefono</label>
        <InputText v-model="companyPhone" id="companyPhone" type="text"/>
      </FloatLabel>
      <FloatLabel class="field">
        <label for="vatNumber">P. IVA</label>
        <InputText v-model="vatNumber" id="vatNumber" type="text"/>
      </FloatLabel>
      <FloatLabel class="field">
        <label for="taxCode">Codice Fiscale</label>
        <InputText v-model="taxCode" id="taxCode" type="text"/>
      </FloatLabel>
      <FloatLabel class="field">
        <label for="invoiceCode">Codice Fatturazione (SDI)</label>
        <InputText v-model="invoiceCode" id="invoiceCode" type="text"/>
      </FloatLabel>
    </div>
    <div class="containerFooter"> 
      <span>
        <Button label="Salva" @click="SaveData()" rounded />
      </span>
    </div>
    <Toast ref="toast" position="bottom-right" style="right:0px; width: -webkit-fill-available; margin: 10px; max-width: 500px;"/>
  </div>
</template>

<script>
import API from '@/api/API';
import Cookies from 'js-cookie';

export default {
  name: 'CompanyInfo',
  components: {
  },
  mounted() {
    this.reloadInfo();
  },
  data() {
    return {
      companyObj: null,
      companyDescription: "",
      companyPhone: "",
      vatNumber: "",
      taxCode: "",
      invoiceCode: "",
      name: ""
    }
  },
  methods: {
    SaveData() {
      const jwt = Cookies.get('token');
      const companyId = Cookies.get('companyId');
      if (jwt === undefined || companyId === undefined) {
        this.$router.push({ name: 'login ' });
      }

      if (!this.isValidData()) {
        return;
      }

      this.companyObj.name = this.name;
      this.companyObj.description = this.companyDescription;
      this.companyObj.phone = this.companyPhone;
      this.companyObj.vatNumber = this.vatNumber;
      this.companyObj.taxCode = this.taxCode;
      this.companyObj.invoiceCode = this.invoiceCode;

      API.CompanyAPI.UpdateCompanyInfo(companyId, jwt, this.companyObj).then((response) => {
        this.$refs.toast.add({severity:'success', summary: 'Salvato', detail: 'Informazioni salvate con successo',life: 5000});
        this.reloadInfo();
        this.re
      }).catch((error) => {
        this.$refs.toast.add({severity:'error', summary: 'Errore', detail: 'Errore nel salvataggio delle informazioni dell\'azienda',life: 5000});
      });
    },
    isValidData() {
      const italianPhoneNumberRegex = /^(\+39(\s)?)?[0-9]{9,10}$/;
      const italianFixPhoneNumberRegex = /^\d{9,11}$/;
      const italianVatNumberRegex = /^[0-9]{11}$/;
      const italianTaxCodeRegex = /^[0-9]{11}$/;
      const italianInvoiceNumberRegex = /^$|^([\w\d]{7})$/;

      //Verifico che la partita iva sia corretta
      if(this.vatNumber !== "" && !italianVatNumberRegex.test(this.vatNumber)){
        this.$refs.toast.add({severity:'error', summary: 'Errore inserimento', detail: "Partita iva non valida",life: 3000});
        return false;
      }
      //Verifico che il codice fiscale sia corretto
      else if(!italianTaxCodeRegex.test(this.taxCode)){
        this.$refs.toast.add({severity:'error', summary: 'Errore inserimento', detail: "Codice fiscale non valido",life: 3000});
        return false;
      }
      // Verifico che il codice di fatturazione sia corretto
      else if(!italianInvoiceNumberRegex.test(this.invoiceCode) || this.invoiceCode === "") {
        this.$refs.toast.add({severity:'error', summary: 'Errore inserimento', detail: "Codice di fatturazione non valido",life: 3000});
        return false;
      }
      //Verifico che sia stato messo il numero di telefono
      else if(this.companyPhone === null || this.companyPhone === undefined || this.companyPhone === ""){
        this.$refs.toast.add({severity:'error', summary: 'Errore inserimento', detail: "Inserire il numero di telefono dell'azienda",life: 3000});
        return false;
      }
      else if(!italianPhoneNumberRegex.test(this.companyPhone) || !italianFixPhoneNumberRegex.test(this.companyPhone)){
        this.$refs.toast.add({severity:'error', summary: 'Errore inserimento', detail: "Numero di telefono inserito non valido. Si accettano solamente numeri fissi o cellulare.",life: 3000});
        return false;
      }

      return true;
    },
    reloadInfo(){
      const jwt = Cookies.get('token');
      const companyId = Cookies.get('companyId');
      if (jwt === undefined || companyId === undefined) {
        this.$router.push({ name: 'login ' });
      }

      API.CompanyAPI.GetCompanyInfo(companyId, jwt).then((response) => {
        this.companyObj = response.data;
        this.name = this.companyObj.name;
        this.companyDescription = this.companyObj.description;
        this.companyPhone = this.companyObj.phone;
        this.vatNumber = this.companyObj.vatNumber;
        this.taxCode = this.companyObj.taxCode;
        this.invoiceCode = this.companyObj.invoiceCode;
      }).catch((error) => {
        this.$refs.toast.add({severity:'error', summary: 'Errore', detail: 'Errore nel recupero delle informazioni dell\'azienda',life: 5000});
      });
    }
  }

}

</script>

<style scoped>

.CompanyProfileContainer{
    padding: 70px;
    border-radius: 50px;
    box-shadow: 0px 0px 50px 7px rgba(0,0,0,0.1);
    max-width: 100%;
    position: relative;
    margin: 0px auto;
    position: relative;
    height: fit-content;
    text-align: center;
    background-color: white;
}
.CompanyProfileContainer .p-float-label{
    margin: 15px;
    width: max-content;
}
.containerHeader{
  margin-bottom: 25px;
}
.containerContent{
  position: relative;
  align-items: center;
  display: inline-grid;
  margin: 15px;
  margin-top: 0px;
}
.containerContentSingleData{
  display: flex;
  align-items: baseline;
  position: relative;
  height: 3rem;
}
.containerContentSingleData h3{
  margin-right: 5px;
}
.containerFooter{
  margin-top: 25px;
}
.checkBoxContainer{
  margin-top: 20px;
  font-size: medium;
  color: gray;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  position: relative;
}
.checkBoxContainer p{
  margin: 10px;
}
.p-button{
  background-color: #EF8354;
  border-color: #EF8354;
  width: 150px;
}
:deep(.p-checkbox-box:hover){
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
:deep(.p-checkbox .p-checkbox-box.p-highlight){
  background:#EF8354;
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
:deep(.p-checkbox-box:focus){
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
.p-button:hover{
  border-color: #bc6844;
  background-color: #bc6844;
}

:deep(.p-inputtext){
  width: 300px;
}
:deep(.p-inputtext:hover){
    border-color: #EF8354;
}
:deep(.p-inputtext:focus){
    border-color: #EF8354;
    box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
:deep(.p-button:focus){
    border-color: #EF8354;
    box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
.containerReturnBackButton span i{
  font-size: 0.7rem; 
  color: grey; 
  margin-top: 20px;
  margin-right: 10px;
  transition: 0.5s;
}
.containerReturnBackButton span i:hover{
  cursor: pointer;
}
.containerReturnBackButton{
  position: relative;
  display: flex;
  display: inline-flex;
  color: grey;
  transition: 0.5s;
  font-size: 1rem;
  align-items: baseline;
}
.containerReturnBackButton:hover{
  cursor: pointer;
}


@media (max-width: 1280px) and (min-width: 501px) {
    .CompanyProfileContainer{
      zoom:100%;
    }
    .containerContent{
      margin: 0px;
    }
    .CompanyProfileContainer .p-float-label{
      margin: 20px;
      width: max-content;
    }
  }
  @media (max-width: 500px) {
    .CompanyProfileContainer{
      padding: 10px;
      border-radius: 20px;
      margin: 10px;
    }
    .containerHeader{
      margin: 10px;
      margin-top: 30px;
    }
    .containerContent{
      margin: 0px;
    }
    .containerFooter{
      margin: 10px;
      margin-top: 50px;
    }
    :deep(.p-inputtext){
      width: 300px;
      height: 60px;
    }
    .containerReturnBackButton{
      margin-bottom: 10px;
    }
  }
</style>