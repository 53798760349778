<template>
    <div style="height: 20rem;">
        <br>
        <div class="flex flex-auto justify-content-center">
            <span class="p-float-label" style="margin: 15px;">
                <Calendar inputId="start" v-model="availability.startTime" showTime showIcon
                dateFormat="dd/mm/yy" hourFormat="24" stepMinute="5" timezone />
                <label for="start">Inizio disponibilità</label>
            </span>
        </div>
        <div class="flex justify-content-center">
            <span class="p-float-label" style="margin: 15px;">
                <Calendar inputId="end" v-model="availability.endTime" showTime showIcon
                dateFormat="dd/mm/yy" hourFormat="24" stepMinute="5" />        
                <label for="end">Fine disponibilità</label>
            </span>
        </div>
        <br>
        <div class="flex justify-content-center">
            <Button :label="isInserting ? 'Aggiungi disponibilità' : 'Modifica disponibilità'" @click="addAvailability" rounded />      
        </div> 
        <br>
        <div v-if="!isInserting" class="flex justify-content-center">
            <Button label="Elimina disponibilità" @click="deleteAvailability" severity="danger" rounded />      
        </div> 
        <br>
    </div>
    <Toast ref="toast" position="bottom-right" style="right:0px; width: -webkit-fill-available; margin: 10px; max-width: 500px;"/>
</template>

<script>
import API from '@/api/API';
import Cookies from 'js-cookie';
import Helper from '@/helper/Helpers';

export default {
    name: 'AddAvailability',

    props: {
        serviceId: {
            type: String,
            required: true
        },
        companyId: {
            type: String,
            required: true
        },
        inStartDate: {
            type: Date,
            required: false
        },
        inEndDate: {
            type: Date,
            required: false
        },
        isInserting : {
            type: Boolean,
            required: true
        },
        availabilityId : {
            type: String,
            required: false
        },
        revertEvent: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            availability: {
                startTime: null,
                endTime: null,
                serviceId: this.serviceId,
                companyId: this.companyId,
                availabilityId: this.availabilityId
            }
        }
    },
    components: {

    },
    mounted() {
        if (this.inStartDate !== undefined) {
            this.availability.startTime = Helper.TimeHelper.getDateToUTC(this.inStartDate);
        }
        if (this.inEndDate !== undefined) {
            this.availability.endTime = Helper.TimeHelper.getDateToUTC(this.inEndDate);
        }
    },
    methods: {
        addAvailability() {
            if (!this.isValid()) {
                this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'Inserire data e ora di inizio e fine disponibilità!',life: 6500});
                return;
            }
            const jwt = Cookies.get('token');
            if (jwt === undefined) {
                this.$router.push({ name: 'login' });
            } else {
                if (this.isInserting) {
                    API.AvailabilityAPI.PostAvailability(jwt,
                        this.availability.serviceId, this.availability.companyId,
                        Helper.TimeHelper.getFormattedDateISO(this.availability.startTime), Helper.TimeHelper.getFormattedDateISO(this.availability.endTime)
                    ).then((response) => {
                        if (response.status === 200) {
                            this.$emit('availabilityAdded');
                        }
                    }).catch((error) => {
                        if (error.response !== undefined) {
                            if (error.response.status === 401) {
                                this.$router.push({ name: 'login' });
                            } else if (error.response.status === 409) {
                                this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'La nuova disponibilità non può toccare o sovrapporsi ad altre disponibilità!',life: 6500});
                            } else if (error.response.status === 418){
                                this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'La nuova disponibilità non può essere aggiunta nel passato!',life: 6500});
                            } else if (error.response.status === 413) {
                                this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'La nuova disponibilità non può durare più di una settimana consecutiva!',life: 6500});
                            }
                        } else {
                            this.$refs.toast.add({severity:'error', summary: 'Errore', detail: 'Errore sconosciuto!',life: 6500});
                        }
                    });
                }
                else
                {
                    API.AvailabilityAPI.PutAvailability(jwt, this.availability.availabilityId,
                        Helper.TimeHelper.getFormattedDateISO(this.availability.startTime),
                        Helper.TimeHelper.getFormattedDateISO(this.availability.endTime)
                    ).then((response) => {
                        if (response.status === 200) {
                            this.$emit('availabilityAdded');
                        }
                    }).catch((error) => {
                        if (error.response !== undefined) {
                            if (error.response.status === 401) {
                                this.$router.push({ name: 'login' });
                            } else if (error.response.status === 409) {
                                this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'La nuova disponibilità non può toccare o sovrapporsi ad altre disponibilità!',life: 6500});
                            } else if (error.response.status === 418) {
                                this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'La nuova disponibilità non può essere aggiunta nel passato!',life: 6500});
                            } else if (error.response.status === 413) {
                                this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'La nuova disponibilità non può durare più di una settimana consecutiva!',life: 6500});
                            }
                        } else {
                            this.$refs.toast.add({severity:'error', summary: 'Errore', detail: 'Errore sconosciuto!',life: 6500});
                        }
                        if (this.revertEvent !== null && this.revertEvent.revert !== undefined)
                            this.revertEvent.revert();
                    });
                }
            }
        },
        deleteAvailability() {
            const jwt = Cookies.get('token');
            if (jwt === undefined) {
                this.$router.push({ name: 'login' });
            } else {
                API.AvailabilityAPI.DeleteAvailability(jwt, this.availability.availabilityId).then((response) => {
                    if (response.status === 200) {
                        this.$emit('availabilityAdded');
                    }
                }).catch((error) => {
                    if (error.response !== undefined) {
                        if (error.response.status === 401) {
                            this.$router.push({ name: 'login' });
                        }
                    } else {
                        this.$refs.toast.add({severity:'error', summary: 'Errore', detail: 'Errore sconosciuto!',life: 6500});
                    }
                });
            }
        },
        isValid() {
            return this.availability.startTime !== null && this.availability.endTime !== null;
        }
    }
}
</script>