export default {
    orederBy(array, key, order = 'asc') {
        return array.result.sort((a, b) => {
            if (order === 'asc') {
                return a[key] > b[key] ? 1 : -1;
            }
            return a[key] < b[key] ? 1 : -1;
        });
    },
    filterByPrice(array, minPrice, maxPrice) {
        return array.result.filter(item => item.price >= minPrice && item.price <= maxPrice);
    },
    filterByReviews(array, minReviews) {
        return array.result.filter(item => item.reviews >= minReviews);
    }
}