import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import HowItWorksView from '../views/HowItWorksView.vue'
import CompaniesView from '../views/CompaniesView.vue'
import PrivatesView from '../views/PrivatesView.vue'
import ContactsView from '../views/ContactsView.vue'
import SupportView from '../views/SupportView.vue'
import SignUpView from '../views/SignUpView.vue'
import LoginView from '../views/LogInView.vue'
import ResetPasswordView from '../views/ResetPasswordView.vue'
import EmailConfirmationView from '../views/EmailConfirmationView.vue'
import ResultsView from '../views/ResultsView.vue'
import NotFoundView from '../views/NotFoundView.vue'
import WorkInProgressView from '../views/WorkInProgressView.vue'
import RatingView from '@/views/RatingView.vue'
import API from '../api/API'
import Cookies from 'js-cookie'
import ResultInfoView from '@/views/ResultInfoView.vue'
import DashboardStatisticsView from '@/views/DashboardStatisticsView.vue'
import DashboardVehiclesView from '@/views/DashboardVehiclesView.vue'
import DashboardBookingsView from '@/views/DashboardBookingsView.vue'
import DashboardCalendarView from '@/views/DashboardCalendarView.vue'
import MyBookingsView from '@/views/MyBookingsView.vue'
import UserProfileView from '@/views/UserProfileView.vue'
import CompanySettingsView from '@/views/CompanySettingsView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/howitworks',
    name: 'howitworks',
    component: HowItWorksView
  },
  {
    path: '/companies',
    name: 'companies',
    component: CompaniesView
  },
  {
    path: '/privates',
    name: 'privates',
    component: PrivatesView
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: ContactsView
  },
  {
    path: '/support',
    name: 'support',
    component: SupportView
  },
  {
    path: '/results/resultinfo',
    name: 'resultinfo',
    component: ResultInfoView,
    beforeEnter: CancelNavigationIfUserNotLogged,
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignUpView,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/resetpassword',
    name: 'resetpassword',
    component: ResetPasswordView,
    beforeEnter: GuardIsUserLogged
  },
  {
    path: '/email/confirmation/:hash',
    name: 'emailconfirmation',
    component: EmailConfirmationView
  },
  {
    path: '/rate/:bookingId/:token/:rating',
    name: 'rating',
    component: RatingView
  },
  {
    path: '/results',
    name: 'results',
    component: ResultsView
  },
  {
    path: '/dashboardstatistics',
    name: 'dashboardstatistics',
    component: DashboardStatisticsView,
    beforeEnter: GuardIsCompanyAdminLogged,
  },
  {
    path: '/dashboardvehicles',
    name: 'dashboardvehicles',
    component: DashboardVehiclesView,
    beforeEnter: GuardIsCompanyAdminLogged,
  },
  {
    path: '/dashboardbookings',
    name: 'dashboardbookings',
    component: DashboardBookingsView,
    beforeEnter: GuardIsCompanyAdminLogged,
  },
  {
    path: '/dashboardcalendar',
    name: 'dashboardcalendar',
    component: DashboardCalendarView,
    beforeEnter: GuardIsCompanyAdminLogged,
  },
  {
    path: '/mybookings',
    name: 'mybookings',
    component: MyBookingsView,
    beforeEnter: GuardIsUserLogged,
  },
  {
    path: '/userprofile',
    name: 'userprofile',
    component: UserProfileView
  },
  // {
  //   path: '/workinprogress',
  //   name: 'workinprogress',
  //   component: WorkInProgressView
  // },
  {
    path: '/companysettings',
    name: 'companysettings',
    component: CompanySettingsView,
    beforeEnter: GuardIsCompanyAdminLogged,
  },

  // Lasciare ultimo per gestire errori
  {
    path: '/:catchAll(.*)',
    name: 'notfound',
    component: NotFoundView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to, from, next) => {
//   if (to.fullPath === '/workinprogress') {
//     return next();
//   } else if (window.location.href.includes('localhost') || window.location.href.includes('dev.ambubook.com')) {
//     return next();
//   } else {
//     return next('/workinprogress');
//   }
// })

function GuardIsUserLogged(to, from, next) {
  const jwt = Cookies.get('token');
  if (jwt) {
    API.UserAPI.IsUserLogged(jwt).then((resp) => {
      if (resp.data) {
        return next();
      } else {
        return next('/');
      }
    }).catch((err) => {
      return next('/');
    });
  } else {
    return next('/');
  }
}

function CancelNavigationIfUserNotLogged(to, from, next) {
  const jwt = Cookies.get('token');
  if (jwt) {
    API.UserAPI.IsUserLogged(jwt).then((resp) => {
      if (resp.data) {
        return next();
      } else {
        return next(false);
      }
    }).catch((err) => {
      return next(false);
    });
  } else {
    return next(false);
  }
}

function GuardIsCompanyAdminLogged(to, from, next) {
  const jwt = Cookies.get('token');
  const userType = Cookies.get('userType');
  if (jwt && (userType == 2 || userType == 1)) {
    console.log('GuardIsCompanyAdminLogged');
    API.UserAPI.IsCompanyAdminLogged(jwt).then((resp) => {
      if (resp.data) {
        return next();
      } else {
        return next('/');
      }
    }).catch((err) => {
      return next('/');
    });
  } else {
    return next('/');
  }
}

function GuardIsAdminLogged(to, from, next) {
  const jwt = Cookies.get('token');
  if (jwt) {
    API.UserAPI.IsAdminLogged(jwt).then((resp) => {
      if (resp.data) {
        return next();
      } else {
        return next('/login');
      }
    }).catch((err) => {
      return next('/login');
    });
  } else {
    return next('/login');
  }
}

export default router
