<template>
    <div style="padding-top: 15px;">
        <span class="p-float-label" style="margin: 15px;">
            <Dropdown  v-model="TypeOption" inputId="TypeOption" :options="TypeOptions" optionLabel="name" optionValue="code"/>
            <label for="TypeOption">Tipo servizio</label> 
        </span>
        <div class="waitingTimeInput">
            <label for="waitingTimeInput" class="block mb-2 labelWaitingTime"> Tempo di attesa </label>
            <InputNumber ref="waitingTimeInput" :disabled="TypeOption != 2" v-model="WaitingTime" inputId="waitingTimeInput" showButtons buttonLayout="horizontal" :min="30" :max="180" :step="30" suffix=" minuti">
                <template #incrementbuttonicon>
                <span class="pi pi-plus" />
                </template>
                <template #decrementbuttonicon>
                <span class="pi pi-minus" />
                </template>
            </InputNumber>
            </div>
        <vue-google-autocomplete
        ref="startAddressSimulation"
        id="googleStartAddressSimulation"
        :fields="['address_components', 'formatted_address', 'geometry']"
        classname="form-control"
        placeholder="Partenza"
        v-on:placechanged="getStartAddressData"
        v-on:no-results-found="getStartAddressData"
        country="it"
        class="googleAutocomplete"/>

        <vue-google-autocomplete
        ref="arriveAddressSimulation"
        id="googleArriveAddressSimulation"
        :fields="['address_components', 'formatted_address', 'geometry']"
        classname="form-control"
        placeholder="Arrivo"
        v-on:placechanged="getArriveAddressData"
        v-on:no-results-found="getArriveAddressData"
        country="it"
        class="googleAutocomplete"/>

        <br>
        <br>
        <div class="flex justify-content-center">
            <Button label="Simula" :loading="simulating" rounded @click="triggerSimulation" />
        </div>
    </div>
    <br>
    <p class="text-2xl font-semibold text-center">Costo del viaggio:</p>
    <p class="text-3xl font-semibold text-center">{{ simulatedPrice.price ? simulatedPrice.price : '-/-' }} €</p>
    <p class="text-2xl font-semibold text-center">Distanza:</p>
    <p class="text-3xl font-semibold text-center">{{ simulatedPrice.distance ? simulatedPrice.distance : '-/-' }} KM</p>
    <Toast ref="toast" position="bottom-right" style="right:0px; width: -webkit-fill-available; margin: 10px; max-width: 500px;"/>
</template>

<script>
import API from '@/api/API';
import Cookies from 'js-cookie';

export default {
    name: "SimulatePrice",
    props: {
        serviceId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            TypeOptions: [
                { name: 'Trasporto', code: 1 },
                { name: 'Visita', code: 2 },
                { name: 'Dimissioni', code: 3 },
            ],

            TypeOption: null,
            startAddress: null,
            arriveAddress: null,
            WaitingTime: 30,
            simulatedPrice: 0,

            simulating: false
        }
    },
    methods: {
        getArriveAddressData: function (addressData, placeResultData, id) {
            if (placeResultData === "googleArriveAddress") {
                this.arriveAddress = null;
            } else {
                this.arriveAddress = addressData;
                console.log(this.arriveAddress);
            }
        },

        getStartAddressData: function (addressData, placeResultData, id) {
            if (placeResultData === "googleStartAddressSimulate")  {
                this.startAddress = null;
            } else {
                this.startAddress = addressData;
            }
        },

        triggerSimulation() {
            if (!this.startAddress || !this.arriveAddress) {
                this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: "Inserire un indirizzo di partenza ed arrivo!",life: 3000});
                return;
            } else if(this.TypeOption == null) {
                this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: "Selezionare un tipo di servizio!",life: 3000});
                return;
            } else if (this.TypeOption == 2 && this.WaitingTime < 30) {
                this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: "Il tempo di attesa minimo è di 30 minuti!",life: 3000});
                return;
            }
            
            if (this.isValid()) {
                const jwt = Cookies.get('token');
                
                this.simulating = true;
                API.ServiceAPI.SimulatePrice(jwt, this.serviceId, {
                    fromLocation: {
                        lon: this.startAddress.longitude,
                        lat: this.startAddress.latitude
                    },
                    toLocation: {
                        lon: this.arriveAddress.longitude,
                        lat: this.arriveAddress.latitude
                    },
                    serviceType: this.TypeOption,
                    waitingTime: this.WaitingTime
                }).then(resp => {
                    this.simulatedPrice = resp.data;
                }).catch(err => {
                    console.error(err);
                }).finally(() => {
                    this.simulating = false;
                });
            }
        },

        isValid() {
            return this.startAddress && this.arriveAddress && this.TypeOption;
        }
    }
};
</script>

<style scoped>
.googleAutocomplete{
    font-family: var(--font-family);
    /*font-feature-settings: var(--font-feature-settings, normal);*/
    font-size: 1rem;
    color: #4b5563;
    background: #ffffff;
    padding: 0.75rem 0.75rem;
    border: 1px solid #d1d5db;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 6px;
    width: -webkit-fill-available;
    margin: 15px;
    }

.pac-container { 
    z-index: 10000 !important;
}

.p-dropdown.p-component.p-inputwrapper {
    width: -webkit-fill-available !important;
    margin: 5px;
}

.waitingTimeInput{
  position: relative;
  margin: 15px;
  text-align: center;
}

</style>