<template>
    <div class="ResetPassword">
        <div v-if="isOnDesktopDisplay" class="SidePanel" ref="SidePanel"></div>
        <div v-if="isOnDesktopDisplay || isOnTabletDisplay" class="SidePanel2" ref="SidePanel2"></div>
        <div v-if="isOnDesktopDisplay || isOnTabletDisplay" class="SidePanel3" ref="SidePanel3"></div>
        <ResetPasswordForm/>
    </div>
</template>

<script>
import { store } from '@/store';
import ResetPasswordForm from '../components/ResetPasswordForm.vue'
export default {
    name: 'ResetPasswordView',
    components: {
        ResetPasswordForm,
    },
    data() {
        return {
          isOnDesktopDisplay: window.innerWidth > 1280,
          isOnTabletDisplay: window.innerWidth < 1280 && window.innerWidth > 500,
        }
    },
    computed: {
      IsResetPasswordVisible(){
        return store.getters.IsResetPasswordVisible;
      },
      IsLoggedIn() {
        return store.getters.IsLoggedIn;
      },
    },
    mounted() {
      if(this.IsLoggedIn)
      {
        store.commit('LoadUserFromCookies');
        setTimeout(() => {
        if(this.isOnDesktopDisplay){
          this.$refs.SidePanel.style.opacity = '75%';
          this.$refs.SidePanel2.style.opacity = '60%';
          }
        }, 100);
      }
      else
      { 
        this.$router.push('/');
      }
    },
} 
</script>

<style scoped>
  .ResetPassword{
    position: relative;
    z-index: -2;
    margin:0px;
    text-align: center;
    display: flex;;
    height: 100vh;
    align-items: center;
  }
  .SidePanel{
    background-color: #EF8354;
    height: -webkit-fill-available;
    width: 50%;
    margin-bottom: 200px;
    border-radius: 0 0 1000px 300px;
    box-shadow: -32px -20px 50px -18px #dddddd;
    transition: opacity 1s ease-out;
    opacity: 0;
  }
  .SidePanel h1{
    color: white;
  }
  .SidePanel2{
    background-color: #4F5D75;
    background-color: #bfc0c0;
    height: -webkit-fill-available;
    width: 60%;
    position: absolute;
    z-index: -1;
    border-radius: 0 1000px 0px 0;
    box-shadow: -32px -20px 50px -18px #dddddd;
    transition: opacity 1s ease-out;
    opacity: 0;
  }
  .titleContainer{
    margin-top: 25%;
    font-size: xx-large;
    text-align: left;
    padding-left: 10%;
  }
  .titleContainer button{
    background-color: #FFFFFF;
    border-color: #FFFFFF;
    color: #EF8354;
  }
  .titleContainer a {
    text-decoration: none;
  }
  :deep(.p-button:focus){
    border-color: #EF8354;
    box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
  }
  @media (max-width: 1280px) and (min-width: 501px) {
    .ResetPassword{
      height: 100vh;
      align-items: center;
      display: block;
    }  
    .SidePanel{
      background-color: #EF8354;
      height: auto;
      opacity: 100%;
      width: auto;
      border-radius: 0px;
      padding: 5px;
      margin-bottom: 0px;
      justify-content: center;
      text-align: center;
    }
    .titleContainer{
      font-size: x-large;
      text-align: center;
      margin: 0px auto;
      padding: 30px;
    }
    .SidePanel2{
      height: -webkit-fill-available;
      width: 70%;
      position: absolute;
      background-color: #bfc0c0;
      z-index: -1;
      float: right;
      position: absolute;
      right: 0;
      border-radius: 400px 0 0 800px;
      transition: opacity 1s ease-out;
      margin-bottom: 10%;
      opacity: 0.4;
      box-shadow: -32px -20px 50px -18px rgba(0,0,0,0.1);
    }
    .SidePanel3{
      background-color: #ef8354;
      height: -webkit-fill-available;
      width: 100%;
      position: absolute;
      z-index: -1;
      position: absolute;
      left: 0;
      border-radius: 300px 1000px 500px 0;
      transition: opacity 1s ease-out;
      opacity: 0.2;
      box-shadow: -32px -20px 50px -18px #dddddd;
      margin-top: 20%;
      width: 95%;
    }
  }
  @media (max-width: 500px) {
    .ResetPassword{
      align-items: center;
      height: auto;
      display: block;
    }  
    .SidePanel{
      background-color: #EF8354;
      height: auto;
      opacity: 100%;
      width: auto;
      border-radius: 0px;
      padding: 5px;
      margin-bottom: 0px;
      justify-content: center;
      text-align: center;
    }
    .titleContainer{
      font-size: smaller;
      text-align: center;
      margin: 0px auto;
      padding: 10px;
    }
  }
</style>