export default {
    removeCAPandCountry(str) {
        var regex = /^(.*?, \d+), (\d{5}) (.*), .*$/;
        return str.replace(regex, "$1, $3");
    },
    trimLongAddress(str) {
        // If the address is too long, trim it to 25 characters
        if (str.length > 25) {
            return str.slice(0, 25) + "...";
        }
        return str;
    }
};