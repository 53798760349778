<template>
    <div class="cardContainer">
        <h1>Immagini azienda</h1>
        <p>Carica le immagini della tua azienda e dei tuoi mezzi</p>
        <br>
        <div class="card">
            <FileUpload name="images" :url="urlApi" @upload="onUpload($event)" :multiple="true" accept="image/*"
            :maxFileSize="10000000" @before-send="addJWT" :file-limit="10" @select="onSelectedFiles" @error="onError">
                <template #empty>
                    <p>Trascina qui le immagini che vuoi caricare, oppure usa il tasto "Scegli file"</p>
                </template>
            </FileUpload>
            <br><br>
            <span class="text-md">Foto caricate</span>
            <br><br>
            <div class="card flex justify-content-center">
                <Galleria v-model:activeIndex="activeIndex" v-model:visible="displayCustom" :value="images" :responsiveOptions="responsiveOptions" :numVisible="7"
                    containerStyle="max-width: 850px" :circular="true" :fullScreen="true" :showItemNavigators="true" :showThumbnails="false">
                    <template #item="slotProps">
                        <img :src="slotProps.item.itemImageSrc" :alt="slotProps.item.alt" style="width: 100%; display: block" />
                    </template>
                    <template #thumbnail="slotProps">
                        <img :src="slotProps.item.thumbnailImageSrc" :alt="slotProps.item.alt" style="display: block" />
                    </template>
                </Galleria>
    
                <div v-if="images" class="grid" style="max-width: 100%; justify-content: center;">
                    <div v-for="(image, index) of images" :key="index" class="xl:col-4 md:col-12 px-2" style="width: auto; margin: 10px;">
                        <img class="cropImages" :src="image.thumbnailImageSrc" :alt="image.alt" style="cursor: pointer; max-height: 9rem; border-radius: 10px;" @click="imageClick(index)" />
                        <br>
                        <Button label="Elimina" style="font-size: 0.8rem; margin-top: 5px;" @click="deleteImage(index)" severity="danger" rounded />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import config from '@/config';
import Cookies from 'js-cookie';
import { usePrimeVue } from 'primevue/config';
import API from '@/api/API';

export default {
    name: 'CompanyImages',
    components: {
    },
    data() {
        return {
            files: [],
            totalSize: 0,
            totalSizePercent: 0,
            urlApi: "",

            activeIndex: 0,
            displayCustom: false,
            images: [],
            responsiveOptions: [
                {
                    breakpoint: '1024px',
                    numVisible: 5
                },
                {
                    breakpoint: '768px',
                    numVisible: 3
                },
                {
                    breakpoint: '560px',
                    numVisible: 1
                }
            ],
                

            primevue: usePrimeVue()
        }
    },
    mounted() {
        if (Cookies.get('token') === undefined) {
            this.$router.push({ name: 'Login' });
        } else {
            this.urlApi = config.backendIP + '/api/v1/company/image/' + Cookies.get('companyId');
        }

        this.reloadImages();
    },
    methods: {
        imageClick(index) {
            this.activeIndex = index;
            this.displayCustom = true;
        },
        addJWT(xhr) {
            if (Cookies.get('token') === undefined) {
                this.$router.push({ name : 'Login' });
            } else {
                xhr.xhr.withCredentials = true;
                xhr.xhr.setRequestHeader('Authorization', Cookies.get('token'));
            }
        },
        onUpload() {
            this.$toast.add({ severity: 'info', summary: 'Success', detail: 'File caricato con successo!', life: 3000 });

            this.reloadImages();
        },
        deleteImage(index) {
            const jwt = Cookies.get('token');
            if (jwt === undefined) {
                this.$router.push({ name: 'Login' });
            }

            const url = this.images[index].itemImageSrc;
            const name = url.substring(url.lastIndexOf('/') + 1)

            API.CompanyAPI.DeleteCompanyImage(Cookies.get('companyId'), name, jwt).then((response) => {
                this.$toast.add({ severity: 'info', summary: 'Success', detail: 'File eliminato con successo!', life: 3000 });

                this.reloadImages();
            }).catch((error) => {
                this.$toast.add({ severity: 'error', summary: 'Errore', detail: 'Errore durante l\'eliminazione del file!', life: 3000 });
                console.log(error);
            });
        },
        reloadImages() {
            const jwt = Cookies.get('token');
            if (jwt === undefined) {
                this.$router.push({ name: 'Login' });
            }

            API.CompanyAPI.GetCompanyImages(Cookies.get('companyId'), jwt).then((response) => {
                this.images = [];
                for (let i = 0; i < response.data.length; i++) {
                    const url = response.data[i];
                    const name = url.substring(url.lastIndexOf('/') + 1)
                    this.images.push({
                        itemImageSrc: response.data[i],
                        thumbnailImageSrc: response.data[i],
                        alt: name
                    });
                }
            }).catch((error) => {
                console.log(error);
            });
        },
        onSelectedFiles(event) {
            for (let i = 0; i < event.files.length; i++) {
                const file = event.files[i];
                if (file.size > 3000000) {
                    this.$toast.add({ severity: 'error', summary: 'Errore', detail: 'Dimensione massima consentita 3MB. Il file ' + file.name + ' non verrà caricato!', life: 5000 });
                }
            }
        },
        onError(event) {
            this.$toast.add({ severity: 'error', summary: 'Errore', detail: 'Errore durante il caricamento dei file!', life: 5000 });
        }
    }
}

</script>

<style scoped>
.cardContainer{
    padding: 70px;
    border-radius: 50px;
    box-shadow: 0px 0px 50px 7px rgba(0,0,0,0.1);
    max-width: 100%;
    position: relative;
    /* margin: 200px auto; */
    /* margin-top: 50px; */
    position: relative;
    height: fit-content;
    text-align: center;
    background-color: white;
}

.cropImages {
    object-fit: cover;
    width: 160px;
    height: 160px;
}
@media (max-width: 500px){
    .cardContainer{
        padding: 20px;
        border-radius: 20px;
        margin: 10px;
    }
}
</style>