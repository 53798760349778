<template>
  <div class="logInContainer">
    <div class="containerHeader"> 
      <i class="pi pi-user" style="font-size: 2.5rem; color: #EF8354;"></i>
      <h1 v-if="!IsInPopUpMode">Bentornato su AmbuBook.</h1>
      <h1 v-if="IsInPopUpMode">Manca solo un passaggio!</h1>
      <p>Accedi alla piattaforma.</p>
      <p v-if="debug">User: a@a.it - Password: Aa123456!</p>
    </div>
    <div class="containerContent">
      <span v-for="(field, index) in CustomerFormFields" :key="index" class="p-float-label p-input-icon-right">
        <IconField iconPosition="right">
        <InputIcon :class="field.iconClass"></InputIcon>
          <InputText v-if="field.type !== 'password'" v-model="$data[field.id]" :loading="loading" />
          <Password v-else v-model="$data[field.id]" toggleMask :feedback="false" :loading="loading"/>
        </IconField>
        <label :for="field.id">{{ field.label }}</label>
      </span>
    </div>
    <div class="containerFooter"> 
      <span>
        <Button label="Accedi" :loading="loading" @click="recaptcha" rounded />
        <div class="checkBoxContainer"> 
          <Checkbox v-model="SaveLogIn" :binary="true" />
          <p>Ricordami</p>
          <!-- <i v-if="loading" class="pi pi-spin pi-spinner" /> -->
        </div>
        <div class="resetPasswordContainer"> 
          <p v-if="!IsInPopUpMode" @click="ViewResetPasswordForm">Password dimenticata?</p>
          <p v-if="IsInPopUpMode" @click="ViewSignUpPage">Non sei registrato?</p>
          <p v-if="IsInPopUpMode" @click="ViewResetPasswordPage">Password dimenticata?</p>
        </div>
      </span>
      <small class="disclaimer-google">Questo sito è protetto da reCAPTCHA. <br>
        Si applicano 
        <a style="text-decoration: none; color: #EF8354;" href="https://policies.google.com/privacy">Privacy Policy</a> e
        <a style="text-decoration: none; color: #EF8354;" href="https://policies.google.com/terms">Termini di servizio</a> di Google.
      </small>
    </div>
    <Toast ref="toast" position="bottom-right" style="right:0px; width: -webkit-fill-available; margin: 10px; max-width: 500px;"/>
  </div>
</template>

<script>
import API from '@/api/API';
import { store } from '../store';
import { useReCaptcha } from "vue-recaptcha-v3";

export default {
  name: 'LogInForm',
  data(){
      return{
        //DEBUG
        debug:false,
        forceNotConfirmedUser: false,

        //Generic Variables
        GenericEmail:'',
        GenericPassword:'',
        SaveLogIn: false,
        loading: false,

        //Popup Mode
        //Questa variabile permette di modificare il comportamento del componente nel caso vengan richiamato dentro un dialog popup
        IsInPopUpMode: this.$props.IsInPopUpMode,

        //Input Dynamic Creation
        CustomerFormFields: [
        { id: 'GenericEmail', label: 'Email', iconClass: 'pi pi-envelope' },
        { id: 'GenericPassword', label: 'Password', type: 'password' }
        ],
      }
  },
  props: ['IsInPopUpMode'],
  methods: {
    async recaptcha() {
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('search')

      this.handleLogin(token);
    },
    async handleLogin(recaptchaToken) {
      try 
      {
        //Inizio a mostrare il caricamento
        this.loading = true;
        
        if(this.debug)
        {
          console.log('LoggedIn: ' + store.getters.IsLoggedIn);
          console.log('Email: ' + this.GenericEmail);
          console.log('password: ' + this.GenericPassword);
        }

        //Verifica dei dati inseriti dall'utente
        if(this.GenericEmail == '' || this.GenericPassword == '')
        {
          this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'Mail o Password non inserite correttamente.',life: 2500});
        }
        else
        {
          const response = await API.UserAPI.Login(this.GenericEmail, this.GenericPassword, this.SaveLogIn, recaptchaToken);
          if (response.status === 200) 
          {
            //Salvataggio risposta API
            const { user, token } = response.data;

            if(this.debug){console.log('DEBUG - User email is confirmed: ' + user.mailConfirmed.toString())}
            if(user.mailConfirmed == true || this.forceNotConfirmedUser)
            {
              this.$refs.toast.add({severity:'success', summary: 'Credenziali valide', detail: 'Benvenuto su AmbuBook',life: 1000});

              //Scrittura sullo Store dell'utente loggato
              store.commit('SetLoggedInUser', {user: user, token: token, remember: this.SaveLogIn});

              //Waiting per mostrare il toast di conferma
              await new Promise(resolve => setTimeout(resolve, 1500));

              //Debug purpose
              if(this.debug){
                console.log('Token: ' + store.state._token);
                console.log('UserName: ' + store.state._loggedInUser.firstName)
              }

              //Imposto l'ambiente per utente loggato
              store.commit('LogIn');

              if(this.IsInPopUpMode){
                window.location.reload();
              }
              else{
                const jwt = store.state._token;
                API.UserAPI.IsCompanyAdminLogged(jwt).then((resp) => {
                  if (resp.data) {
                    //Reindirizzamento a dashboard in quanto è amministratore di azienda
                    const newUrl = window.location.origin + '/dashboardstatistics';// dashboardstatistics;
                    window.location.assign(newUrl);
                    //this.$router.replace('/dashboardstatistics');
                  } else {
                    //Reindirizzamento a pagina principale
                    this.$router.replace('/');
                  }
                }).catch((err) => {
                  //Reindirizzamento a pagina principale
                  this.$router.replace('/');
                });
              }
            }
            else
            {
              this.$refs.toast.add({severity:'warn', summary: 'Attenzione', detail: 'Il tuo utente non è ancora stato verificato! Controlla la tua mail e conferma la registrazione del tuo utente.',life: 5000});
            }
          }
        }
      } 
      catch (error) 
      {
        this.ManageError(error);
      }
      finally
      {
        this.loading=false;
      }
    },

    ManageError(error){
      if (error.response && error.response.status === 401) 
        {
          this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'Credenziali non valide',life: 2500});
        } 
        else 
        {
          this.$refs.toast.add({severity:'error', summary: 'Qualcosa non sta funzionando...', detail: 'Ci scusiamo per il disagio, contatta il supporto per segnalare.',life: 5000});
        }
    },

    ViewResetPasswordForm(){
      store.commit('SwitchResetLogin');
      if(this.debug){
        console.log('DEBUG - IsResetPasswordFormVisible: ' + store.getters.IsResetPasswordVisible);
      }
    },

    ViewSignUpPage(){
      this.$router.replace('/signup');
    },

    ViewResetPasswordPage(){
      store.commit('SwitchResetLogin');
      this.$router.replace('/login');
    },

  },
}
</script>


<style scoped>
.logInContainer{
    padding: 70px;
    border-radius: 80px;
    box-shadow: 0px 0px 50px 7px rgba(0,0,0,0.1);
    max-width: 700px;
    position: relative;
    margin: 200px auto;
    margin-top: 50px;
    position: relative;
    height: fit-content;
    text-align: center;
    background-color: white;
}
.logInContainer .p-float-label{
    margin: 15px;
    width: max-content;
}
.containerHeader{
  margin-bottom: 25px;
}
.containerContent{
  position: relative;
  align-items: center;
  display: inline-grid;
  margin: 15px;
}
.containerFooter{
  margin-top: 25px;
}
.checkBoxContainer{
  margin-top: 20px;
  font-size: medium;
  color: gray;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  position: relative;
}
.checkBoxContainer p{
  margin: 10px;
}
.p-button{
  background-color: #EF8354;
  border-color: #EF8354;
  width: 150px;
}
:deep(.p-checkbox-box:hover){
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
:deep(.p-checkbox .p-checkbox-box.p-highlight){
  background:#EF8354;
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
:deep(.p-checkbox-box:focus){
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
.p-button:hover{
  border-color: #bc6844;
  background-color: #bc6844;
}

:deep(.p-inputtext){
  width: 300px;
}
:deep(.p-inputtext:hover){
    border-color: #EF8354;
}
:deep(.p-inputtext:focus){
    border-color: #EF8354;
    box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
:deep(.p-button:focus){
    border-color: #EF8354;
    box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
.resetPasswordContainer p{
  color: grey;
  cursor: pointer;
  transition: 0.5s;
}
.resetPasswordContainer p:hover{
  color: #EF8354;
  cursor: pointer;
  transition: 0.5s;
}

@media (max-width: 1280px) and (min-width: 501px) {
    .logInContainer{
      zoom:100%;
    }
    .containerContent{
      margin: 0px;
    }
    .logInContainer .p-float-label{
      margin: 20px;
      width: max-content;
    }
  }
  @media (max-width: 500px), (max-height: 900px) {
    .logInContainer{
      padding: 10px;
      border-radius: 20px;
      margin: 5px auto;
      width: -webkit-fill-available;
    }
    .containerHeader{
      margin: 10px;
      margin-top: 30px;
    }
    .containerContent{
      margin: 0px;
    }
    .containerFooter{
      margin: 10px;
      margin-bottom: 30px;
    }
    :deep(.p-inputtext){
      width: 300px;
      height: 60px;
    }
  }

  .disclaimer-google{
    font-size: 0.8rem;
    color: grey;
    text-align: center;
    margin-top: 20px;
  }
</style>