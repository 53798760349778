<template>
  <Dialog v-model:visible="UserDataModifyDialogvisible" modal header="Contattaci" :style="{ width: '50vw' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <p class="m-0">
        Per modificare i tuoi dati anagrafici ti preghiamo di contattare il nostro supporto alla mail info@ambubook.com. Ti preghiamo 
        di indicare nella mail quali campi sono errati in modo che un nostro operatore possa effettuare le opportune verifiche e soddisfare la 
        tua richiesta. Grazie!
      </p>
  </Dialog>
  <div class="UserProfileContainer">
    <div class="containerHeader"> 
      <i class="pi pi-user" style="font-size: 2.5rem; color: #EF8354;"></i>
      <h1>Dati utente</h1>
      <p>Riepilogo dei tuoi dati anagrafici che ci hai fornito.</p>
    </div>
    <div class="containerContent">
      <div class="containerContentSingleData">
        <h3>Nome: </h3>
        <p>{{ this.loggedInFirstName }}</p>
      </div>
      <div class="containerContentSingleData">
        <h3>Cognome: </h3>
        <p>{{ this.loggedInLastName }}</p>
      </div>
      <div class="containerContentSingleData">
        <h3>Email: </h3>
        <p>{{ this.loggedInEmail }}</p>
      </div>
      <div class="containerContentSingleData">
        <h3>Telefono: </h3>
        <p>{{ this.loggedInPhone }}</p>
      </div>
      <div class="containerContentSingleData">
        <h3>Sesso: </h3>
        <p>{{ this.loggedInSex }}</p>
      </div>
      <div class="containerContentSingleData">
        <h3>Data di nascita: </h3>
        <p>{{ this.loggedInBirthDate}}</p>
      </div>

    </div>
    <div class="containerFooter"> 
      <span>
        <Button label="Modifica" @click="OpenUserDataModifyDialog()" rounded />
      </span>
    </div>
    <Toast ref="toast" position="bottom-right" style="right:0px; width: -webkit-fill-available; margin: 10px; max-width: 500px;"/>
  </div>
</template>

<script>
import API from '@/api/API';
import { store } from '../store';
import Cookies from 'js-cookie';
// @ts-ignore
import { stringifyQuery } from 'vue-router';

export default {
  name: 'UserProfileForm',
  data(){
      return{
        //DEBUG
        debug: false,

        //Dialog User Data Modify
        UserDataModifyDialogvisible: false,
      }
  },
  methods: {
    OpenUserDataModifyDialog(){
      this.UserDataModifyDialogvisible = true;
    }
  },
  computed: {
      loggedInFirstName() {
        return store.state._loggedInUser.firstName;
      },
      loggedInLastName() {
        return store.state._loggedInUser.lastName;
      },
      loggedInEmail() {
        return store.state._loggedInUser.email;
      },
      loggedInPhone() {
        return store.state._loggedInUser.telephone;
      },
      loggedInSex() {
        if(store.state._loggedInUser.sex == 1){
          return 'Uomo';
        }else if(store.state._loggedInUser.sex == 2){
          return 'Donna';
        }else{
          return 'Errore lettura dato';
        }
      },
      loggedInBirthDate() {
        if (!store.state._loggedInUser.birthDate) return '';
        const date = new Date(store.state._loggedInUser.birthDate);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
      },
  },
  filters: {
    formatDate: function(value) {
      console.log('Value: ' + value);
      if (!value) return '';
      const date = new Date(value);
      console.log('Date: ' + date.toString());
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      console.log('day: ' + day);
      console.log('month: ' + month);
      console.log('year: ' + year);
      return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
    }
  }
}
</script>


<style scoped>
.UserProfileContainer{
    padding: 70px;
    border-radius: 80px;
    box-shadow: 0px 0px 50px 7px rgba(0,0,0,0.1);
    max-width: 700px;
    position: relative;
    margin: 200px auto;
    margin-top: 50px;
    position: relative;
    height: fit-content;
    text-align: center;
    background-color: white;
}
.UserProfileContainer .p-float-label{
    margin: 15px;
    width: max-content;
}
.containerHeader{
  margin-bottom: 25px;
}
.containerContent{
  position: relative;
  align-items: center;
  display: inline-grid;
  margin: 15px;
  margin-top: 0px;
}
.containerContentSingleData{
  display: flex;
  align-items: baseline;
  position: relative;
  height: 3rem;
}
.containerContentSingleData h3{
  margin-right: 5px;
}
.containerFooter{
  margin-top: 25px;
}
.checkBoxContainer{
  margin-top: 20px;
  font-size: medium;
  color: gray;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  position: relative;
}
.checkBoxContainer p{
  margin: 10px;
}
.p-button{
  background-color: #EF8354;
  border-color: #EF8354;
  width: 150px;
}
:deep(.p-checkbox-box:hover){
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
:deep(.p-checkbox .p-checkbox-box.p-highlight){
  background:#EF8354;
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
:deep(.p-checkbox-box:focus){
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
.p-button:hover{
  border-color: #bc6844;
  background-color: #bc6844;
}

:deep(.p-inputtext){
  width: 300px;
}
:deep(.p-inputtext:hover){
    border-color: #EF8354;
}
:deep(.p-inputtext:focus){
    border-color: #EF8354;
    box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
:deep(.p-button:focus){
    border-color: #EF8354;
    box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
.containerReturnBackButton span i{
  font-size: 0.7rem; 
  color: grey; 
  margin-top: 20px;
  margin-right: 10px;
  transition: 0.5s;
}
.containerReturnBackButton span i:hover{
  cursor: pointer;
}
.containerReturnBackButton{
  position: relative;
  display: flex;
  display: inline-flex;
  color: grey;
  transition: 0.5s;
  font-size: 1rem;
  align-items: baseline;
}
.containerReturnBackButton:hover{
  cursor: pointer;
}


@media (max-width: 1280px) and (min-width: 501px) {
    .UserProfileContainer{
      zoom:100%;
    }
    .containerContent{
      margin: 0px;
    }
    .UserProfileContainer .p-float-label{
      margin: 20px;
      width: max-content;
    }
  }
  @media (max-width: 500px) {
    .UserProfileContainer{
      padding: 10px;
      border-radius: 20px;
      margin: 10px;
    }
    .containerHeader{
      margin: 10px;
      margin-top: 30px;
    }
    .containerContent{
      margin: 0px;
    }
    .containerFooter{
      margin: 10px;
      margin-top: 50px;
    }
    :deep(.p-inputtext){
      width: 300px;
      height: 60px;
    }
    .containerReturnBackButton{
      margin-bottom: 10px;
    }
  }

</style>