<!--
VERSIONING:
1.0 Madalin 01/11/2023: First draft + added Navbar component
1.1 Madalin 02/11/2023: Navbar responsive + Added PrimeVue
1.1 Madalin 04/11/2023: Optimized Navbar, Added Footer
1.2 Madalin 05/11/2023: Optimized Navbar, Added howItWorks Page, added PrimeFlex
1.3 Madalin 12/11/2023: Finished HowItWorksView and changed all images paths, now is in specific folder for images in assets
1.4 Madalin 18/11/2023: Added SignInView with CustomerSignInForm + resposive
1.5 Madalin 18/11/2023: Refactored Code and Added ODV SignIn
1.6 Madalin 25/11/2023: Completed Login, Added Store
1.7 Madalin 27/11/2023: Managed cookies on login and added username in the navbar
1.8 Madalin 05/12/2023: Customer User creation + handled errors
1.9 Madalin 08/12/2023: Added blob backgrounds
2.0 Madalin 09/12/2023: Added change password
2.1 Madalin 09/12/2023: Corrected change and reset password
2.2 Madalin 22/01/2024: Added Home and SearchBox
2.3 Madalin 01/02/2024: Added Company and CompanyAdmin SignUp
2.4 Madalin 03/02/2024: Added Company signUp phone number check
                        Added Confirm Password check
                        Added buttons loading when calling api
                        Removed email from reset password and added store getter for email
                        Corrected Reset Password and added Token
                        Corrected Display Format issue
                        UTC fixed for signup and redirect to home after password reset
2.5 Madalin 12/02/2024: Added Result component and ResultsView page
2.6 Madalin 13/02/2024: Connected Backend search with frontend + Skeleton
2.7 Madalin 14/02/2024: Results and ResultSkeleton Responsivness
2.8 Madalin 19/02/2024: Graphic bugs fixed
2.9 Madalin 24/02/2024: Minimized SearchBox and fixed SignUpView text
3.0 Madalin 25/02/2024: Login on booking operation
3.1 Madalin 26/02/2024: Fixed Primevue 3.49 integration
3.2 Madalin 27/02/2024: SearchBox - Added switch for waiting and redesigned waiting section
                        Results - Fixed SearchBox Width and background colors
                        Fixed Graphic Issues (Chrome and Safari)
3.3 Madalin 02/03/2024: Completed recover password form + connected to backend + errors management
3.4 Madalin 05/03/2024: Added ResultInfoView + PatientForm
3.5 Madalin 10/03/2024: Added ResultInfos component + responsive
3.6 Madalin 21/03/2024: Added Sidebar + Dashboard + responsive
3.7 Madalin 28/03/2024: Fixed Sidebar only for CompanyAdmin
                        Fixed Navbar user menù based on UserType
                        Fixed Page margin with sidebar + responsive + navbar statistic route
3.8 Madalin 30/03/2024: Improved Result Infos Graphics
3.9 Madalin 03/03/2024: Added Sidebar routings + highlight current route on sidebar
                        Created MyBookings view and added to normal user navbar menu
4.0 Madalin 04/04/2024: Corrected SearchBox Graphics + responsive
4.1 Madalin 10/04/2024: Added User Profile +  responsive
4.2 Madalin 15/04/2024: Added VeichleList component + responsive
                        Improved booking table graphics
4.3 Madalin 12/05/2024: Added VehicleForm working + responsive
                        Added tooltips for VehicleForm
4.4 Madalin 22/05/2024: Connected VehicleForm to VehicleList + API
4.5 Madalin 23/05/2024: Added Vehicle form Update and Delete vehicle
4.6 Madalin 28/05/2024: BookingInfo responsive
4.7 Madalin 11/06/2024: Added telephone tu cookies and user profile
4.8 Madalin 13/06/2024: Added ratings
4.9 Madalin 24/06/2024: Added steps for patient form
5.0 Madalin 07/07/2024: Added MyBookings management
5.1 Madalin 23/07/2024: Improved CompanySettings Graphic + responsive
5.2 Madalin 24/07/2024: Added Contact page + responsive
5.3 Madalin 02/08/2024: Added Company page + responsive

UTILS:
Compilazione Frontend: CD Frontend > Make Run
Compilazione Backend: CD Backend > export PATH=$PATH:$HOME/go/bin > Make Dockerize-dev
-->

<template>
    <div class="main-container">
      <DashboardSidebar v-if="showDashboardSidebar"></DashboardSidebar>
      <ConfirmDialog />
      <div class="fullPageContentExcludedSidebar" :style="{ 'margin-left': (showDashboardSidebar && !isOnMobileDisplay) ? '78px' : '0px' }">
        <div class="topApp" v-if="!isOnMobileDisplay"></div>
        <Navbar :logged-in="loggedIn"/>
        <router-view/>
        <Footer/>
      </div>
    </div>
</template>

<script>
import API from '@/api/API';
import { store } from './store';
import DashboardSidebar from './components/DashboardSidebar.vue';
export default {
  name: 'App',
  components:{
    DashboardSidebar
  },
  data(){
    return{
      showDashboardSidebar: false,
      isLoadingCompanyAdminStatus: true
    }
  },
  
  created(){
    store.commit('LoadUserFromCookies');

    //Questa watch serve a mostrare immediatamente la sidebar senza effettuare una ricarica
    this.$watch('$route', this.onRouteChange);

    //Questo richiamo al metodo serve per mostrare la sidebar in qualsiasi caso l'utente amministratore ricarichi la pagina
    this.checkCompanyAdminStatus();
  },

  watch: {
    $route(to, from) {
      this.scrollToTop();
    },
    loggedIn(newValue) {
      this.loggedIn = newValue;
    },
  },

  computed: {
    loggedIn() {
      return store.getters.IsLoggedIn;
    },
    isOnMobileDisplay() {
      return store.getters.IsOnMobileDisplay;
    },
  },

  methods: {
    scrollToTop() {
      const el = document.getElementById('app');
      // @ts-ignore
      el.scrollIntoView({ behavior: 'smooth' });
    },

    //Metodo che serve per verificare se l'utente che si è appena loggato sia amministratore di azienda o meno, questo serve
    //per mostrare la sidebar amministratore
    async checkCompanyAdminStatus() {
      const jwt = store.state._token;
      try {
        const resp = await API.UserAPI.IsCompanyAdminLogged(jwt);
        this.showDashboardSidebar = resp.data;
      } catch (err) {
        this.showDashboardSidebar = false;
      } finally {
        this.isLoadingCompanyAdminStatus = false;
      }
    },

    //Questo metodo permette di capire se è stato effettuato un movimento da una route qualsiasi alla route delle dashboardStatistics 
    //e di conseguenza richiama il metodo per verificare l'utente e mostrare la sidebar
    onRouteChange(newRoute, oldRoute) {
      if (newRoute.name === 'dashboardstatistics') {
        this.checkCompanyAdminStatus();
      }
    },

    // Rimuovo il watcher quando il componente viene distrutto
    beforeDestroy() {
      this.$off('$route', this.onRouteChange);
    },
  },
}
</script>

<style>
.main-container{
  position: relative;
  z-index: 0;
}
.topApp{
  height: 40px;
  margin-bottom: 40px;
}
.fullPageContentExcludedSidebar{
  position: relative;
  z-index: -1;
}

/*
Hide the reCAPTCHA badge
If you don't write at least the following text in your privacy policy, you are violating the Google reCAPTCHA Terms of Service:
"This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply."
*/
.grecaptcha-badge { 
    visibility: hidden !important;
}

.p-toast.p-component {
    z-index: 100000 !important;
}
</style>
