import axios from 'axios';
import config from '@/config';

export default {
    async GetLastBookings(jwt, companyId, period) {
        const response = await axios.post(`${config.backendIP}/api/v1/company/stats/last-bookings`, {
            companyId: companyId,
            period: period
        }, {
            headers: {
                Authorization: `${jwt}`
            }
        });
        return response;
    },
    async GetEarningsVsBookings(jwt, companyId, period) {
        const response = await axios.post(`${config.backendIP}/api/v1/company/stats/earnings`, {
            companyId: companyId,
            period: period
        }, {
            headers: {
                Authorization: `${jwt}`
            }
        });
        return response;
    }
};