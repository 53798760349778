import RecaptchaAPI from './RecaptchaAPI.js';
import UserAPI from './UserAPI.js';
import SearchAPI from './SearchAPI.js';
import ServiceAPI from './ServiceAPI.js';
import CompanyAPI from './CompanyAPI.js';
import BookingAPI from './BookingAPI.js';
import AvailabilityAPI from './AvailabilityAPI.js';
import POIsAPI from './POIsAPI.js';
import StatsAPI from './StatsAPI.js';

export default {
    RecaptchaAPI: {
        ...RecaptchaAPI
    },
    UserAPI: {
        ...UserAPI
    },
    SearchAPI: {
        ...SearchAPI
    },
    ServiceAPI: {
        ...ServiceAPI
    },
    CompanyAPI: {
        ...CompanyAPI
    },
    BookingAPI: {
        ...BookingAPI
    },
    AvailabilityAPI: {
        ...AvailabilityAPI
    },
    POIsAPI: {
        ...POIsAPI
    },
    StatsAPI: {
        ...StatsAPI
    }
};