<template>
  <div class="MyBookings">
    <h1>Le mie prenotazioni</h1>
    <div v-if="showResults">
      <div v-for="(result, index) in resultBookings" :key="index">
        <UserBooking :raw-item="result"
                :company-name="result.bookingNumber" 
                :start-address="result.fromLocation"
                :end-address="result.toLocation"
                :duration="formattedTime(result.estimatedTime.companyTotalTime)"
                :distance="result.totalDistance"
                :service-type="result.serviceType"
                :waiting-time="result.waitingTime"
                :price="result.price"
                :date-time-to-location="formattedDate(result.dateTimeToLocation)"
                :booking-state="result.bookingState"
                :booking-id="result.bookingId">
        </UserBooking>
      </div>
    </div>
    <div v-if="noResults && !showResults">
      <div class="p-d-flex p-jc-center" v-if="noResults">
        <div class="noresult-container">
          <br>
          <Text class="noresult-text">Non ci sono prenotazioni da visualizzare per ora</Text>
          <br>
          <br>
          <br>
          <Button label="Inizia la tua ricerca!" rounded @click="goToHome()"/>
        </div>
      </div>
    </div>
  </div>
  <Toast ref="toast" position="bottom-right" style="right:0px; width: -webkit-fill-available; margin: 10px; max-width: 500px;"/>
</template>

<script>
import UserBooking from '../components/UserBooking.vue'
import API from '@/api/UserAPI';
import Cookies from 'js-cookie';
import Helper from '@/helper/Helpers';
export default {
  name: 'MyBookingsView',
  data(){
    return{
      resultBookings: [],
      showResults: false,
      noResults: false,
    }
  },
  components: {
    UserBooking,
  },
  mounted(){
    this.DownloadUserBookings();
  },
  methods:{
    formattedTime(time) {
      return Helper.TimeHelper.getFormattedTimeFromMinutes(time);
    },
    formattedDate(date){
      return Helper.TimeHelper.getDateFromDateTime(date);
    },
    goToHome(){
      this.$router.replace('/');
    },
    async DownloadUserBookings(){
      const jwt = Cookies.get('token');
      const userId = Cookies.get('id');
      if (jwt === undefined){
        this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'Non sei loggato, effettua il login per vedere le tue prenotazioni.',life: 2500});
      }
      else{
        if(userId === undefined){
          this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'Si è presentato un errore con il tuo utente. Ti preghiamo di uscire ed effettuare nuovamente l\'accesso.',life: 3000});
        }
        else{
          API.GetAllUserBookings(jwt,userId).then(response => {
            this.resultBookings = response.data.reverse();
            if(this.resultBookings === null){
              this.showResults = false;
              this.noResults = true;
            }
            else{
              this.showResults = true;
              this.noResults = false;
            }
          }).catch(error => {
            this.showResults = false;
            this.noResults = true;
            this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'Ops! Si è presentato un errore con le tue prenotazioni. Ti preghiamo di contattare l\'assistenza se dovesse persistere.',life: 3000});
          });
        }
      }
    }
  },
}
</script>

<style scoped>
.MyBookings{
  position: relative;
  z-index: -2;
  margin: 100px auto;
  text-align: center;
  /* display: flex; */
  align-items: center;
  min-height: 70vh;
  max-width: 900px;
  text-align: center;
}
.noresult-container {
  text-align: center;
}
.noresult-image {
  display: block;
  margin: 0 auto;
  width: 150px;
  height: auto;
  margin-top: 70px;
}
.noresult-text {
  margin-top: 30px; /* Adjust spacing between image and text */
  font-size: 1.5rem; /* Adjust font size as needed */
  font-weight: bold; /* Optional: Adjust font weight */
  color: #989898;
}
@media (max-width: 500px), (max-height: 900px) {
  .MyBookings{
    align-items: center;
    height: auto;
    display: block;
    margin: 0 auto;
    min-height: 65vh;
  }
}

</style>
