<template>
    <div class="resultContainer">
      <div class="resultHeader">
      </div>
      <div class="resultContent">
        <div class="resultImageAndInfosContainer">
          <div class="resultTitle">
            <div style="display: flex; align-items: center;">
              <Tag style="margin-right: 10px;" v-if="bookingState === 0" severity="danger" value="Errore" rounded></Tag>
              <Tag style="margin-right: 10px;" v-if="bookingState === 1" severity="warning" value="In attesa" rounded></Tag>
              <Tag style="margin-right: 10px;" v-if="bookingState === 2" severity="info" value="Accettata" rounded></Tag>
              <Tag style="margin-right: 10px;" v-if="bookingState === 3 || bookingState === 6" severity="danger" value="Rifiutata" rounded></Tag>
              <Tag style="margin-right: 10px;" v-if="bookingState === 4" severity="contrast" value="Cancellato" rounded></Tag>
              <Tag style="margin-right: 10px;" v-if="bookingState === 5" severity="success" value="Completato" rounded></Tag>
              <h2>{{companyName}}</h2>
          </div>
            
            <div class="addressContainer" style="margin-bottom: 10px;">
              <h6>{{ dateTimeToLocation }}</h6>
            </div>
            <div class="addressContainer">
              <h6>{{formatAddress(startAddress)}}</h6>
              <i v-if="serviceType == 2" class="pi pi-arrow-right-arrow-left"></i>
              <i v-if="serviceType != 2" class="pi pi-arrow-right"></i>
              <h6>{{formatAddress(endAddress)}}</h6>
            </div>
          </div>
          <div class="resultInfos">
            <h4>{{duration}} - {{distance}} km</h4>
            <i class="pi pi-info-circle" v-tooltip="'La durata e distanza sono comprensivi dei viaggi di andata e ritorno presso la base del mezzo.'"></i>
          </div>
        </div>
        <div class="resultPrice">
          <div class="resultPriceNumber">
            <p>Include tasse e costi</p>
            <h2>€{{price}}</h2>
          </div>
          <div class="flex flex-wrap gap-2">
            <Button label="Dettagli" rounded @click="showBookingDetails(bookingId)" />
            <Button v-if="bookingState === 1 || bookingState === 2" label="Disdici" rounded severity="danger" @click="cancelBooking()" />
          </div>
        </div>
      </div>
      <div class="resultFooter">

      </div>
    </div>
    <Toast ref="toast" position="bottom-right" style="right:0px; width: -webkit-fill-available; margin: 10px; max-width: 500px;"/>
    <Dialog v-model:visible="showBookingInfo" modal header="Dettagli Prenotazione" :style="{ width: '75rem' }">
      <UserBookingInfo :bookingId="bookingIdDetails"></UserBookingInfo>
    </Dialog>
    <Toast ref="toast" position="bottom-right" style="right:0px; width: -webkit-fill-available; margin: 10px; max-width: 500px;"/>
</template>

<script>
import API from '@/api/API';
import Helper from '@/helper/Helpers';
import Cookies from 'js-cookie';
import LogInForm from '../components/LogInForm.vue';
import { store } from '@/store';
import vue3starRatings from "vue3-star-ratings";
import UserBookingInfo from '../components/UserBookingInfo.vue';
import { useConfirm } from 'primevue/useconfirm';

export default {
  name: 'Result',
  components: {
      LogInForm,
      vue3starRatings,
      UserBookingInfo,
  },
  data(){
      return {
        bookingIdDetails: null,
        showBookingInfo: false,
        confirmForm: useConfirm(),
      }
  },
  mounted() {
    this.IsRecoverPasswordVisible();
  },
  props: {
    rawItem: Object,
    companyName: String,
    startAddress: Object,
    endAddress: Object,
    duration: String,
    distance: String,
    serviceType: Number,
    waitingTime: Number,
    price: Number,
    dateTimeToLocation: String,
    bookingState: Number,
    bookingId: String,
  },
  watch: {

  } ,

  methods: {
    showBookingDetails(bookingId) {
      this.bookingIdDetails = bookingId;
      this.showBookingInfo = true;
    },
    IsRecoverPasswordVisible(){
      return store.getters.IsRecoverPasswordVisible;
    },
    formatAddress(address) {
      let t = Helper.GeoHelper.removeCAPandCountry(address.address);
      return Helper.GeoHelper.trimLongAddress(t);
    },
    cancelBooking(){
      this.confirmForm.require({
        message: `Sei sicuro di voler cancellare la prenotazione?`,
        header: 'Attenzione',
        icon: 'pi pi-exclamation-triangle',
        rejectClass: 'p-button-success p-button-outlined',
        acceptClass: 'p-button-danger',
        rejectLabel: 'No',
        acceptLabel: 'Si',
        accept: () => {
          this.requestCancelBooking(this.bookingId);
          this.confirmForm.close();
        },
        reject: () => {
          this.$refs.toast.add({severity: 'info', summary: 'Operazione annullata', detail: 'La prenotazione NON è stata cancellata'});
          this.confirmForm.close();
        }
      });
    },
    async requestCancelBooking(){
      const jwt = Cookies.get('token');

      if (jwt === undefined){
        this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'Non sei loggato, effettua il login per cancellare la prenotazione.',life: 2500});
        return;
      }

      API.BookingAPI.CancelBooking(jwt, this.bookingId).then((response) => {
        if (response.status === 200){
          this.$refs.toast.add({severity: 'success', summary: 'Operazione completata', detail: 'La prenotazione è stata cancellata con successo', life: 2500});
          // refresh the page to update the bookings without emitting an event to the parent
          window.location.reload();
        }
      }).catch((error) => {
        this.$refs.toast.add({severity: 'error', summary: 'Errore', detail: 'Si è verificato un errore durante la cancellazione della prenotazione', life: 2500});
      });
    }
  }
}
</script>


<style scoped>
:deep(.p-tag-value){
  font-size: xx-small;
}
.containerRating{
  margin-left: 10px;
  display: flex;
  align-items: center;
}
.containerRating p{
  margin-left: 5px;
  font-size: small;
  color: gray;
}
.resultContainer{
  padding: 15px;
  border-radius: 15px;
  -webkit-box-shadow: 0px 0px 12px -1px rgba(0,0,0,0.5);
  box-shadow: 0px 0px 12px -1px rgba(0,0,0,0.05);
  margin: 5px;
  min-width: 850px;
  background-color: white;
}
.resultContent{
  /*margin: 5px;*/
  display: flex;
  align-items: center;
  height: 100px;
  justify-content: space-between;
}
.resultTitle{
  color: #4F5D75;
  text-align: left;
}
.resultImage{
  height: 6rem;
  width: 6rem;
  background-color: rgb(245 245 245);
  border-radius: 15px;
  padding: 10px;
}
.image{
  height: -webkit-fill-available;
  background-image: url(../assets/Images/AmbulanceNoBKG.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.resultImageAndInfosContainer{
  margin: 15px;
  max-height: 10vh;
  line-height: 5px;
  flex-grow: 1;
}
.resultInfos{
  text-align: left;
  color: #EF8354;
  display: flex;
  position: relative;
  align-items: baseline;
}
.resultInfos i{
  font-size: small;
  color: #bfc0c0;
  margin: 5px;
  cursor: pointer;
}
.resultPrice{
  text-align: right;
  margin-right: 10px;
  line-height: 10px;
}
.resultPrice p{
  color: #bfc0c0;
  font-size: x-small;
  font-weight: lighter;
  margin-right: 10px;
}
.resultPriceNumber{
  display: flex;
  position: relative;
  align-items: center;
}
.addressContainer{
  display: flex;
  position: relative;
  align-items: center;
  height: 5px;
}
.addressContainer i{
  font-size: x-small;
  margin-right: 5px;
  margin-left: 5px;
}

@media (max-width: 700px){

  .resultContainer{
    min-width: 0px;
    padding-top: 1px;
  }
  .resultContent{
    display: unset;
    height: auto;
  }
  .resultTitle{
    line-height: 1;
  }
  .resultPrice{
    text-align: right;
    line-height: 0px;
    margin: 0px;
  }
  .resultPriceNumber{
    justify-content: end;
    font-size: small;
    margin-bottom: 5px;
  }
  .resultImage{
    width: -webkit-fill-available;
  }
  .resultInfos{
    line-height: 1;
  }
  .resultImageAndInfosContainer{
    margin: 15px;
    max-height: auto;
    line-height: 5px;
    flex-grow: 1;
  }
}

</style>