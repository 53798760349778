// @ts-nocheck
import { createApp } from 'vue'
import App from './App.vue'

//CSS
import './assets/global.css'
//PrimeFlex CSS
import '/node_modules/primeflex/primeflex.css'

//Custom Components
import Navbar from './components/Navbar'
import Footer from './components/Footer'

//Primevue
import PrimeVue from 'primevue/config';
//import 'primevue/resources/themes/lara-light-teal/theme.css';
import './assets/lara-light-teal/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Avatar from 'primevue/avatar';
import Menu from 'primevue/menu';
import TieredMenu from 'primevue/tieredmenu'
import AnimateOnScroll from 'primevue/animateonscroll';
import Password from 'primevue/password'
import Checkbox from 'primevue/checkbox';
import SelectButton from 'primevue/selectbutton';
import Carousel from 'primevue/carousel';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import Slider from 'primevue/slider';
import InputNumber from 'primevue/inputnumber';
import VueGoogleAutocomplete from "vue-google-autocomplete";
import Steps from 'primevue/steps';
import Dialog from 'primevue/dialog';
import Tooltip from 'primevue/tooltip';
import Skeleton from 'primevue/skeleton';
import Inplace from 'primevue/inplace';
import Message from 'primevue/message';
import InputOtp from 'primevue/inputotp';
import FloatLabel from 'primevue/floatlabel';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmationService from 'primevue/confirmationservice';
import InputSwitch from 'primevue/inputswitch';
import Textarea from 'primevue/textarea';
import Galleria from 'primevue/galleria';
import Divider from 'primevue/divider';
import AutoComplete from 'primevue/autocomplete'
import Sidebar from 'primevue/sidebar';
import Listbox from 'primevue/listbox';
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Tag from 'primevue/tag'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import Fieldset from 'primevue/fieldset';
import Panel from 'primevue/panel';
import ScrollPanel from 'primevue/scrollpanel';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Chart from 'primevue/chart';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import FullCalendar from '@fullcalendar/vue3';
import FileUpload from 'primevue/fileupload'

//Router
import router from './router'

//Store
import Store from './store/index.js'

// Config
import config from './config.js';
import APIManager from './api/API.js'
import './registerServiceWorker'

//Captcha
//import {ReCaptcha} from 'vue-recaptcha';

//Initial App Creation
const app = createApp(App, {
    data() {
      return {
        backendIP: config.backendIP,
        apiManager: APIManager,
      };
    },
}).use(router);

app.use(Store);
app.use(PrimeVue, {
    zIndex: {
      modal: 900
    },
    locale: {
        firstDayOfWeek: 1,
        fileSizeTypes: ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        choose: 'Scegli file',
        upload: 'Carica',
        cancel: 'Annulla',
        dayNames: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
        dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
        dayNamesMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
        monthNamesShort: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
        monthNames: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']
    }
});
app.use(ToastService); //Services required for Toast messages of PrimeVue
app.use(ConfirmationService); //Services required for ConfirmDialog of PrimeVue
app.use(VueReCaptcha, { siteKey: '6LduMdYpAAAAAD4MHSwwXY7-RlfpaevCPoQGhuNL' }); // Google ReCaptcha v3
app.directive('animateonscroll', AnimateOnScroll);
app.directive('tooltip', Tooltip);
app.directive('badge', BadgeDirective);

//PrimeVue Components
app.component('Navbar',Navbar);
app.component('Footer',Footer);
app.component('Button',Button);
app.component('InputText',InputText);
app.component('Toast',Toast);
app.component('Avatar',Avatar);
app.component('TieredMenu',TieredMenu);
app.component('Menu',Menu);
app.component('Password',Password);
app.component('Checkbox',Checkbox);
app.component('SelectButton',SelectButton);
app.component('Carousel',Carousel);
app.component('Calendar',Calendar);
app.component('Dropdown',Dropdown);
app.component('Slider',Slider);
app.component('InputNumber',InputNumber);
app.component('VueGoogleAutocomplete', VueGoogleAutocomplete);
app.component('Steps',Steps);
app.component('Dialog',Dialog);
app.component('Skeleton',Skeleton);
app.component('Inplace', Inplace);
app.component('Message', Message);
app.component('IconField', IconField);
app.component('InputIcon', InputIcon);
app.component('ConfirmDialog', ConfirmDialog);
app.component('InputSwitch', InputSwitch);
app.component('InputOtp', InputOtp);
app.component('Textarea', Textarea);
app.component('FloatLabel', FloatLabel);
app.component('Galleria', Galleria);
app.component('Divider', Divider);
app.component('AutoComplete', AutoComplete);
app.component('Sidebar', Sidebar);
app.component('Listbox', Listbox);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Tag', Tag);
app.component('Fieldset', Fieldset);
app.component('Panel', Panel);
app.component('ScrollPanel', ScrollPanel);
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('Chart', Chart);
app.component('FullCalendar', FullCalendar);
app.component('Badge', Badge);
app.component('FileUpload', FileUpload);
app.component('Message', Message);

app.data

app.mount('#app');
