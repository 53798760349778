<template>
<div class="grid nested-grid settingsContainer">
    <div class="xl:col-4 md:col-12">
        <CompanyInfo/>
    </div>
    <div class="xl:col-4">
        <!-- <div class="grid">

        </div> -->
        <div class="xl:col-12 md:col-12">
            <CompanyLogo/>
        </div>
        <div class="xl:col-12 md:col-12">
            <CompanySafetyTime/>
        </div>

    </div>
    <div class="xl:col-8 md:col-12">
        <CompanyRequestNotification/>
    </div>
    <div class="xl:col-8 md:col-12">
        <CompanyImages/>
    </div>
</div>
</template>

<script>
import CompanyImages from '@/components/CompanySettings/CompanyImages.vue'
import CompanyInfo from '@/components/CompanySettings/CompanyInfo.vue'
import CompanyLogo from '@/components/CompanySettings/CompanyLogo.vue'
import CompanySafetyTime from '@/components/CompanySettings/CompanySafetyTime.vue'
import CompanyRequestNotification from '@/components/CompanySettings/CompanyRequestNotification.vue'

export default {
  name: 'CompanySettingsView',
  components: {
    CompanyImages,
    CompanyInfo,
    CompanyLogo,
    CompanySafetyTime,
    CompanyRequestNotification
  }
}

</script>

<style scoped>
.settingsContainer {
    display: flex;
    justify-content: center;
    padding: 1.5rem;
    margin: 0 auto; /* Centra il contenitore orizzontalmente */
    /* max-width: 1200px; Puoi regolare questa larghezza in base alle tue necessità */
}
.nested-grid {
    width: 100%; /* Assicura che la griglia interna occupi tutta la larghezza del contenitore */
}
@media (max-width: 500px) {
    .settingsContainer {
        display: inline;
        justify-content: center;
        padding: 0px;
        margin: 5px;
    }
}
</style>